import React, {useState, useCallback} from 'react';
import {getAllUpcomingCaterings} from '../../../services/OrderService';
import CateringList from '../../components/Orders/CateringList';

const Catering = () => {
    const [cateringList, setCateringList] = useState([]);
    // const sort = 3;
    // const [pagination, setPagination] = useState();
    const [isloading, setIsloading] = useState(true);

    const getUpcomingCaterings = useCallback(() => {
        console.log(isloading, 'load');

        getAllUpcomingCaterings().then(res => {
            // res.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
            // setPagination(
            //     Array(Math.ceil(res.length / sort))
            //         .fill()
            //         .map((_, i) => i + 1)
            // );
            setIsloading(false);
            console.log(res.data, 'rse....');
            setCateringList(res.data);
        });
    }, []);
    return (
        <CateringList
            cateringList={cateringList}
            getUpcomingCaterings={getUpcomingCaterings}
            setCateringList={setCateringList}
            isloading={isloading}
            // sort={sort}
        />
    );
};

export default Catering;
