import {useState, useEffect, useCallback} from 'react';
import {toast} from 'react-toastify';
import {ErrorMessage} from '@hookform/error-message';
import {useForm, Controller} from 'react-hook-form';
import FormInput from '../../components/FoodMenu/FormInput';
// import FormSelectInput from '../FoodMenu/FormSelectInput';
// import FormMultiSelectInput from './DropdownMultiSelectInput';
import FormMultiSelectInput from '../../components/Settings/DropdownMultiSelectInput';
import {
    updateCateringSettings,
    getCateringSettings,
    getAllCuisineList,
} from '../../../services/SettingsService';
import TimePicker from '../../components/Settings/TimePicker';
import {objectToFormData} from '../../../utils/objectToFormData';
import moment from 'moment';
const notify = (id, type, message, time) => {
    toast.update(id, {
        render: message,
        type: type,
        position: 'top-center',
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        isLoading: false,
    });
};
const CateringSettings = () => {
    const [catdetails, setCatdetails] = useState();
    const [cuisineList, setCuisineList] = useState();
    const [occasionList, setOccasionList] = useState();

    const {
        handleSubmit,
        formState: {errors},
        register,
        setValue,
        control,
    } = useForm({
        mode: 'all',
        defaultValues: {},
    });

    const getCuisineList = useCallback(() => {
        getAllCuisineList().then(res => {
            res.forEach(each => {
                Object.defineProperty(
                    each,
                    'label',
                    Object.getOwnPropertyDescriptor(each, 'name')
                );
                Object.defineProperty(
                    each,
                    'value',
                    Object.getOwnPropertyDescriptor(each, 'id')
                );
            });
            let mappedCuisineList = res
                .map(each => {
                    each.value = +each.value;
                    return each;
                })
                .filter(each => each.status);
            setCuisineList(mappedCuisineList);
        });
    }, [setCuisineList]);

    useEffect(() => {
        getCuisineList();
        // getOccasionList();

        getCateringSettings().then(res => setCatdetails(res));
    }, []);

    const days = [
        {
            name: 'catering_days.son',
            label: 'Sunday',
        },
        {
            name: 'catering_days.mon',
            label: 'Monday',
        },
        {
            name: 'catering_days.tue',
            label: 'Tuesday',
        },
        {
            name: 'catering_days.wed',
            label: 'wednesday',
        },
        {
            name: 'catering_days.thu',
            label: 'Thursday',
        },
        {
            name: 'catering_days.fri',
            label: 'Friday',
        },
        {
            name: 'catering_days.sat',
            label: 'Saturday',
        },
    ];

    useEffect(() => {
    if (catdetails) {
        let listToPreload = catdetails.occassion_list.map(each => {
            return { label: each, value: each };
        });
        let cuisineToPreload = catdetails.cuisine_list.map(each => {
            return { label: each, value: each };
        });
        let suggestionList = catdetails.occassion_suggestion_list.map(each => {
            return { label: each, value: each };
        });
        setOccasionList([...suggestionList, ...listToPreload]);

        setValue('occassion_list', listToPreload);
        setValue('cuisine_list', cuisineToPreload);

        const fields = [
            'is_catering',
            'catering_time',
            'catering_prior_days',
            'is_pre_cook',
            'is_live_cook',
            'catering_days',
            'indoor_service',
            'outdoor_service',
        ];

        fields.forEach(field => setValue(field, catdetails[field]));

        // Ensure that cat_start_time and cat_end_time are in the correct format.
        setValue('cat_start_time', catdetails.cat_start_time);
        setValue('cat_end_time', catdetails.cat_end_time);
    }
}, [setValue, catdetails]);

    const onSubmit = async data => {
        data.occassion_list = data.occassion_list.map(each => each.value);
        data.cuisine_list = data.cuisine_list.map(each => each.label);

        data.cat_start_time =
            new Date(`2000-01-01 ${data.cat_start_time} AM`).getHours() +
            ':' +
            String(
                new Date(`2000-01-01 ${data.cat_start_time} AM`).getMinutes()
            ).padStart(2, '0') +
            ':' +
            String(
                new Date(`2000-01-01 ${data.cat_start_time} AM`).getSeconds()
            ).padStart(2, '0');
        data.cat_end_time =
            new Date(`2000-01-01 ${data.cat_end_time} `).getHours() +
            ':' +
            String(
                new Date(`2000-01-01 ${data.cat_end_time} `).getMinutes()
            ).padStart(2, '0') +
            ':' +
            String(
                new Date(`2000-01-01 ${data.cat_end_time} `).getSeconds()
            ).padStart(2, '0');

        const toaster = toast.loading('Updating...');

        let postData = objectToFormData(data);
        let res = await updateCateringSettings(postData);
        if (res.status === 'success') {
            notify(toaster, 'success', 'Updated Catering Settings!', 2000);
            
        } else {
            notify(toaster, 'error', res.data.message, 2000);
        }
    };

    return (
        <>
            <div className='modal-body'>
                {catdetails ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='d-flex row'>
                            <div className='col-md-6 col-xl-3 col-xxl-6 mb-3 picker-data'>
                                <div className='color-time-picker'>
                                    <TimePicker
                                        label={'Start time'}
                                        name={'cat_start_time'}
                                        required={true}
                                        Controller={Controller}
                                        control={control}
                                        errors={errors}
                                        id={'cat_start_time'}
                                        ErrorMessage={ErrorMessage}
                                        register={{
                                            ...register('cat_start_time'),
                                            required:
                                                'Catering Start time is required!',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6 col-xl-3 col-xxl-6 mb-3 picker-data'>
                                <div className='color-time-picker '>
                                    <TimePicker
                                        label={'End time'}
                                        name={'cat_end_time'}
                                        Controller={Controller}
                                        control={control}
                                        errors={errors}
                                        id={'cat_end_time'}
                                        required={true}
                                        ErrorMessage={ErrorMessage}
                                        register={{
                                            ...register('cat_end_time'),
                                            required:
                                                'Catering End time is required!',
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='col-10'>
                                <FormInput
                                    label={' Minimum Catering Prior Days'}
                                    id={'catering_prior_days'}
                                    name={'catering_prior_days'}
                                    required={true}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('catering_prior_days', {
                                            required:
                                                'Catering Prior Days is required!',
                                            pattern: {
                                                // value: /^\d+(\.\d{(1, 2)})?$/,
                                                value: /^\d{1,3}$/,
                                                message:
                                                    'Invalid number of days provided!',
                                            },
                                        }),
                                    }}
                                    errors={errors}
                                />
                            </div>
                            <div className='col-10'>
                                <FormMultiSelectInput
                                    label={'Occasion List'}
                                    id={'val-occasion'}
                                    name={'occassion_list'}
                                    ErrorMessage={ErrorMessage}
                                    Controller={Controller}
                                    control={control}
                                    errors={errors}
                                    required={true}
                                    options={
                                        occasionList
                                            ? occasionList
                                            : [
                                                  {
                                                      value: null,
                                                      label: 'Loading...',
                                                  },
                                              ]
                                    }
                                />
                            </div>
                            <div className='col-10'>
                                <FormMultiSelectInput
                                    label={'Available Cuisines'}
                                    id={'val-cuisine'}
                                    name={'cuisine_list'}
                                    ErrorMessage={ErrorMessage}
                                    Controller={Controller}
                                    control={control}
                                    errors={errors}
                                    required={true}
                                    options={
                                        cuisineList
                                            ? cuisineList
                                            : [
                                                  {
                                                      value: null,
                                                      label: 'Loading...',
                                                  },
                                              ]
                                    }
                                />
                            </div>

                            <label className='text-black font-w500 col-12'>
                                Available days
                            </label>
                            {days.map((day, i) => (
                                <div className='col-4 '>
                                    <div className='custom-control custom-checkbox mb-3 checkbox-info'>
                                        <input
                                            type='checkbox'
                                            defaultChecked
                                            className='custom-control-input'
                                            {...register(day.name)}
                                            id={day.name}
                                        />
                                        <label
                                            className='custom-control-label'
                                            htmlFor={day.name}
                                        >
                                            {day.label}
                                        </label>
                                    </div>
                                </div>
                            ))}

                            <div className='form-group mb-3 size-filter  col-10'>
                                <label className='text-black font-w500 col-12'>
                                    Available Services
                                </label>

                                <div className='custom-control custom-checkbox mb-3 checkbox-info '>
                                    <input
                                        type='checkbox'
                                        className='custom-control-input'
                                        defaultChecked
                                        {...register('is_live_cook')}
                                        id='val-is_live_cook'
                                    />
                                    <label
                                        className='custom-control-label'
                                        htmlFor='val-is_live_cook'
                                    >
                                        Live Cook
                                    </label>
                                </div>
                                <div className='custom-control custom-checkbox mb-3 checkbox-info col-4'>
                                    <input
                                        type='checkbox'
                                        className='custom-control-input'
                                        defaultChecked
                                        {...register('is_pre_cook')}
                                        id='val-is_pre_cook'
                                    />
                                    <label
                                        className='custom-control-label'
                                        htmlFor='val-is_pre_cook'
                                    >
                                        Pre Cook
                                    </label>
                                </div>

                                <div className='custom-control custom-checkbox mb-3 checkbox-info'>
                                    <input
                                        type='checkbox'
                                        className='custom-control-input'
                                        defaultChecked
                                        {...register('indoor_service')}
                                        id='val-indoor_service'
                                    />
                                    <label
                                        className='custom-control-label'
                                        htmlFor='val-indoor_service'
                                    >
                                        Indoor Service
                                    </label>
                                </div>
                                <div className='custom-control custom-checkbox mb-3 checkbox-info'>
                                    <input
                                        type='checkbox'
                                        className='custom-control-input'
                                        defaultChecked
                                        {...register('outdoor_service')}
                                        id='val-outdoor_service'
                                    />
                                    <label
                                        className='custom-control-label'
                                        htmlFor='val-outdoor_service'
                                    >
                                        Outdoor Service
                                    </label>
                                </div>
                            </div>
                            <button className='btn btn-primary btn-block btn-md mb-2 mr-auto'>
                                Update
                            </button>
                        </div>
                    </form>
                ) : (
                    <div id='preloader-inner'>
                        <div className='sk-three-bounce-inner'>
                            <div className='sk-child sk-bounce1'></div>
                            <div className='sk-child sk-bounce2'></div>
                            <div className='sk-child sk-bounce3'></div>
                            <div id='preloader-inner-bg'></div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default CateringSettings;