import React from 'react';
import {Link} from 'react-router-dom';

const NotificationButton = ({type, bookid}) => {
    let linkUrl;
    if (type === 'Reservation') {
        linkUrl = `/reservation-detail/${bookid}`;
    } else if (type === 'Skipque') {
        linkUrl = `/delivery-detail/${bookid}`;
    } else if (type === 'Catering') {
        linkUrl = `/catering-detail/${bookid}`;
    } else {
        linkUrl = `/notifications`;
    }
    return (
        <div className='float-right'>
            <Link to={linkUrl}>
                <small className='text-primary'>show</small>
            </Link>
        </div>
    );
};

export default NotificationButton;
