/// Menu
import MetisMenu from 'metismenujs';
import React, {Component} from 'react';

/// Scroll
import PerfectScrollbar from 'react-perfect-scrollbar';
/// Link
import {Link} from 'react-router-dom';

class MM extends Component {
    componentDidMount() {
        this.$el = this.el;
        this.mm = new MetisMenu(this.$el);
    }
    componentWillUnmount() {}
    render() {
        return (
            <div className='mm-wrapper'>
                <ul className='metismenu' ref={el => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

class SideBar extends Component {
    /// Open menu
    componentDidMount() {
        // sidebar open/close
        var btn = document.querySelector('.nav-control');
        var aaa = document.querySelector('#main-wrapper');
        function toggleFunc() {
            return aaa.classList.toggle('menu-toggle');
        }
        btn.addEventListener('click', toggleFunc);
    }

    state = {
        newEvent: false,
        loveEmoji: false,
    };
    Showhandler() {
        this.setState({show: true});
    }
    Hidehandler() {
        this.setState({show: false});
    }

    render() {
        /// Path
        let path = window.location.pathname;
        path = path.split('/');
        path = path[path.length - 1];

        /// Active menu
        let orders = ['reservations', 'delivery', 'skip-the-queue', 'catering'],
            order_history = [
                'reservation-history',
                'delivery-history',
                'skip-the-queue-history',
                'catering-history',
            ],
            food_menu = ['food-items', 'cuisines', 'chefs', 'table-filtering'],
            settings = [
                'restaurant-settings',
                'reservation-settings',
                'delivery-settings',
                'takeaway-settings',
                'catering-settings',
                'table-settings',
                'tablecategory-settings',
            ];

        return (
            <div className='deznav'>
                <PerfectScrollbar className='deznav-scroll'>
                    <MM className='metismenu' id='menu'>
                        <li className={`${path === '' ? 'mm-active' : ''}`}>
                            <Link
                                className='ai-icon'
                                to='/'
                                onClick={() => this.props.onClick3()}
                            >
                                <i className='flaticon-381-networking'></i>
                                <span className='nav-text'>Dashboard</span>
                            </Link>
                        </li>
                        <li
                            className={`${
                                orders.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link
                                className='has-arrow ai-icon'
                                to='#'
                                onClick={() => this.props.onClick3()}
                            >
                                <i className='flaticon-381-box-2'></i>
                                <span className='nav-text'>Orders</span>
                            </Link>
                            <ul>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'reservations'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/reservations'
                                    >
                                        Reservations
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'delivery'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/delivery'
                                    >
                                        Delivery
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'take-away'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/take-away'
                                    >
                                        Take Away
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'catering'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/catering'
                                    >
                                        Catering Orders
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li
                            className={`${
                                order_history.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link
                                className='has-arrow ai-icon'
                                to='#'
                                onClick={() => this.props.onClick3()}
                            >
                                <i className='flaticon-381-tab'></i>
                                <span className='nav-text'>Order History</span>
                            </Link>
                            <ul>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'reservation-history'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/reservation-history'
                                    >
                                        Reservation History
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'delivery-history'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/delivery-history'
                                    >
                                        Delivery History
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'take-away-history'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/take-away-history'
                                    >
                                        Take-away History
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'catering-history'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/catering-history'
                                    >
                                        Catering History
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li
                            className={`${path === 'users' ? 'mm-active' : ''}`}
                        >
                            <Link
                                className='ai-icon'
                                to='/users'
                                onClick={() => this.props.onClick3()}
                            >
                                <i className='flaticon-381-user-1'></i>
                                <span className='nav-text'>Users</span>
                            </Link>
                        </li>
                        <li
                            className={`${
                                path === 'customers' ? 'mm-active' : ''
                            }`}
                        >
                            <Link
                                className='ai-icon'
                                to='/customers'
                                onClick={() => this.props.onClick3()}
                            >
                                <i className='flaticon-381-user-9'></i>
                                <span className='nav-text'>Customers</span>
                            </Link>
                        </li>
                        <li
                            className={`${
                                food_menu.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link
                                className='has-arrow ai-icon'
                                to='#'
                                onClick={() => this.props.onClick3()}
                            >
                                <i className='flaticon-381-menu'></i>
                                <span className='nav-text'>Food Menu</span>
                            </Link>
                            <ul>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'food-items'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/food-items'
                                    >
                                        Food Items
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'cuisines'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/cuisines'
                                    >
                                        Cuisines
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'chefs' ? 'mm-active' : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/chefs'
                                    >
                                        Chefs
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li
                            className={`${
                                settings.includes(path) ? 'mm-active' : ''
                            }`}
                        >
                            <Link
                                className='has-arrow ai-icon'
                                to='#'
                                onClick={() => this.props.onClick3()}
                            >
                                <i className='flaticon-381-settings-5'></i>
                                <span className='nav-text'>Settings</span>
                            </Link>
                            <ul>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'restaurant-settings'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/restaurant-settings'
                                    >
                                        Restaurant Settings
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'reservation-settings'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/reservation-settings'
                                    >
                                        Reservation Settings
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'delivery-settings'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/delivery-settings'
                                    >
                                        Delivery Settings
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'takeaway-settings'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/takeaway-settings'
                                    >
                                        Takeaway Settings
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'catering-settings'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/catering-settings'
                                    >
                                        Catering Settings
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'table-settings'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/table-settings'
                                    >
                                        Tables
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${
                                            path === 'table-category'
                                                ? 'mm-active'
                                                : ''
                                        }`}
                                        onClick={() => this.props.onClick()}
                                        to='/table-category'
                                    >
                                        Table Category
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </MM>

                    <div className='copyright'>
                        <p>
                            <strong>Skippy Restaurant Admin Dashboard</strong> ©
                            2022 All Rights Reserved
                        </p>
                        <p>
                            Made with{' '}
                            <span
                                className={`${
                                    this.state.loveEmoji
                                        ? 'heart heart-blast'
                                        : 'heart'
                                }`}
                                onClick={() =>
                                    this.setState({
                                        loveEmoji: !this.state.loveEmoji,
                                    })
                                }
                            ></span>{' '}
                            by Texol
                        </p>
                    </div>
                </PerfectScrollbar>
            </div>
        );
    }
}

export default SideBar;
