import React, {useRef, useEffect} from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, refInside, closeAction) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (
                ref.current &&
                !ref.current.contains(event.target) &&
                !refInside.current.contains(event.target) &&
                !event.target.classList.contains('pac-container') &&
                !event.target.classList.contains('pac-item') &&
                !event.target.classList.contains('pac-icon') &&
                !event.target.classList.contains('pac-matched') &&
                !event.target.classList.contains('pac-item-query') &&
                !event.target.parentElement?.classList.contains('pac-item')
            ) {
                closeAction(false);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideAlerter(props) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.refInside, props.closeAction);

    return <div ref={wrapperRef}>{props.children}</div>;
}
