import React, {useState, useCallback} from 'react';
import {getAllCategoryList} from '../../../services/SettingsService';
import TableCategory from '../../components/Settings/TableCategory';
import {ToastContainer} from 'react-toastify';

const TableCategorySettings = () => {
    const [categoryList, setCategoryList] = useState();

    const getCategoryList = useCallback(() => {
        getAllCategoryList().then(res => {
            setCategoryList(res);
        });
    }, []);
    return (
        <TableCategory
            categoryList={categoryList}
            getCategoryList={getCategoryList}
        />
    );
};

export default TableCategorySettings;
