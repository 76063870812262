import {useState, useEffect, useCallback, useRef} from 'react';
import {Modal} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {ErrorMessage} from '@hookform/error-message';
import {useForm, Controller} from 'react-hook-form';
import FormInput from '../../components/FoodMenu/FormInput';
import {connect, useDispatch} from 'react-redux';

import {UpdateProfileAction} from '../../../store/actions/UserActions';
import {objectToFormData} from '../../../utils/objectToFormData';
// images
import uploadImageIcon from '../../../images/upload_image.png';

const notify = (id, type, message, time) => {
    toast.update(id, {
        render: message,
        type: type,
        position: 'top-center',
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        isLoading: false,
    });
};

const EditProfile = props => {
    const {
        EditModal,
        setEditModal,
        setProfileDetails,
        profileDetails,
        // userProfile,
    } = props;
    // setProfileDetails(userProfile);
    // console.log(props, "'props'");
    const [checked, setChecked] = useState(false);
    const handleChange = () => {
        setChecked(!checked);
    };

    const uploadRef = useRef(null);
    const {
        handleSubmit,
        formState: {errors},
        register,
        setValue,
        control,
        watch,
    } = useForm({mode: 'all'});
    const {ref, ...rest} = register('img');
    const dispatch = useDispatch();
    const watchImage = watch('img', null);
    useEffect(() => {
        console.log(profileDetails, 'mobile');
        if (profileDetails) {
            const fields = ['full_name', 'email', 'designation', 'mobile'];

            fields.forEach(field => setValue(field, profileDetails[field]));
            // console.log(profileDetails['profile_img']);
        }
    }, [setValue, profileDetails]);

    const onSubmit = async data => {
        if (profileDetails) {
            const fields = [
                'full_name',
                'email',
                'designation',
                'img',
                'mobile',
            ];
            fields.forEach(field => {
                if (field === 'img' && data[field].length < 1) {
                    delete data[field];
                } else if (field === 'img') {
                    data.img = data.img[0];
                }
                if (data[field] === undefined) {
                    delete data[field];
                }
                if (field === 'password') {
                    delete data[field];
                }
            });
            console.log(data, 'Profile data updation');

            const toaster = toast.loading('Updating...');

            const res = await dispatch(UpdateProfileAction(data));
            // console.log(res.status, 'updatedrespons');
            if (res.status === 'success') {
                console.log(' toaster keri');
                alert(res.status);
                notify(toaster, 'success', 'Profile details updated!', 2000);
                setEditModal(false);
            } else {
                notify(toaster, 'error', res.data.message, 2000);
            }
        }
    };

    return (
        <Modal
            show={EditModal}
            onHide={setEditModal}
            className='modal fade'
            id='EditModal'
        >
            <>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title'>Update Profile</h5>
                        <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            onClick={() => setEditModal(false)}
                        >
                            <span>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='image-placeholder'>
                                <div
                                    className='avatar-edit'
                                    onClick={() => uploadRef?.current?.click()}
                                >
                                    <input
                                        type='file'
                                        {...rest}
                                        name='img'
                                        ref={e => {
                                            ref(e);
                                            uploadRef.current = e;
                                        }}
                                        className='custom-file-input'
                                        id='val-img'
                                        hidden
                                    />
                                    <label
                                        htmlFor='imageUpload'
                                        name=''
                                    ></label>
                                </div>
                                <div className='avatar-preview'>
                                    <div id='imagePreview'>
                                        {profileDetails ||
                                        uploadRef?.current?.files.length ? (
                                            <img
                                                id='saveImageFile'
                                                src={
                                                    watchImage &&
                                                    watchImage.length &&
                                                    uploadRef.current.files
                                                        .length
                                                        ? URL.createObjectURL(
                                                              uploadRef?.current
                                                                  .files[0]
                                                          )
                                                        : process.env
                                                              .REACT_APP_API_RESOURCE_URL +
                                                          profileDetails?.img
                                                }
                                                alt='upload profile pic here'
                                            />
                                        ) : (
                                            <img
                                                className='mr-3 img-fluid rounded'
                                                src={uploadImageIcon}
                                                alt='upload profile pic here'
                                            />
                                        )}
                                    </div>
                                    <ErrorMessage
                                        errors={errors}
                                        name='img'
                                        render={({message}) => (
                                            <div className='text-danger fs-12'>
                                                {message}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <FormInput
                                label={'Name'}
                                id={'val-full_name'}
                                name={'full_name'}
                                required={true}
                                ErrorMessage={ErrorMessage}
                                register={{
                                    ...register('full_name', {
                                        required: 'Name is required!',
                                    }),
                                }}
                                errors={errors}
                            />
                            <FormInput
                                label={'Designation'}
                                id={'val-designation'}
                                name={'designation'}
                                required={true}
                                ErrorMessage={ErrorMessage}
                                register={{
                                    ...register('designation', {
                                        required: 'Designation is required!',
                                    }),
                                }}
                                errors={errors}
                            />
                            <FormInput
                                label={'Contact Number'}
                                id={'val-admin_mobile'}
                                name={'admin_mobile'}
                                required={true}
                                ErrorMessage={ErrorMessage}
                                register={{
                                    ...register('admin_mobile', {
                                        required: 'Contact number is required!',
                                        pattern: {
                                            value: /^(0|[0-9]\d*)(\.\d+)?$/,
                                            message:
                                                'Invalid contact number provided!',
                                        },
                                    }),
                                }}
                                errors={errors}
                            />
                            <FormInput
                                label={'Email'}
                                type='text'
                                id={'val-email'}
                                name={'email'}
                                ErrorMessage={ErrorMessage}
                                className='form-control'
                                register={{
                                    ...register('email', {
                                        required: ' Email is requied!',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Invalid email provided!',
                                        },
                                    }),
                                }}
                                errors={errors}
                            />
                            <div className='custom-control custom-checkbox mb-3 checkbox-info'>
                                <input
                                    type='checkbox'
                                    className='custom-control-input'
                                    onChange={handleChange}
                                    id='val-is-cater'
                                />
                                <label
                                    className='custom-control-label'
                                    htmlFor='val-is-cater'
                                >
                                    Change Password
                                </label>
                            </div>
                            {checked ? (
                                <>
                                    <FormInput
                                        label={' Old Password'}
                                        id={'val-old_pass'}
                                        name={'old_pass'}
                                        type={'old_pass'}
                                        required={true}
                                        ErrorMessage={ErrorMessage}
                                        register={{
                                            ...register('old_pass', {
                                                required:
                                                    ' Old Password is required!',
                                            }),
                                        }}
                                        errors={errors}
                                    />
                                    <FormInput
                                        label={'New Password'}
                                        id={'val-new_pass'}
                                        name={'new_pass'}
                                        type={'password'}
                                        required={true}
                                        ErrorMessage={ErrorMessage}
                                        register={{
                                            ...register('new_pass', {
                                                required:
                                                    ' New Password is required!',
                                            }),
                                        }}
                                        errors={errors}
                                    />
                                    <FormInput
                                        label={'Confirm Password'}
                                        id={'val-confirm-password'}
                                        type={'password'}
                                        name={'con_pass'}
                                        required={true}
                                        ErrorMessage={ErrorMessage}
                                        register={{
                                            ...register('con_pass', {
                                                required:
                                                    'Confirm Password is required!',

                                                validate: val =>
                                                    val === watch('new_pass') ||
                                                    'Passwords do not match!',
                                            }),
                                        }}
                                        errors={errors}
                                    />
                                </>
                            ) : (
                                ' '
                            )}

                            <div className='justify-content-right'>
                                <button
                                    type='submit'
                                    className='next btn btn-primary ml-1'
                                >
                                    Update Profile
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        userProfile: state.user,
    };
};

export default connect(mapStateToProps)(EditProfile);
