import React from 'react';

const Loader = () => {
    return (
        <div id='preloader-inner'>
            <div className='sk-three-bounce-inner'>
                <div className='sk-child sk-bounce1'></div>
                <div className='sk-child sk-bounce2'></div>
                <div className='sk-child sk-bounce3'></div>
                <div id='preloader-inner-bg'></div>
            </div>
        </div>
    );
};

export default Loader;
