import React, {useEffect, useState, useMemo} from 'react';
import {Card, Table, Badge, Dropdown} from 'react-bootstrap';
import {rejectReservation} from '../../../services/OrderService';
import {confirmReservation} from '../../../services/OrderService';
import {Link} from 'react-router-dom';
import Status from './Status';
import Loader from './Loader';
const svg1 = (
    <svg width='20px' height='20px' viewBox='0 0 24 24' version='1.1'>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <rect x='0' y='0' width='24' height='24'></rect>
            <circle fill='#000000' cx='5' cy='12' r='2'></circle>
            <circle fill='#000000' cx='12' cy='12' r='2'></circle>
            <circle fill='#000000' cx='19' cy='12' r='2'></circle>
        </g>
    </svg>
);

const ReservationList = props => {
    const {
        reservationList,
        getUpcomingReservations,
        setReservationList,
        isloading,
    } = props;

    const [filteredList, setFilteredList] = useState(reservationList ?? []);

    useEffect(() => {
        getUpcomingReservations();
    }, [getUpcomingReservations]);

    useEffect(() => {
        setFilteredList(reservationList);
    }, [reservationList]);

   const handleReject = async (id) => {
    try {
        await rejectReservation(id);
        // Reload the page after successful rejection
        window.location.reload();
    } catch (error) {
        console.error('Error rejecting reservation:', error);
    }
};


    return (
        <>
            <Status
                List={reservationList}
                filteredList={filteredList}
                setFilteredList={setFilteredList}
            ></Status>
            <Card>
                <Card.Header>
                    <Card.Title>Live Reservation Orders</Card.Title>
                </Card.Header>
                {isloading && <Loader />}

                {!isloading && filteredList && filteredList.length > 0 ? (
                    <Card.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>RES. NO.</strong>
                                    </th>
                                    <th>
                                        <strong>DATE</strong>
                                    </th>
                                    <th>
                                        <strong>CUSTOMER</strong>
                                    </th>
                                    <th>
                                        <strong>TABLE NO.</strong>
                                    </th>
                                    <th>
                                        <strong>NO. OF GUESTS</strong>
                                    </th>
                                    <th>
                                        <strong>AMOUNT</strong>
                                    </th>
                                    <th>
                                        <strong>STATUS</strong>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isloading &&
                                filteredList &&
                                filteredList.length > 0 ? (
                                    filteredList.map(each => (
                                        <tr key={each.bookid}>
                                            <td>
                                                <strong>
                                                    {each.bookid}
                                                </strong>
                                            </td>
                                            <td>
                                                {each.date +
                                                    ' ' +
                                                    each.time.slice(0, -8)}
                                            </td>
                                            <td>{each.initiator}</td>
                                            <td>{each.table_no}</td>
                                            <td>{each.n_o_g}</td>
                                            <td>SAR {each.total}</td>
                                            <td>
                                                <Badge
                                                    variant={
                                                        each.status ===
                                                        'Booking Confirmed'
                                                            ? 'success light'
                                                            : 'warning'
                                                    }
                                                >
                                                    {each.status}
                                                </Badge>
                                            </td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant={
                                                            each.status ===
                                                            'Booking Confirmed'
                                                                ? 'success'
                                                                : 'warning'
                                                        }
                                                        className='light sharp i-false'
                                                    >
                                                        {svg1}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='dropdown-menu dropdown-menu-right'>
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                handleReject(
                                                                    each.bookid
                                                                )
                                                            }
                                                            data-dismiss='alert'
                                                            aria-label='Close'
                                                            className='dropdown-item'
                                                        >
                                                            <i className='las la-times-circle text-danger mr-3 scale5' />
                                                            Reject
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            as={Link}
                                                            to={`/reservation-detail/${each.bookid}`}
                                                            className='dropdown-item'
                                                        >
                                                            View Details
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr></tr>
                                )}
                            </tbody>
                        </Table>
                    </Card.Body>
                ) : (
                    <div style={{height: '100px'}}>
                        <h5 className='text-center mt-5'>NO ORDER FOUND</h5>
                    </div>
                )}
            </Card>
        </>
    );
};

export default ReservationList;
