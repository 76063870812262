import {updateProfileDetails} from '../../services/SettingsService';
import {getLoggedInUserData} from '../../services/UserService';
import {objectToFormData} from '../../utils/objectToFormData';
export const GET_LOGGED_IN_USER_DETAILS_ACTION =
    '[user details action] get logged in user details';

export const PROFILE_UPDATE_ACTION = '[admin action] update profile';
export const PROFILE_UPDATE_FAIL_ACTION =
    '[admin action] update profile failed';

export function getLoggedInUserDetailsAction(data) {
    // console.log(data, 'loggedin');
    return {
        type: GET_LOGGED_IN_USER_DETAILS_ACTION,
        payload: data,
    };
}
export const UpdateProfileAction = data => async dispatch => {
    try {
        let postData = objectToFormData(data, '');
        console.log(data, 'adminaction');
        const res = await updateProfileDetails(postData);
        console.log(res.status, 'actionresponse');
        if (res.status === 'success') {
            console.log('KKKKKKKKK');
            dispatch(getLoggedInUserData);
        } else {
            dispatch({type: PROFILE_UPDATE_FAIL_ACTION});
        }
        return res;
    } catch (err) {
        console.log(err);
    }
};
