import React, {useState, useCallback} from 'react';
import {getAllCaterings} from '../../../services/OrderService';
import CateringHistoryList from '../../components/OrderHistory/CateringHistoryList';

const CateringHistory = () => {
    const [cateringList, setCateringList] = useState();
    const sort = 3;

    const [page, setPage] = useState(1);
    const [totalPages, setTotalpages] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const getCaterings = useCallback((page, fromDate, toDate) => {
        getAllCaterings(page, fromDate, toDate).then(res => {
            setTotalpages(res.total_pages, 'total pages');

            setCateringList(res.data);
        });
    }, []);
    return (
        <CateringHistoryList
            cateringList={cateringList}
            getCaterings={getCaterings}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            page={page}
            totalPages={totalPages}
        />
    );
};

export default CateringHistory;
