import React from 'react';

import ReactApexChart from 'react-apexcharts';

class ApexBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [
                {
                    name: 'Cycling',
                    data: props.data || [],
                },
            ],
            options: {
                chart: {
                    type: 'bar',
                    height: 230,
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: 'top',
                        },
                    },
                },
                colors: ['#EA7A9A'],
                legend: {
                    show: false,
                    position: 'top',
                    horizontalAlign: 'left',
                },
                dataLabels: {
                    enabled: false,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        // colors: ["#fff"],
                    },
                },
                stroke: {
                    show: false,
                },
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
                xaxis: {
                    show: false,
                    categories: [
                        'jan',
                        'feb',
                        'mar',
                        'apr',
                        'may',
                        'jun',
                        'jul',
                        'aug',
                        'sep',
                        'oct',
                        'nov',
                        'dec',
                    ],
                },
            },
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                series: [
                    {
                        name: 'Cycling',
                        data: this.props.data || [],
                    },
                ],
            });
        }
    }
    render() {
        return (
            <div id='chart'>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type='bar'
                    height={350}
                />
            </div>
        );
    }
}
export default ApexBar;
