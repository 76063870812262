import React, {useState, useEffect, useMemo} from 'react';
import {Card, Table} from 'react-bootstrap';

import {FilteringTable} from './FilterTable';
import {Link} from 'react-router-dom';
import DateFilter from './DateFilter';
import {format} from 'date-fns';
import {ColumnFilter} from './ColumnFilter';

const ReservationList = props => {
    const {
        reservationList,
        getReservations,
        page,
        totalPages,
        setFromDate,
        setToDate,
        fromDate,
        toDate,
    } = props;
    const [selectedDates, setSelectedDates] = useState([]);
    const [filteredResult, setFilteredResult] = useState(null);

    useEffect(() => {
        getReservations(page);
    }, [getReservations]);
    const handlePaginationClick = async pageIndex => {
        const fDate = fromDate;
        const tDate = toDate;

        getReservations(pageIndex + 1, fDate, tDate);
    };
    const handleDateChange = async dates => {
        const fDate = new Date(dates[0]);
        const tDate = new Date(dates[1]);

        const fromYear = fDate.getFullYear();
        const fromMonth = fDate.getMonth() + 1;
        const fromDate = fDate.getDate();

        const toYear = tDate.getFullYear();
        const toMonth = tDate.getMonth() + 1;
        const toDate = tDate.getDate();

        const FromDate = `${fromYear}-${fromMonth
            .toString()
            .padStart(2, '0')}-${fromDate.toString().padStart(2, '0')}`;
        const ToDate = `${toYear}-${toMonth
            .toString()
            .padStart(2, '0')}-${toDate.toString().padStart(2, '0')}`;

        setSelectedDates(dates);
        setFromDate(FromDate);
        setToDate(ToDate);
        setFilteredResult(FromDate);
        getReservations(page, FromDate, ToDate);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'RES NO.',
                Footer: 'RES NO.',
                accessor: 'Reservation_no',
                Filter: ColumnFilter,
            },
            {
                Header: 'DATE',
                Footer: 'DATE',
                accessor: 'date',

                Filter: ColumnFilter,
            },
            {
                Header: 'CUSTOMER',
                Footer: 'CUSTOMER',
                accessor: 'initiator',
                Filter: ColumnFilter,
            },
            {
                Header: 'SLOT',
                Footer: 'SLOT',
                accessor: 'slot',
                Filter: ColumnFilter,
            },

            {
                Header: 'NUM OF GUESTS',
                Footer: 'NUM OF GUESTS',
                accessor: 'n_o_g',

                Filter: ColumnFilter,
            },
            {
                Header: 'TIME',
                Footer: 'AMOUNT',
                accessor: 'time',

                // Cell: ({value}) => {
                //     return value.slice(11, 19);
                // },
                Filter: ColumnFilter,
            },
            {
                Header: 'STATUS',
                Footer: 'STATUS',
                accessor: 'status',
                Filter: ColumnFilter,
            },
            {
                Header: '',
                // Footer: '',
                accessor: 'view',
                Cell: value => {
                    let {Reservation_no} = value.row.original;
                    let linkUrl = `/reservation-detail/${Reservation_no}`;
                    return (
                        <Link to={linkUrl}>
                            <button
                                className='btn btn-primary btn-sm 
                            '
                            >
                                View
                            </button>
                        </Link>
                    );
                },
                Filter: ColumnFilter,
                disableFilters: true,
            },
        ],
        []
    );

    return (
        <>
            {reservationList ? (
                <>
                    <Card>
                        <DateFilter
                            selectedDates={selectedDates}
                            handleDateChange={handleDateChange}
                        />
                        <FilteringTable
                            columns={columns}
                            data={reservationList}
                            total_pages={totalPages}
                            paginationClick={handlePaginationClick}
                            filteredResult={filteredResult}
                        />
                    </Card>
                </>
            ) : (
                <Table>
                    <thead>
                        <tr>
                            <th>
                                <strong>RES. NO.</strong>
                            </th>
                            <th>
                                <strong>DATE</strong>
                            </th>
                            <th>
                                <strong>TIME</strong>
                            </th>
                            <th>
                                <strong>CUSTOMER</strong>
                            </th>

                            <th>
                                <strong>STATUS</strong>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div id='preloader-inner'>
                                    <div className='sk-three-bounce-inner'>
                                        <div className='sk-child sk-bounce1'></div>
                                        <div className='sk-child sk-bounce2'></div>
                                        <div className='sk-child sk-bounce3'></div>
                                        <div id='preloader-inner-bg'></div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            )}
        </>
    );
};

export default ReservationList;
