import axiosPrivateInstance from './AxiosPrivateInstance';
import {handleApiError} from './SettingsService';
export const getAllCustomers = async page => {
    try {
        const res = await axiosPrivateInstance.get(
            `restaurant-customers/?pageitems=4&page=${page}`
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const getCustomerDetail = async username => {
    try {
        const res = await axiosPrivateInstance.get(
            `/restaurant-customer-details/?username=${username}`
        );
        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const getCustomerOrder = async (username, page) => {
    try {
        const res = await axiosPrivateInstance.get(
            `/restaurant-customers-orders/?username=${username}&pageitems=4&page=${page}`
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
