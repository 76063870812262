import React, {useState, useCallback} from 'react';
import {getAllUpcomingDeliveries} from '../../../services/OrderService';
import DeliveryList from '../../components/Orders/DeliveryList';

const Delivery = () => {
    const [deliveryList, setDeliveryList] = useState([]);
    const sort = 3;
    const [pagination, setPagination] = useState();
    const [isloading, setIsloading] = useState(true);

    const getUpcomingDeliveries = useCallback(() => {
        getAllUpcomingDeliveries().then(res => {
            setIsloading(false);
            res.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
            setPagination(
                Array(Math.ceil(res.length / sort))
                    .fill()
                    .map((_, i) => i + 1)
            );
            setDeliveryList(res);
        });
    }, []);
    return (
        <DeliveryList
            deliveryList={deliveryList}
            setDeliveryList={setDeliveryList}
            getUpcomingDeliveries={getUpcomingDeliveries}
            pagination={pagination}
            sort={sort}
            isloading={isloading}
        />
    );
};

export default Delivery;
