import React, {useState} from 'react';

/// React router dom
import {Switch, Route} from 'react-router-dom';
/// Css
import './index.css';
import './chart.css';
import './step.css';

/// Layout
import Nav from './layouts/nav';
import Footer from './layouts/Footer';

/// Dashboard
import Home from './components/Dashboard/Home';

import Reviews from './components/Dashboard/Reviews';
/// Notifications
import Notifications from './components/Notification';

/// Orders
import Reservations from './pages/Orders/Reservations';
import Delivery from './pages/Orders/Delivery';
import Takeaway from './pages/Orders/Takeaway';
import Catering from './pages/Orders/Catering';

/// Order History
import ReservationHistory from './pages/OrderHistory/ReservationHistory';
import DeliveryHistory from './pages/OrderHistory/DeliveryHistory';
import TakeawayHistory from './pages/OrderHistory/TakeawayHistory';
import CateringHistory from './pages/OrderHistory/CateringHistory';
import ViewDetailpage from './components/OrderHistory/ViewDetailpage';
import ViewCateringDetail from './components/OrderHistory/ViewCateringDetail';
import ViewDeliveryDetail from './components/OrderHistory/ViewDeliveryDetail';
import ViewTakeawayDetail from './components/OrderHistory/ViewTakeawayDetail';
import {getFirebaseToken} from '../services/NotificationService';

/// Users
import Users from './pages/Users';

/// Customers
import Customers from './pages/Customers/Customers';
import CustomerDetail from './pages/Customers/CustomerDetail';

/// Food Menu
import FoodItems from './pages/FoodMenu/FoodItems';
import Cuisines from './pages/FoodMenu/Cuisines';
import Chefs from './pages/FoodMenu/Chefs';

/// Settings
import TableSettings from './pages/Settings/TableSettings';
import CategorySettings from './pages/Settings/TableCategorySettings';
import ReservationSettings from './pages/Settings/ReservationSettings';
import DeliverySettings from './pages/Settings/DeliverySettings';
import TakeawaySettings from './pages/Settings/TakeawaySettings';
import CateringSettings from './pages/Settings/CateringSettings';
import RestaurantSettings from './pages/Settings/RestaurantSettings';

///Profile
import ProfileCard from './pages/Users/ProfileCard';

/// Pages
import LockScreen from './pages/LockScreen';
import Error400 from './pages/Error400';
import Error403 from './pages/Error403';
import Error404 from './pages/Error404';
import Error500 from './pages/Error500';
import Error503 from './pages/Error503';

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';

const Markup = () => {
    let path = window.location.pathname;
    path = path.split('/');
    path = path[path.length - 1];
    let pagePath = path.split('-').includes('page');
    const [activeEvent, setActiveEvent] = useState(!path);

    const routes = [
        /// Dashboard
        {url: '', component: Home},
        {url: 'dashboard', component: Home},

        {url: 'review', component: Reviews},

        /// Orders
        {url: 'reservations', component: Reservations},
        {url: 'delivery', component: Delivery},
        {url: 'take-away', component: Takeaway},
        {url: 'catering', component: Catering},

        /// Order History
        {url: 'reservation-history', component: ReservationHistory},
        {url: 'delivery-history', component: DeliveryHistory},
        {url: 'take-away-history', component: TakeawayHistory},
        {url: 'catering-history', component: CateringHistory},

        /// Order Details
        {
            url: `reservation-detail/:id`,
            component: ViewDetailpage,
        },
        {
            url: `catering-detail/:id`,
            component: ViewCateringDetail,
        },
        {
            url: `take-away-detail/:id`,

            component: ViewTakeawayDetail,
        },
        {
            url: `delivery-detail/:id`,
            component: ViewDeliveryDetail,
        },

        /// Users
        {url: 'users', component: Users},

        ///Customers
        {url: 'customers', component: Customers},
        {url: `customer-detail/:username`, component: CustomerDetail},

        /// Profile
        {url: 'app-profile', component: ProfileCard},

        ///Notifications
        {url: 'notifications', component: Notifications},

        /// Food Menu
        {url: 'food-items', component: FoodItems},
        {url: 'cuisines', component: Cuisines},
        {url: 'chefs', component: Chefs},

        /// Settings
        {url: 'table-settings', component: TableSettings},
        {url: 'table-category', component: CategorySettings},
        {url: 'reservation-settings', component: ReservationSettings},
        {url: 'delivery-settings', component: DeliverySettings},
        {url: 'takeaway-settings', component: TakeawaySettings},
        {url: 'catering-settings', component: CateringSettings},
        {url: 'restaurant-settings', component: RestaurantSettings},

        /// pages
        // { url: 'page-register', component: Registration },
        // {url: 'page-lock-screen', component: LockScreen},
        // {url: 'page-login', component: Login},
        // {url: 'page-error-400', component: Error400},
        // {url: 'page-error-403', component: Error403},
        // {url: 'page-error-404', component: Error404},
        // {url: 'page-error-500', component: Error500},
        // {url: 'page-error-503', component: Error503},
        // {url: 'todo', component: Todo},
    ];

    return (
        <>
            <div
                id={`${!pagePath ? 'main-wrapper' : ''}`}
                className={`${!pagePath ? 'show' : 'mh100vh'}`}
            >
                {!pagePath && (
                    <>
                        <Nav
                            onClick={() => setActiveEvent(!activeEvent)}
                            activeEvent={activeEvent}
                            onClick2={() => setActiveEvent(false)}
                            onClick3={() => setActiveEvent(true)}
                        />
                    </>
                )}
                <div
                    className={` ${
                        !path && activeEvent ? 'rightside-event' : ''
                    } ${!pagePath ? 'content-body' : ''}`}
                >
                    <div
                        className={`${!pagePath ? 'container-fluid' : ''}`}
                        style={{minHeight: window.screen.height - 60}}
                    >
                        <Switch>
                            {routes.map((data, i) => (
                                <Route
                                    key={i}
                                    exact
                                    path={`/${data.url}`}
                                    component={data.component}
                                />
                            ))}
                            <Route key={10} path='*' component={Error404} />
                        </Switch>
                    </div>
                </div>
                {!pagePath && <Footer />}
            </div>
            <ScrollToTop />
        </>
    );
};

export default Markup;
