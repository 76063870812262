import axiosPrivateInstance from './AxiosPrivateInstance';
import {handleApiError} from './SettingsService';

export const getDashboardDetails = async () => {
    try {
        const res = await axiosPrivateInstance.get('restaurant-dashboard/');

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getOrderSummary = async (from_date, to_date, orderType) => {
    try {
        const res = await axiosPrivateInstance.get(
            `total-order-summary/?from_date=${
                from_date ? from_date : ''
            }&to_date=${to_date ? to_date : ''}&ord_type=${orderType}`
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const getAverageOrders = async (from_date, to_date, orderType) => {
    try {
        const res = await axiosPrivateInstance.get(
            `average-orders/?from_date=${from_date}&to_date=${to_date}&ord_type=${orderType}`
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getOrderRevenue = async (from_date, to_date, orderType) => {
    try {
        const res = await axiosPrivateInstance.get(
            `total_revenue/?from_date=${from_date}&to_date=${to_date}`
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const getMonthlyOrders = async year => {
    try {
        const res = await axiosPrivateInstance.get(
            `month-order-count/?year=${year}
            `
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const getCustomerMap = async year => {
    try {
        const res = await axiosPrivateInstance.get(
            `month-customer-count/?year=${year}`
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const getMonthlyRevenue = async year => {
    try {
        const res = await axiosPrivateInstance.get(
            `month-avg-revenue/?year=${year}`
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
