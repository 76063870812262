import React, {useEffect, useState, useMemo} from 'react';
import {Card, Table, Badge, Dropdown} from 'react-bootstrap';
import {rejectCatering} from '../../../services/OrderService';
import Status from './Status';
import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import Loader from './Loader';
const svg1 = (
    <svg width='20px' height='20px' viewBox='0 0 24 24' version='1.1'>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <rect x='0' y='0' width='24' height='24'></rect>
            <circle fill='#000000' cx='5' cy='12' r='2'></circle>
            <circle fill='#000000' cx='12' cy='12' r='2'></circle>
            <circle fill='#000000' cx='19' cy='12' r='2'></circle>
        </g>
    </svg>
);
const notify = (id, type, message, time) => {
    toast.update(id, {
        render: message,
        type: type,
        position: 'top-center',
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        isLoading: false,
    });
};

const CateringList = props => {
    const {cateringList, getUpcomingCaterings, setCateringList, isloading} =
        props;
    console.log(cateringList, 'catering');

    const [filteredList, setFilteredList] = useState(cateringList ?? []);
    useEffect(async () => {
        const res = await getUpcomingCaterings();
    }, [getUpcomingCaterings]);

    useEffect(() => {
        setFilteredList(cateringList);
    }, [cateringList]);
    useEffect(() => {}, [filteredList]);

    const handleReject = async id => {
        try {
            await rejectCatering(id);
            const res = await getUpcomingCaterings();

            setCateringList(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Status
                List={cateringList}
                filteredList={filteredList}
                setFilteredList={setFilteredList}
            />

            <Card>
                <Card.Header>
                    <Card.Title>Live Catering Orders</Card.Title>
                </Card.Header>
                {isloading && <Loader />}
                {!isloading && filteredList && filteredList.length > 0 ? (
                    <Card.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>DELIVERY NO.</strong>
                                    </th>
                                    <th>
                                        <strong>DATE</strong>
                                    </th>
                                    <th>
                                        <strong>TIME</strong>
                                    </th>
                                    <th>
                                        <strong>CUSTOMER</strong>
                                    </th>
                                    <th>
                                        <strong>AMOUNT</strong>
                                    </th>
                                    <th>
                                        <strong>STATUS</strong>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isloading &&
                                filteredList &&
                                filteredList.length > 0
                                    ? filteredList.map(each => (
                                          <tr key={each.booking_id}>
                                              <td>
                                                  <strong>
                                                      {each.booking_id}
                                                  </strong>
                                              </td>
                                              <td>
                                                  {each.date +
                                                      ' ' +
                                                      each.time.slice(
                                                          0,
                                                          -7
                                                      )}{' '}
                                              </td>
                                              <td>{each.time}</td>
                                              <td>{each.customer}</td>
                                              <td>SAR {each.total}</td>
                                              <td>
                                                  <Badge
                                                      variant={
                                                          each.status ===
                                                          'Booking Confirmed'
                                                              ? 'success light'
                                                              : 'warning'
                                                      }
                                                  >
                                                      {each.status}
                                                  </Badge>
                                              </td>
                                              <td>
                                                  <Dropdown>
                                                      <Dropdown.Toggle
                                                          variant={
                                                              each.status ===
                                                              'Booking Confirmed'
                                                                  ? 'success'
                                                                  : 'warning'
                                                          }
                                                          className='light sharp i-false'
                                                      >
                                                          {svg1}
                                                      </Dropdown.Toggle>

                                                      <Dropdown.Menu className='dropdown-menu dropdown-menu-right'>
                                                          <Dropdown.Item
                                                              onClick={() =>
                                                                  handleReject(
                                                                      each.booking_id
                                                                  )
                                                              }
                                                              data-dismiss='alert'
                                                              aria-label='Close'
                                                              className='dropdown-item'
                                                          >
                                                              <i className='las la-times-circle text-danger mr-3 scale5' />
                                                              Reject
                                                          </Dropdown.Item>
                                                          <Dropdown.Item
                                                              as={Link}
                                                              to={`/catering-detail/${each.booking_id}`}
                                                              className='dropdown-item'
                                                          >
                                                              View Details
                                                          </Dropdown.Item>
                                                      </Dropdown.Menu>
                                                  </Dropdown>
                                              </td>
                                          </tr>
                                      ))
                                    : ''}
                            </tbody>
                        </Table>
                    </Card.Body>
                ) : (
                    <div style={{height: '100px'}}>
                        <h5 className='text-center mt-5'>NO ORDER FOUND</h5>
                    </div>
                )}
            </Card>
        </>
    );
};

export default CateringList;
