import {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import {toast} from 'react-toastify';
import moment from 'moment';

import {ErrorMessage} from '@hookform/error-message';
import {useForm, Controller} from 'react-hook-form';
import FormInput from '../../components/FoodMenu/FormInput';
import FormSelectInput from '../../components/FoodMenu/FormInput';
import FormMultiSelectInput from '../../components/Settings/DropdownMultiSelectInput';
import {Table} from 'react-bootstrap';
import {debounce} from 'lodash';
import {
    updateReserSettings,
    getReservationDetails,
} from '../../../services/SettingsService';
import {objectToFormData} from '../../../utils/objectToFormData';

import uploadImageIcon from '../../../images/upload_image.png';
import TimePicker from '../../components/Settings/TimePicker';

const notify = (id, type, message, time) => {
    toast.update(id, {
        render: message,
        type: type,
        position: 'top-center',
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        isLoading: false,
    });
};
const ReservationSettings = props => {
    const [resdetails, setResdetails] = useState();
    const [start_time, setStartTime] = useState('');
    const [end_time, setEndTime] = useState('');
    const [slot_duration, setSlotDuration] = useState('');
    const [time_slots, setTimeSlots] = useState([]);
    const [occasionList, setOccasionList] = useState();
    // const [slotList, setSlotList] = useState([]);

    const uploadRef = useRef(null);
    const days = [
        {
            name: 'reservation_days.sun',
            label: 'Sunday',
        },
        {
            name: 'reservation_days.mon',
            label: 'Monday',
        },
        {
            name: 'reservation_days.tue',
            label: 'Tuesday',
        },
        {
            name: 'reservation_days.wed',
            label: 'wednesday',
        },
        {
            name: 'reservation_days.thu',
            label: 'Thursday',
        },
        {
            name: 'reservation_days.fri',
            label: 'Friday',
        },
        {
            name: 'reservation_days.sat',
            label: 'Saturday',
        },
    ];
    const {
        handleSubmit,
        formState: {errors},
        register,
        setValue,
        control,
        watch,
        resetField,
        // onBlur,
    } = useForm({
        mode: 'all',
        defaultValues: {},
    });

    useEffect(() => {
        getReservationDetails().then(res => setResdetails(res));
    }, []);

    useEffect(() => {
        if (resdetails) {
            let listToPreload = resdetails.occassion_list.map(each => {
                return {label: each, value: each};
            });
            let suggestionList = resdetails.occ_suggestion_list.map(each => {
                return {label: each, value: each};
            });
            setOccasionList([...suggestionList, ...listToPreload]);
            setValue('occassion_list', listToPreload);
            
            const fields = [
                'reservation_days',
                'slot_start_time',
                'slot_end_time',
                'slot_duration',
                'reservation_prior_time',
                'res_charge',
                'valet_parking',
                'slots_list','status',
            ];

            let formattedendtime = moment(
                resdetails.slot_end_time,
                'hh:mm:ss AA'
            ).format('HH:mm:ss');
            let formattedstarttime = moment(
                resdetails.slot_start_time,
                'hh:mm:ss AA'
            ).format('HH:mm:ss');

            resdetails.slot_end_time = formattedendtime;
            resdetails.slot_start_time = formattedstarttime;

            setSlotDuration(resdetails.slot_duration);
            setStartTime(resdetails.slot_start_time);

            setEndTime(formattedendtime);
            setTimeSlots(resdetails.slots_list);

            fields.forEach(field => setValue(field, resdetails[field]));
        }
    }, [setValue, resdetails]);
    const onSubmit = async data => {
        data.occassion_list = data.occassion_list.map(each => each.value);
        data.slots_list = time_slots;
        data.slot_start_time =
            new Date(`2000-01-01 ${data.slot_start_time} AM`).getHours() +
            ':' +
            String(
                new Date(`2000-01-01 ${data.slot_start_time} AM`).getMinutes()
            ).padStart(2, '0') +
            ':' +
            String(
                new Date(`2000-01-01 ${data.slot_start_time} AM`).getSeconds()
            ).padStart(2, '0');
        data.slot_end_time =
            new Date(`2000-01-01 ${data.slot_end_time} `).getHours() +
            ':' +
            String(
                new Date(`2000-01-01 ${data.slot_end_time} `).getMinutes()
            ).padStart(2, '0') +
            ':' +
            String(
                new Date(`2000-01-01 ${data.slot_end_time} `).getSeconds()
            ).padStart(2, '0');

        const toaster = toast.loading('Updating...');

        let postData = objectToFormData(data);
        let res = await updateReserSettings(postData);
        getReservationDetails().then(res => setResdetails(res));
        if (res.status === 'success') {
            notify(toaster, 'success', 'Updated Reservation Settings!', 2000);
        } else {
            notify(toaster, 'error', res?.data?.message, 2000);
        }
    };
    const {ref, ...rest} = register('floor_plan_list');
    const watchfloorplans = watch('floor_plan_list', null);
    const watchstartTime = watch('slot_start_time', null);
    const watchendTime = watch('slot_end_time', null);
    const watchslot = watch('slot_duration', null);

    const debouncedUpdate = debounce(watchslot => {
        gen_slots(watchslot);
    }, 2000);
    useEffect(() => {
        debouncedUpdate(watchslot);
        return () => {
            debouncedUpdate.cancel();
        };
    }, [watchslot, watchendTime, watchstartTime]);

    const slotChange = id => {
        setTimeSlots(time_slots =>
            time_slots.map(each => {
                if (each.id === id) {
                    each.status = !each.status;

                    return each;
                } else {
                    return each;
                }
            })
        );
        let changedSlotList = time_slots.filter(each => {
            if (each.id === id) {
                each.status = !each.status;
                return each;
            }
        });
    };

    const gen_slots = watchslot => {
        if ((watchendTime, watchslot, watchstartTime)) {
            const slot_duration_seconds = watchslot * 60;
            let current_time = new Date().setHours(
                watchstartTime.split(':')[0],
                watchstartTime.split(':')[1],
                0,
                0
            );

            const endtime = new Date().setHours(
                watchendTime.split(':')[0],
                watchendTime.split(':')[1],
                0,
                0
            );

            const slots = [];
            let id = 1;
            while (current_time <= endtime - slot_duration_seconds * 1000) {
                const start_time_str = new Date(
                    current_time
                ).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                });

                const end_time_str = new Date(
                    current_time + slot_duration_seconds * 1000
                ).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                });

                slots.push({
                    id: id,
                    slotes: `${start_time_str} to ${end_time_str}`,
                    status: true,
                });

                id++;
                current_time += slot_duration_seconds * 1000;
            }

            setTimeSlots(slots);
        }
    };

    return (
        <>
            <div className='container mt-1'>
                {resdetails ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='d-flex row'>
                            <div className='col-10 '>
                                <h4 className='mb-4 text-center'>
                                    Restaurant Floor Plans
                                </h4>

                                <div className='image-placeholder'>
                                    <div
                                        className='avatar-edit'
                                        onClick={() =>
                                            uploadRef?.current?.click()
                                        }
                                    >
                                        <input
                                            type='file'
                                            multiple
                                            {...rest}
                                            name='floor_plan_list'
                                            ref={e => {
                                                ref(e);
                                                uploadRef.current = e;
                                            }}
                                            className='custom-file-input'
                                            id='val-img'
                                            hidden
                                        />
                                        <label
                                            htmlFor='imageUpload'
                                            name=''
                                        ></label>
                                    </div>
                                    <div className='avatar-preview'>
                                        <div id='imagePreview'>
                                            {resdetails ||
                                            uploadRef?.current?.files.length ? (
                                                <img
                                                    id='saveImageFile'
                                                    src={
                                                        watchfloorplans &&
                                                        watchfloorplans.length &&
                                                        uploadRef.current.files
                                                            .length
                                                            ? URL.createObjectURL(
                                                                  uploadRef
                                                                      ?.current
                                                                      .files[0]
                                                              )
                                                            : process.env
                                                                  .REACT_APP_API_RESOURCE_URL +
                                                              resdetails
                                                                  ?.floor_plan_list[0]
                                                    }
                                                    alt='upload floor plans pic here'
                                                />
                                            ) : (
                                                <img
                                                    className='mr-3 img-fluid rounded'
                                                    src={uploadImageIcon}
                                                    alt='upload floor plan pic here'
                                                />
                                            )}
                                        </div>
                                        <ErrorMessage
                                            errors={errors}
                                            name='floorplans'
                                            render={({message}) => (
                                                <div className='text-danger fs-12'>
                                                    {message}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6 col-xl-3 col-xxl-6 mb-3 picker-data text-black font-w500'>
                                <div className='color-time-picker'>
                                    <TimePicker
                                        label={'Start time'}
                                        name={'slot_start_time'}
                                        required={true}
                                        Controller={Controller}
                                        control={control}
                                        errors={errors}
                                        id={'slot_start_time'}
                                        ErrorMessage={ErrorMessage}
                                        register={{
                                            ...register('slot_start_time'),
                                            required:
                                                'Reservation Start time is required!',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6 col-xl-3 col-xxl-6 mb-3 picker-data'>
                                <div className='color-time-picker '>
                                    <TimePicker
                                        label={'End time'}
                                        name={'slot_end_time'}
                                        Controller={Controller}
                                        control={control}
                                        errors={errors}
                                        id={'slot_end_time'}
                                        required={true}
                                        ErrorMessage={ErrorMessage}
                                        register={{
                                            ...register('slot_end_time'),
                                            required:
                                                'Reservation End time is required!',
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='col-10'>
                                <FormInput
                                    label={'Reservation Charge'}
                                    id={'res_charge'}
                                    name={'res_charge'}
                                    required={true}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('res_charge', {
                                            required:
                                                'Reservation charge is required!',
                                            pattern: {
                                                // value: /^\d+(\.\d{(1, 2)})?$/,
                                                value: /^\d{1,4}$/,
                                                message:
                                                    'Invalid charge provided!',
                                            },
                                        }),
                                    }}
                                    errors={errors}
                                />
                            </div>

                            <div className='col-10'>
                                <FormInput
                                    label={'Reservation prior time'}
                                    id={'reservation_prior_time'}
                                    name={'reservation_prior_time'}
                                    required={true}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('reservation_prior_time', {
                                            required:
                                                'Reservation prior time is required!',
                                            pattern: {
                                                value: /^\d{1,4}$/,
                                                message:
                                                    'Invalid time provided!',
                                            },
                                        }),
                                    }}
                                    errors={errors}
                                />
                            </div>
                            <div className='form-group mb-3 col-10'>
                                <label
                                    className='text-black font-w500'
                                    htmlFor='slot_duration'
                                >
                                    Slot Duration
                                    <span className='text-danger'>*</span>
                                </label>
                                <input
                                    type='text'
                                    name='slot_duration'
                                    id='slot_duration'
                                    className='form-control'
                                    defaultValue={slot_duration}
                                    required
                                    {...register('slot_duration', {
                                        pattern: {
                                            value: /^\d{1,4}$/,
                                            message:
                                                'Invalid duration provided!',
                                        },
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name='slot_duration'
                                    render={({message}) => (
                                        <div className='text-danger fs-12'>
                                            {message}
                                        </div>
                                    )}
                                />
                            </div>

                            <label className='text-black font-w500 col-12'>
                                Reservation Slots
                            </label>
                            <div className='col-10 tableContainer'>
                                <div
                                    className='table-responsive order-list card-table'
                                    style={{height: '400px'}}
                                >
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <strong>Slots</strong>
                                                </th>

                                                <th>
                                                    <strong>Status</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {time_slots?.length ? (
                                                time_slots.map(slots => (
                                                    <tr key={slots.id}>
                                                        <td>
                                                            <div className='d-flex align-items-center'>
                                                                <span className='w-space-no'>
                                                                    {
                                                                        slots.slotes
                                                                    }
                                                                </span>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className='custom-control custom-checkbox mb-3'>
                                                                <input
                                                                    type='checkbox'
                                                                    checked={
                                                                        slots.status
                                                                    }
                                                                    className='custom-control-input'
                                                                    onChange={() =>
                                                                        slotChange(
                                                                            slots.id
                                                                        )
                                                                    }
                                                                    id={
                                                                        slots.id
                                                                    }
                                                                />
                                                                <label
                                                                    className='custom-control-label'
                                                                    htmlFor={
                                                                        slots.id
                                                                    }
                                                                >
                                                                    Active
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td>
                                                        <div id='preloader-inner'>
                                                            <div className='sk-three-bounce-inner'>
                                                                <div className='sk-child sk-bounce1'></div>
                                                                <div className='sk-child sk-bounce2'></div>
                                                                <div className='sk-child sk-bounce3'></div>
                                                                <div id='preloader-inner-bg'></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            <label className='text-black font-w500 col-12 mt-3'>
                                Reservation days
                            </label>
                            {days.map((day, i) => (
                                <div key={i} className='col-4 '>
                                    <div className='custom-control custom-checkbox mb-3 checkbox-info'>
                                        <input
                                            type='checkbox'
                                            defaultChecked
                                            className='custom-control-input'
                                            {...register(day.name)}
                                            id={day.name}
                                        />
                                        <label
                                            className='custom-control-label'
                                            htmlFor={day.name}
                                        >
                                            {day.label}
                                        </label>
                                    </div>
                                </div>
                            ))}
                            <div className='col-10'>
                                <FormMultiSelectInput
                                    label={'Occasion List'}
                                    id={'val-occasion'}
                                    name={'occassion_list'}
                                    ErrorMessage={ErrorMessage}
                                    Controller={Controller}
                                    control={control}
                                    errors={errors}
                                    required={true}
                                    options={
                                        occasionList
                                            ? occasionList
                                            : [
                                                  {
                                                      value: null,
                                                      label: 'Loading...',
                                                  },
                                              ]
                                    }
                                />
                            </div>
                        </div>
                        <div className='custom-control custom-checkbox mb-3 '>
                            <input
                                type='checkbox'
                                defaultChecked
                                className='custom-control-input'
                                {...register('valet_parking')}
                                id='val_parking'
                            />
                            <label
                                className='custom-control-label'
                                htmlFor='val_parking'
                            >
                                Valet Parking
                            </label>
                        </div>
                        <button className='btn btn-primary btn-block btn-md mb-2 mr-auto'>
                            Update
                        </button>
                    </form>
                ) : (
                    <div id='preloader-inner'>
                        <div className='sk-three-bounce-inner'>
                            <div className='sk-child sk-bounce1'></div>
                            <div className='sk-child sk-bounce2'></div>
                            <div className='sk-child sk-bounce3'></div>
                            <div id='preloader-inner-bg'></div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default ReservationSettings;