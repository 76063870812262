import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import { UserReducer } from './reducers/UserReducer';
import { ErrorReducer } from './reducers/ErrorReducer';

const middleware = applyMiddleware(thunk);

// Check if Redux DevTools extension is available
const composeEnhancers =
  (process.env.REACT_APP_ENV === 'development' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  error: ErrorReducer,
});

// Create the store
export const store = createStore(reducers, composeEnhancers(middleware));
