import axiosPrivateInstance from './AxiosPrivateInstance';
import {getLoggedInUserDetailsAction} from '../store/actions/UserActions';

export const getLoggedInUserData = async dispatch => {
    console.log('called');
    try {
        const res = await axiosPrivateInstance.get('/restaurant-user-profile/');
        // console.log(res, 'profile');
        dispatch(getLoggedInUserDetailsAction(res.data.data));
    } catch (error) {
        console.log(error);
    }
};

export const getAllAdminUsers = async () => {
    try {
        const res = await axiosPrivateInstance.get('/restaurant-user-list/');
        return res.data.data;
    } catch (error) {
        console.log(error);
    }
};

export const getUserDetailById = async id => {
    try {
        const res = await axiosPrivateInstance.get(
            `/restaurant-user-detail/?pk=` + id
        );
        return res.data.data;
    } catch (error) {
        console.log(error);
    }
};

export const updateUserDetailsById = async (id, data) => {
    try {
        const res = await axiosPrivateInstance.patch(
            `/restaurant-user-detail/?pk=` + id,
            data
        );
        console.log(res, 'userupdate');
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const addNewUser = async data => {
    try {
        console.log('entered');
        const res = await axiosPrivateInstance.post(
            '/restaurant-user-list/',
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const changeUserPasswordById = async (id, data) => {
    try {
        const res = await axiosPrivateInstance.patch(
            `restaurant-change-password/?pk=` + id,
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
    }
};

export const deleteUserById = async id => {
    try {
        const res = await axiosPrivateInstance.delete(
            `restaurant-user-detail/?pk=` + id
        );
        return res.data;
    } catch (error) {
        console.log(error);
    }
};
