import {
    GET_LOGGED_IN_USER_DETAILS_ACTION,
    // PROFILE_UPDATE_ACTION,
    // PROFILE_UPDATE_FAIL_ACTION,
} from '../actions/UserActions';

const initialState = {
    email: '',
    img: '',
    mobile: '',
    full_name: '',
    designation: '',
    notif_cnt: '',
};

export function UserReducer(state = initialState, action) {
    if (action.type === GET_LOGGED_IN_USER_DETAILS_ACTION) {
        return {
            ...state,
            email: action.payload.email,
            img: action.payload.img,
            mobile: action.payload.admin_mobile,
            full_name: action.payload.full_name,
            designation: action.payload.designation,
            notif_cnt: action.payload.notif_cnt,
        };
    }
    // if (action.type === PROFILE_UPDATE_ACTION) {
    //     console.log(action.payload, 'REDUCER');
    //     return {
    //         ...state,
    //         email: action.payload.email,
    //         img: action.payload.img,
    //         mobile: action.payload.mobile,
    //         full_name: action.payload.full_name,
    //         designation: action.payload.designation,
    //     };
    // }

    // if (action.type === PROFILE_UPDATE_FAIL_ACTION) {
    //     return {
    //         ...state,
    //         errorMessage: action.payload,
    //         successMessage: '',
    //         showLoading: false,
    //     };
    // }

    return state;
}
