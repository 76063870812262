import axiosPrivateInstance from './AxiosPrivateInstance';
import {handleApiError} from './SettingsService';

export const getAllUpcomingReservations = async () => {
    try {
        const res = await axiosPrivateInstance.get(
            '/restaurant-upcoming-reservations/'
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getAllUpcomingDeliveries = async () => {
    try {
        const res = await axiosPrivateInstance.get(
            'upcoming-skipque-list/?ord_type=Delivery'
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getAllUpcomingTakeaways = async () => {
    try {
        const res = await axiosPrivateInstance.get(
            'upcoming-skipque-list/?ord_type=Takeaway'
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getAllUpcomingCaterings = async () => {
    try {
        const res = await axiosPrivateInstance.get(
            'upcoming-cater-event-list/'
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getAllReservations = async (page, fromDate, toDate) => {
    try {
        const res = await axiosPrivateInstance.get(
            `restaurant-table-reservation-history/?pageitems=4&page=${page}${
                fromDate ? `&from_date=${fromDate}` : ''
            }${toDate ? `&to_date=${toDate}` : ''}`
        );
        console.log(res.data, 'reservation');
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getAllDeliveries = async (page, fromDate, toDate) => {
    try {
        const res = await axiosPrivateInstance.get(
            `restaurant-skipque-history/?pageitems=4&ord_type=Delivery&page=${page}${
                fromDate ? `&from_date=${fromDate}` : ''
            }${toDate ? `&to_date=${toDate}` : ''}`
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getAllTakeaways = async (page, fromDate, toDate) => {
    try {
        const res = await axiosPrivateInstance.get(
            `restaurant-skipque-history/?pageitems=4&ord_type=Takeaway&page=${page}${
                fromDate ? `&from_date=${fromDate}` : ''
            }${toDate ? `&to_date=${toDate}` : ''}`
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getAllCaterings = async (page, fromDate, toDate) => {
    try {
        const res = await axiosPrivateInstance.get(
            `restaurant-caterevent-history/?pageitems=4&page=${page}${
                fromDate ? `&from_date=${fromDate}` : ''
            }${toDate ? `&to_date=${toDate}` : ''}`
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const getReservationDetail = async id => {
    try {
        const res = await axiosPrivateInstance.get(
            `restaurant-table-reservation-detail/?id=${id}`
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const getCateringDetail = async id => {
    try {
        const res = await axiosPrivateInstance.get(
            `upcoming-cater-event-detail/?booking_id=${id}`
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const getSkipthequeDetail = async id => {
    try {
        const res = await axiosPrivateInstance.get(
            `upcoming-skipque-detail/?ord_id=${id}`
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const confirmReservation = async id => {
    try {
        const res = await axiosPrivateInstance.patch(
            `restaurant-table-manual-confirm/?id=${id}`
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const rejectReservation = async id => {
    try {
        const res = await axiosPrivateInstance.delete(
            `restaurant-table-manual-confirm/?id=${id}`
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const rejectCatering = async id => {
    try {
        const res = await axiosPrivateInstance.delete(
            `upcoming-cater-event-detail/?booking_id=${id}`
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const confirmSkipque = async id => {
    try {
        const res = await axiosPrivateInstance.patch(
            `upcoming-skipque-detail/?ord_id=${id}`
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const rejectSkipque = async id => {
    try {
        const res = await axiosPrivateInstance.delete(
            `upcoming-skipque-detail/?ord_id=${id}`
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const completeOrder = async (id) => {
    try {
        const res = await axiosPrivateInstance.patch(
            `upcoming-skipque-detail/?ord_id=${id}&is_completed=true`
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const foodReady = async (id) => {
    try {
        const res = await axiosPrivateInstance.patch(
            `upcoming-skipque-detail/?ord_id=${id}&food_ready=true`
        )
        return res.data.data
    } catch (error) {
        console.log(error);
        handleApiError(error)
    }
}