import React, {useState, useCallback} from 'react';
import {getAllChefList} from '../../../services/FoodItemsService';
import ChefList from '../../components/FoodMenu/ChefList';

const Chefs = () => {
    const [chefList, setChefList] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const getChefList = useCallback(() => {
        getAllChefList().then(res => {
            setIsLoading(false);
            setChefList(res);
        });
    }, []);
    return (
        <ChefList
            chefList={chefList}
            getChefList={getChefList}
            isLoading={isLoading}
        />
    );
};

export default Chefs;
