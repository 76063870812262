import Cookies from 'js-cookie';
import {loginConfirmedAction, logout} from '../store/actions/AuthActions';
import axiosPublicInstance from './AxiosPublicInstance';
import jwt_decode from 'jwt-decode';

axiosPublicInstance.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'multipart/form-data';
    config.withCredentials = true;
    return config;
});

export const getOtp = async mobile => {
    const postData = new FormData();
    postData.append('mobile', mobile);
    const resData = await axiosPublicInstance.post(
        '/restaurant-register-step1/',
        postData
    );
    return resData;
};

export const submitOtp = async data => {
    const postData = new FormData();
    postData.append('eotp', data.eotp);
    const resData = await axiosPublicInstance.post(
        '/restaurant-register-step2/',
        postData
    );
    return resData;
};

export const signUp = async data => {
    const resData = await axiosPublicInstance.post(
        '/restaurant-register-step3/',
        data
    );
    return resData;
};

export const login = async (email, password, fcmToken) => {
    const postData = new FormData();
    postData.append('email', email);
    postData.append('password', password);
    postData.append('webfcm', fcmToken);
    
    const resData = await axiosPublicInstance.post('/login/', postData);
    return resData;
};

export function saveTokenInLocalStorage(refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
}

export function saveTokenInCookies(accessToken) {
    Cookies.set('access', accessToken, {secure: true, sameSite: 'None'});
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export const checkAutoLogin = async (dispatch, history) => {
    const accessTokenString = Cookies.get('access');
    const refreshTokenString = localStorage.getItem('refreshToken');

    if (!refreshTokenString) {
        dispatch(logout(history));
        return;
    }

    let todaysDate = new Date();

    let accessTokenExpireDate = '';
    try {
        accessTokenExpireDate = new Date(
            jwt_decode(accessTokenString).exp * 1000
        );
    } catch (error) {
        dispatch(logout(history));
        return;
    }

    if (todaysDate > accessTokenExpireDate) {
        let refreshTokenExpireDate = new Date(
            jwt_decode(refreshTokenString).exp * 1000
        );

        if (todaysDate > refreshTokenExpireDate) {
            dispatch(logout(history));
            return;
        } else {
            const postData = new FormData();
            postData.append('refresh', refreshTokenString);
            let res = await axiosPublicInstance.post(
                '/login/token/refresh/',
                postData
            );
            saveTokenInCookies(res.data.access);

            dispatch(
                loginConfirmedAction({
                    access: accessTokenString,
                    refresh: refreshTokenString,
                })
            );

            const timer =
                refreshTokenExpireDate.getTime() - todaysDate.getTime();
            runLogoutTimer(dispatch, timer, history);
        }
    } else {
        dispatch(
            loginConfirmedAction({
                access: accessTokenString,
                refresh: refreshTokenString,
            })
        );
        return;
    }
};
