import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

const ViewButton = props => {
    const {ord_id, ord_type} = props;

    const [url, setUrl] = useState('/');

    //Update the URL based on the selected order type
    useEffect(() => {
        if (ord_type === 'delivery') {
            setUrl(`/delivery-detail/${ord_id}`);
        } else if (ord_type === 'reservation') {
            setUrl(`/reservation-detail/${ord_id}`);
        } else if (ord_type === 'takeaway') {
            setUrl(`/take-away-detail/${ord_id}`);
        } else if (ord_type === 'Inhome' || ord_type === 'bid') {
            setUrl(`/catering-detail/${ord_id}`);
        }
    }, [ord_type, ord_id]);

    return (
        <Link to={url}>
            <button className='btn btn-primary btn-sm '>view</button>
        </Link>
    );
};

export default ViewButton;
