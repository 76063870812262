import React, {useState, useMemo} from 'react';
import {useCallback} from 'react';
import {Dropdown} from 'react-bootstrap';

const Status = props => {
    const {List, filteredList, setFilteredList} = props;

    const [selectedStatus, setSelectedStatus] = useState('All Status');
    const statusList = [
        {status: 'All Status', class: 'text-primary'},
        {status: 'Booking Confirmed', class: 'text-success'},

        {status: 'Pending Confirmation', class: 'text-warning'},
    ];

    const filteredupcomingList = useCallback(
        status => {
            setSelectedStatus(status);
            if (status === 'All Status') {
                setFilteredList(List);
            } else {
                let newList = List.filter(item => item.status === status);

                setFilteredList(newList);
            }
        },
        [List]
    );

    return (
        <div className='d-sm-flex mb-lg-4 mb-2'>
            <Dropdown className='dropdown mb-2 ml-auto mr-3'>
                <Dropdown.Toggle
                    to='#'
                    className='i-false btn btn-primary btn-rounded light'
                    data-toggle='dropdown'
                    aria-expanded='false'
                >
                    {selectedStatus}
                </Dropdown.Toggle>
                <Dropdown.Menu className='dropdown-menu dropdown-menu-center'>
                    {statusList.map((statuslist, index) => (
                        <Dropdown.Item
                            className='dropdown-item'
                            key={index}
                            onClick={() => {
                                filteredupcomingList(statuslist.status);
                            }}
                        >
                            <span className={statuslist.class}>
                                {statuslist.status}
                            </span>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default Status;
