import axiosPublicInstance from './AxiosPublicInstance';
import {handleApiError} from './SettingsService';

export const getCountryList = async () => {
    try {
        let result = await axiosPublicInstance.get(`country-list/`);
        console.log(result);
        let resultData = result.data.data;

        resultData.forEach(each => {
            Object.defineProperty(
                each,
                'label',
                Object.getOwnPropertyDescriptor(each, 'country')
            );
            Object.defineProperty(
                each,
                'value',
                Object.getOwnPropertyDescriptor(each, 'id')
            );
           
        });
        return resultData;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getStateList = async countryId => {
    try {
        let result = await axiosPublicInstance.get(
            `state-list/?country_id=${countryId}`
        );

        let resultData = result.data.data;

        resultData.forEach(each => {
            Object.defineProperty(
                each,
                'label',
                Object.getOwnPropertyDescriptor(each, 'state')
            );
            Object.defineProperty(
                each,
                'value',
                Object.getOwnPropertyDescriptor(each, 'id')
            );

            delete each['state'];
            delete each['id'];
            delete each['country'];
            delete each['state_code'];
        });

        return resultData;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getCityList = async (countryId, stateId) => {
    try {
        let result = await axiosPublicInstance.get(
            `city-list/?country_id=${countryId}&state_id=${stateId}`
        );
        let resultData = result.data.data;

        resultData.forEach(each => {
            Object.defineProperty(
                each,
                'label',
                Object.getOwnPropertyDescriptor(each, 'city')
            );
            Object.defineProperty(
                each,
                'value',
                Object.getOwnPropertyDescriptor(each, 'id')
            );

            delete each['state'];
            delete each['country'];
            delete each['city'];
            delete each['id'];
        });

        return resultData;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
