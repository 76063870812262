import React, {useRef} from 'react';

import {useTable, useFilters, usePagination} from 'react-table';

import './filtering.css';
import {useEffect} from 'react';

export const FilteringTable = ({
    columns,
    data,
    total_pages,
    filteredResult,
    paginationClick,
}) => {
    const skipPageResetRef = useRef();
    const tableInstance = useTable(
        {
            pageCount: total_pages,
            manualPagination: true,
            paginationClick,
            columns,
            data,
            initialState: {pageIndex: 0},
            autoResetPage: !skipPageResetRef.current,
        },
        useFilters,
        // useGlobalFilter,
        usePagination
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        // setGlobalFilter,
    } = tableInstance;
    const {pageIndex} = state;
    useEffect(() => {
        skipPageResetRef.current = true;

        paginationClick(pageIndex);
    }, [pageIndex]);

    useEffect(() => {
        gotoPage(0);
    }, [filteredResult]);

    useEffect(() => {
        skipPageResetRef.current = false;
    }, []);

    return (
        <>
            <div className='table-responsive px-3'>
                <table
                    {...getTableProps()}
                    className='table filtering-table table-responsive-lg'
                >
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                        {column.canFilter
                                            ? column.render('Filter')
                                            : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className=''>
                        {page?.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {' '}
                                                {cell.render('Cell')}{' '}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='d-flex justify-content-between'>
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>
                        {''}
                    </span>
                    <span className='table-index'>
                        Go to page :{' '}
                        <input
                            type='number'
                            className='ml-2'
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const pageNumber = e.target.value
                                    ? Number(e.target.value) - 1
                                    : 0;
                                gotoPage(pageNumber);
                            }}
                            min={1}
                            max={pageOptions.length}
                        />
                    </span>
                </div>
                <div className='text-center'>
                    <div className='filter-pagination  mt-3'>
                        <button
                            className=' previous-button'
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        >
                            {'<<'}
                        </button>

                        <button
                            className='previous-button'
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            Previous
                        </button>
                        <button
                            className='next-button'
                            onClick={nextPage}
                            disabled={!canNextPage}
                        >
                            Next
                        </button>
                        <button
                            className=' next-button'
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        >
                            {'>>'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
export default FilteringTable;
