import {history} from '../index.js';
import {loginConfirmedAction, logout} from '../store/actions/AuthActions';
import axiosPublicInstance from '../services/AxiosPublicInstance';
import jwt_decode from 'jwt-decode';
import {saveTokenInCookies, runLogoutTimer} from '../services/AuthService';
import {store} from '../store/store';
import Cookies from 'js-cookie';

export const checkTokenValidity = async () => {
    const accessTokenString = Cookies.get('access');
    const refreshTokenString = localStorage.getItem('refreshToken');

    if (!refreshTokenString) {
        store.dispatch(logout(history));
        return;
    }

    let todaysDate = new Date();

    let accessTokenExpireDate = '';
    try {
        accessTokenExpireDate = new Date(
            jwt_decode(accessTokenString).exp * 1000
        );
    } catch (error) {
        store.dispatch(logout(history));
    }

    if (todaysDate > accessTokenExpireDate) {
        let refreshTokenExpireDate = new Date(
            jwt_decode(refreshTokenString).exp * 1000
        );

        if (todaysDate > refreshTokenExpireDate) {
            store.dispatch(logout(history));
            return;
        } else {
            const postData = new FormData();
            postData.append('refresh', refreshTokenString);
            let res = await axiosPublicInstance.post(
                '/login/token/refresh/',
                postData
            );
            saveTokenInCookies(res.data.access);

            store.dispatch(
                loginConfirmedAction({
                    access: accessTokenString,
                    refresh: refreshTokenString,
                })
            );

            const timer =
                refreshTokenExpireDate.getTime() - todaysDate.getTime();
            runLogoutTimer(store.dispatch, timer, history);
        }
    } else {
        store.dispatch(
            loginConfirmedAction({
                access: accessTokenString,
                refresh: refreshTokenString,
            })
        );
    }
};
