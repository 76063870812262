import React, {useState, useCallback} from 'react';
import {getAllDeliveries} from '../../../services/OrderService';
import DeliveryHistoryList from '../../components/OrderHistory/DeliveryHistoryList';

const DeliveryHistory = () => {
    const [deliveryList, setDeliveryList] = useState();
    const sort = 3;

    const [page, setPage] = useState(1);
    const [totalPages, setTotalpages] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const getDeliveries = useCallback((page, fromDate, toDate) => {
        getAllDeliveries(page, fromDate, toDate).then(res => {
            setTotalpages(res.total_pages, 'total pages');

            setDeliveryList(res.data);
        });
    }, []);
    return (
        <DeliveryHistoryList
            deliveryList={deliveryList}
            getDeliveries={getDeliveries}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            page={page}
            totalPages={totalPages}
        />
    );
};

export default DeliveryHistory;
