import React from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

const DateFilter = props => {
    const {selectedDates, handleDateChange} = props;

    const currentYear = new Date().getFullYear();
    const fromDate = moment(new Date(currentYear, 0, 1)).format('YYYY-MM-DD'); // January 1st of the current year
    const toDate = moment(new Date(currentYear, 11, 31)).format('YYYY-MM-DD'); // December 31st of the current year

    return (
        <div>
            <Flatpickr
                className='form-control'
                placeholder='Select a date'
                options={{
                    mode: 'range',
                    dateFormat: 'Y-m-d',
                    defaultDate:
                        selectedDates && selectedDates.length > 0
                            ? selectedDates
                            : '',
                    onChange: handleDateChange,
                }}
            />
        </div>
    );
};

export default DateFilter;
