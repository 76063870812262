import {useState, useEffect, useCallback, useRef} from 'react';
import {toast} from 'react-toastify';
import {ErrorMessage} from '@hookform/error-message';
import {useForm, Controller} from 'react-hook-form';
import FormInput from '../../components/FoodMenu/FormInput';

import {
    updateDeliverySettings,
    getDeliverySettings,
    updateDeliverySlot,
} from '../../../services/SettingsService';
import {debounce} from 'lodash';

import moment from 'moment';
import TimePicker from '../../components/Settings/TimePicker';
import {objectToFormData} from '../../../utils/objectToFormData';
import {Table} from 'react-bootstrap';
const notify = (id, type, message, time) => {
    toast.update(id, {
        render: message,
        type: type,
        position: 'top-center',
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        isLoading: false,
    });
};
const DeliverySettings = () => {
    const [deldetails, setDeldetails] = useState();
    const [start_time, setStartTime] = useState('');
    const [end_time, setEndTime] = useState('');
    const [slot_duration, setSlotDuration] = useState('');
    const [time_slots, setTimeSlots] = useState([]);
    const {
        handleSubmit,
        formState: {errors},
        register,
        setValue,
        control,
        watch,
    } = useForm({
        mode: 'all',
    });
    useEffect(() => {
        getDeliverySettings().then(res => setDeldetails(res[0]));
    }, []);

    const days = [
        {
            name: 'delivery_days.sun',
            label: 'Sunday',
        },
        {
            name: 'delivery_days.mon',
            label: 'Monday',
        },
        {
            name: 'delivery_days.tue',
            label: 'Tuesday',
        },
        {
            name: 'delivery_days.wed',
            label: 'wednesday',
        },
        {
            name: 'delivery_days.thu',
            label: 'Thursday',
        },
        {
            name: 'delivery_days.fri',
            label: 'Friday',
        },
        {
            name: 'delivery_days.sat',
            label: 'Saturday',
        },
    ];

    useEffect(() => {
        if (deldetails) {
            const fields = [
                'slot_duration',
                'delivery_prior_time',
                'delivery_booking_charge',
                'delivery_days',
                'is_free_delivery',
                'slot_end_time',
                'slot_start_time',
                'slots_list',
            ];
            let formattedendtime = moment(
                deldetails.slot_end_time,
                'hh:mm:ss AA'
            ).format('HH:mm:ss');
            console.log(formattedendtime, 'end');
            let formattedstarttime = moment(
                deldetails.slot_start_time,
                'hh:mm:ss AA'
            ).format('HH:mm:ss');
            setSlotDuration(deldetails.slot_duration);
            setStartTime(formattedstarttime);
            setEndTime(formattedendtime);
            setTimeSlots(deldetails.slots_list);
            deldetails.slot_end_time = formattedendtime;
            deldetails.slot_start_time = formattedstarttime;
            fields.forEach(field => setValue(field, deldetails[field]));
        }
    }, [setValue, deldetails]);

    const onSubmit = async data => {
        console.log(data.slot_start_time, 'Deliverysettings time updating');
        data.slots_list = time_slots;

        // Extract hours and minutes from the submitted start time
        const startTimeParts = data.slot_start_time.split(':');
        data.slot_strt_time = `${startTimeParts[0]}:${startTimeParts[1]}`;

        // Extract hours and minutes from the submitted end time
        const endTimeParts = data.slot_end_time.split(':');
        data.slot_end_time = `${endTimeParts[0]}:${endTimeParts[1]}`;

        // Process other form data as needed

        const toaster = toast.loading('Updating...');

        let postData = objectToFormData(data);
        let res = await updateDeliverySettings(postData);
        getDeliverySettings().then(res => setDeldetails(res[0]));
        if (res.status === 'success') {
            notify(toaster, 'success', 'Updated Delivery Settings!', 2000);
        } else {
            notify(toaster, 'error', res.data.message, 2000);
        }
    };

    const watchstartTime = watch('slot_start_time', null);
    const watchendTime = watch('slot_end_time', null);
    const watchslot = watch('slot_duration', null);
    const debouncedUpdate = debounce(watchslot => {
        gen_slots(watchslot);
    }, 2000);
    useEffect(() => {
        debouncedUpdate(watchslot);
        return () => {
            debouncedUpdate.cancel();
        };
    }, [watchslot, watchendTime, watchstartTime]);
    const slotChange = id => {
        setTimeSlots(time_slots =>
            time_slots.map(each => {
                if (each.id === id) {
                    each.status = !each.status;

                    return each;
                } else {
                    return each;
                }
            })
        );
        let changedSlotList = time_slots.filter(each => {
            if (each.id === id) {
                each.status = !each.status;
                return each;
            }
        });
    };
    const gen_slots = watchslot => {
        if (watchstartTime && watchendTime && watchslot) {
            const slot_duration_seconds = watchslot * 60;
            let current_time = moment(watchstartTime, 'hh:mm:ss A');
            const endtime = moment(watchendTime, 'hh:mm:ss A');

            const slots = [];
            let id = 1;
            while (
                current_time.isBefore(
                    endtime.subtract(slot_duration_seconds, 'seconds')
                )
            ) {
                const start_time_str = current_time.format('HH:mm:ss');
                const end_time_str = current_time
                    .add(slot_duration_seconds, 'seconds')
                    .format('HH:mm:ss');

                slots.push({
                    id: id,
                    slotes: `${start_time_str} to ${end_time_str}`,
                    status: true,
                });

                id++;
            }

            setTimeSlots(slots);
        }
    };

    return (
        <>
            <div className='modal-body'>
                {deldetails ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='d-flex row'>
                            <div className='col-md-6 col-xl-3 col-xxl-6 mb-3 picker-data'>
                                <div className='color-time-picker'>
                                    <TimePicker
                                        label={'Start time'}
                                        name={'slot_start_time'}
                                        required={true}
                                        Controller={Controller}
                                        control={control}
                                        errors={errors}
                                        id={'slot_start_time'}
                                        ErrorMessage={ErrorMessage}
                                        register={{
                                            ...register('slot_start_time'),
                                            required:
                                                'Delivery Start time is required!',
                                        }}
                                        type='time'
                                        step={1800}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6 col-xl-3 col-xxl-6 mb-3 picker-data'>
                                <div className='color-time-picker '>
                                    <TimePicker
                                        label={'End time'}
                                        name={'slot_end_time'}
                                        Controller={Controller}
                                        control={control}
                                        errors={errors}
                                        id={'slot_end_time'}
                                        required={true}
                                        ErrorMessage={ErrorMessage}
                                        register={{
                                            ...register('slot_end_time'),
                                            required:
                                                'Delivery End time is required!',
                                        }}
                                        type='time'
                                        step={1800}
                                    />
                                </div>
                            </div>

                            <div className='col-10'>
                                <FormInput
                                    label={'Delivery Prior time'}
                                    id={'delivery_prior_time'}
                                    name={'delivery_prior_time'}
                                    required={true}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('delivery_prior_time', {
                                            required:
                                                'Delivery Prior time is required!',

                                            pattern: {
                                                // value: /^\d+(\.\d{(1, 2)})?$/,
                                                value: /^\d{1,4}$/,
                                                message:
                                                    'Invalid time provided!',
                                            },
                                        }),
                                    }}
                                    errors={errors}
                                />
                            </div>

                            <div className='col-10'>
                                <FormInput
                                    label={'Delivery Charge'}
                                    id={'delivery_booking_charge'}
                                    name={'delivery_booking_charge'}
                                    required={true}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('delivery_booking_charge', {
                                            required:
                                                'Delivery charge is required!',
                                            pattern: {
                                                // value: /^\d+(\.\d{(1, 2)})?$/,
                                                value: /^\d{1,4}$/,
                                                message:
                                                    'Invalid charge provided!',
                                            },
                                        }),
                                    }}
                                    errors={errors}
                                />
                            </div>
                            <div className='form-group mb-3 col-10'>
                                <label
                                    className='text-black font-w500'
                                    htmlFor='slot_duration'
                                >
                                    Slot Duration
                                    <span className='text-danger'>*</span>
                                </label>
                                <input
                                    type='text'
                                    name='slot_duration'
                                    id='slot_duration'
                                    className='form-control'
                                    defaultValue={slot_duration}
                                    required
                                    {...register('slot_duration', {
                                        pattern: {
                                            value: /^\d{1,4}$/,
                                            message:
                                                'Invalid duration provided!',
                                        },
                                    })}
                                    // onBlur={e => gen_slots(e.target.value)}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name='slot_duration'
                                    render={({message}) => (
                                        <div className='text-danger fs-12'>
                                            {message}
                                        </div>
                                    )}
                                />
                            </div>
                            <label className='text-black font-w500 col-12'>
                                Delivery Slots
                            </label>
                            <div className='col-10 tableContainer'>
                                {/* <div className='card-body p-0 '> */}
                                <div
                                    className='table-responsive order-list card-table'
                                    style={{height: '400px'}}
                                >
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <strong>Slots</strong>
                                                </th>

                                                <th>
                                                    <strong>Status</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {time_slots?.length ? (
                                                time_slots.map(slots => (
                                                    <tr key={slots.id}>
                                                        <td>
                                                            <div className='d-flex align-items-center'>
                                                                <span className='w-space-no'>
                                                                    {
                                                                        slots.slotes
                                                                    }
                                                                </span>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className='custom-control custom-checkbox mb-3'>
                                                                <input
                                                                    type='checkbox'
                                                                    checked={
                                                                        slots.status
                                                                    }
                                                                    className='custom-control-input'
                                                                    onChange={() =>
                                                                        slotChange(
                                                                            slots.id
                                                                        )
                                                                    }
                                                                    id={
                                                                        slots.id
                                                                    }
                                                                />
                                                                <label
                                                                    className='custom-control-label'
                                                                    htmlFor={
                                                                        slots.id
                                                                    }
                                                                >
                                                                    Active
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                                {/* </div> */}
                            </div>

                            <label className='text-black font-w500 col-12'>
                                Delivery days
                            </label>
                            {days.map((day, i) => (
                                <div className='col-4 ' key={i}>
                                    <div className='custom-control custom-checkbox mb-3 checkbox-info'>
                                        <input
                                            type='checkbox'
                                            defaultChecked
                                            className='custom-control-input'
                                            {...register(day.name)}
                                            id={day.name}
                                        />
                                        <label
                                            className='custom-control-label'
                                            htmlFor={day.name}
                                        >
                                            {day.label}
                                        </label>
                                    </div>
                                </div>
                            ))}

                            <div className='col-12'>
                                <div className='custom-control custom-checkbox mb-3 '>
                                    <input
                                        type='checkbox'
                                        defaultChecked
                                        className='custom-control-input'
                                        {...register('is_free_delivery')}
                                        id='is_free_delivery'
                                    />
                                    <label
                                        className='custom-control-label'
                                        htmlFor='is_free_delivery'
                                    >
                                        Free Delivery
                                    </label>
                                </div>
                            </div>
                            <button className='btn btn-primary btn-block btn-md mb-2 mr-auto'>
                                Update
                            </button>
                        </div>
                    </form>
                ) : (
                    <div id='preloader-inner'>
                        <div className='sk-three-bounce-inner'>
                            <div className='sk-child sk-bounce1'></div>
                            <div className='sk-child sk-bounce2'></div>
                            <div className='sk-child sk-bounce3'></div>
                            <div id='preloader-inner-bg'></div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default DeliverySettings;
