import React, {Fragment, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
    getCateringDetail,
    rejectCatering,
} from '../../../services/OrderService';
// images
import {Table, Badge, Dropdown} from 'react-bootstrap';
const svg1 = (
    <svg width='20px' height='20px' viewBox='0 0 24 24' version='1.1'>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <rect x='0' y='0' width='24' height='24'></rect>
            <circle fill='#000000' cx='5' cy='12' r='2'></circle>
            <circle fill='#000000' cx='12' cy='12' r='2'></circle>
            <circle fill='#000000' cx='19' cy='12' r='2'></circle>
        </g>
    </svg>
);

const ViewCateringDetail = () => {
    let {id} = useParams();
    const [data, setData] = useState('');

    useEffect(async () => {
        const res = await getCateringDetail(id);

        setData(res);
    }, []);

    const handleReject = async id => {
        try {
            await rejectCatering(id);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Fragment>
            <div className='row'>
                {data?.length > 0 ? (
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-header'>
                                {' '}
                                <strong> {data[0].booking_id}</strong>
                                <strong> {data[0].date}</strong>
                                <strong> {data[0].mobile}</strong>
                                <div className='d-flex'>
                                    <Badge
                                        variant={
                                            data[0].status ===
                                                'Booking Confirmed' ||
                                            data[0].status ===
                                                'Booking Completed' ||
                                            data[0].status === 'Order Completed'
                                                ? 'success'
                                                : 'warning'
                                        }
                                    >
                                        {data[0].status}
                                    </Badge>
                                    <div className='ml-3'>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant={
                                                    data[0].status ===
                                                    'Booking Confirmed'
                                                        ? 'success'
                                                        : 'warning'
                                                }
                                                className='light sharp i-false'
                                            >
                                                {svg1}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='dropdown-menu dropdown-menu-right'>
                                                <Dropdown.Item
                                                    onClick={() =>
                                                        handleReject(
                                                            data[0].booking_id
                                                        )
                                                    }
                                                    data-dismiss='alert'
                                                    aria-label='Close'
                                                    className='dropdown-item'
                                                >
                                                    <i className='las la-times-circle text-danger mr-3 scale5' />
                                                    Reject
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>

                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-lg-5 col-sm-5'>
                                        <table className=' table-clear'>
                                            <tbody>
                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>
                                                            Customer
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].customer}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>
                                                            {' '}
                                                            Occassion
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].event}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left px-5 py-2 '>
                                                        <strong>
                                                            Location
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].location}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left px-5 py-2 '>
                                                        <strong>
                                                            {' '}
                                                            Location Type
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].location_type}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>{' '}
                                    </div>
                                    <div className='col-lg-4 col-sm-5 ml-auto'>
                                        <table className=' table-clear'>
                                            <tbody>
                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>
                                                            Service Type
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].serv_type}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>Type</strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].live_cook ===
                                                        true ? (
                                                            <strong>
                                                                Live Cook
                                                            </strong>
                                                        ) : (
                                                            <strong>
                                                                Pre Cook
                                                            </strong>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>
                                                            Num of Guests
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].nog}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>{' '}
                                    </div>
                                </div>

                                <div className='table-responsive order-list card-table mt-5'>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <strong> ID</strong>
                                                </th>
                                                <th>
                                                    <strong>NAME</strong>
                                                </th>
                                                <th>
                                                    <strong>OCCASSION</strong>
                                                </th>
                                                <th>
                                                    <strong>
                                                        CUSTOMER SUGGESTED QUOTE
                                                    </strong>
                                                </th>
                                                <th>
                                                    <strong>
                                                        ACTUAL QUOTE
                                                    </strong>
                                                </th>
                                                <th>
                                                    <strong>
                                                        PAYMENT STATUS
                                                    </strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='w-space-no'>
                                                            {data[0].booking_id}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='w-space-no'>
                                                            {data[0].customer}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='w-space-no'>
                                                            {data[0].event}
                                                        </span>
                                                    </div>
                                                </td>{' '}
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='w-space-no'>
                                                            {data[0].budget}
                                                        </span>
                                                    </div>
                                                </td>{' '}
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='w-space-no'>
                                                            {
                                                                data[0]
                                                                    .confrm_amount
                                                            }
                                                        </span>
                                                    </div>
                                                </td>{' '}
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='w-space-no'>
                                                            {
                                                                data[0]
                                                                    .paymentstaus
                                                            }
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>

                                <div className='row'>
                                    <div className='col-lg-4 col-sm-5'> </div>
                                    <div className='col-lg-4 col-sm-5 ml-auto'>
                                        <table className='table table-clear'>
                                            <tbody>
                                                <tr>
                                                    <td className='left'>
                                                        <strong>
                                                            Payment Amount
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].confrm_amount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left'>
                                                        <strong>
                                                            Payment Mode
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].payment_mode}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left'>
                                                        <strong>
                                                            Total Amount
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        $679,76
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left'>
                                                        <strong>Total</strong>
                                                    </td>
                                                    <td className='right'>
                                                        <strong>
                                                            $7.477,36
                                                        </strong>
                                                        <br />
                                                        <strong>
                                                            0.15050000 BTC
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div id='preloader-inner'>
                        <div className='sk-three-bounce-inner'>
                            <div className='sk-child sk-bounce1'></div>
                            <div className='sk-child sk-bounce2'></div>
                            <div className='sk-child sk-bounce3'></div>
                            <div id='preloader-inner-bg'></div>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default ViewCateringDetail;
