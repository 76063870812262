import {useState} from 'react';
import React from 'react';

const YearPicker = ({selectedYear, handleYearChange}) => {
    const [startYear] = useState(2010);
    const currentYear = new Date().getFullYear();
    const years = Array.from(
        {length: currentYear - startYear + 1},
        (_, i) => startYear + i
    );

    return (
        <select
            className='form-control'
            value={selectedYear}
            onChange={handleYearChange}
        >
            {years.map(year => (
                <option key={year} value={year}>
                    {year}
                </option>
            ))}
        </select>
    );
};
export default YearPicker;
