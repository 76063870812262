import React, {Fragment, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getReservationDetail} from '../../../services/OrderService';
import {rejectReservation} from '../../../services/OrderService';
// images
import {Table, Badge, Dropdown} from 'react-bootstrap';
const svg1 = (
    <svg width='20px' height='20px' viewBox='0 0 24 24' version='1.1'>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <rect x='0' y='0' width='24' height='24'></rect>
            <circle fill='#000000' cx='5' cy='12' r='2'></circle>
            <circle fill='#000000' cx='12' cy='12' r='2'></circle>
            <circle fill='#000000' cx='19' cy='12' r='2'></circle>
        </g>
    </svg>
);

const ViewDetailpage = () => {
    let {id} = useParams();
    const [data, setData] = useState('');

    useEffect(async () => {
        const res = await getReservationDetail(id);

        setData(res);
    }, []);

    const handleReject = async id => {
        try {
            await rejectReservation(id);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Fragment>
            <div className='row'>
                {data?.length > 0 ? (
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-header'>
                                {' '}
                                <strong>{data[0].Reservation_no}</strong>
                                <strong> {data[0].date}</strong>
                                <div className='d-flex'>
                                    <Badge
                                        variant={
                                            data[0].status ===
                                                'Booking Confirmed' ||
                                            data[0].status ===
                                                'Booking Completed' ||
                                            data[0].status === 'Order Completed'
                                                ? 'success'
                                                : 'warning'
                                        }
                                    >
                                        {data[0].status}
                                    </Badge>
                                    <div className='ml-3'>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant={
                                                    data[0].status ===
                                                    'Booking Confirmed'
                                                        ? 'success'
                                                        : 'warning'
                                                }
                                                className='light sharp i-false'
                                            >
                                                {svg1}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='dropdown-menu dropdown-menu-right'>

                                                {/* {data[0].status === "Booking Confirmed" && (
                                                     <Dropdown.Item
                                                    onClick={() =>
                                                        handleReject(
                                                            data[0]
                                                                .Reservation_no
                                                        )
                                                    }
                                                    data-dismiss='alert'
                                                    aria-label='Close'
                                                    className='dropdown-item'
                                                >
                                                    <i className='las la-times-circle text-danger mr-3 scale5' />
                                                    Order Completed
                                                </Dropdown.Item>
                                                    
                                                )} */}
                                                {
                                                    data[0].status !== "Booking Rejected" && (
                                                         <Dropdown.Item
                                                    onClick={() =>
                                                        handleReject(
                                                            data[0]
                                                                .Reservation_no
                                                        )
                                                    }
                                                    data-dismiss='alert'
                                                    aria-label='Close'
                                                    className='dropdown-item'
                                                >
                                                    <i className='las la-times-circle text-danger mr-3 scale5' />
                                                    Reject
                                                </Dropdown.Item>

                                                    )
                                                }
                                               
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-lg-6 col-sm-5'>
                                        <table className=' table-clear'>
                                            <tbody>
                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>
                                                            Initiator
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].initiator}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>Slote</strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].sloteid}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left px-5 py-2 '>
                                                        <strong>Time</strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].time}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>{' '}
                                    </div>
                                    <div className='col-lg-4 col-sm-5 ml-auto'>
                                        <table className=' table-clear'>
                                            <tbody>
                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>
                                                            Table No
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].table_no}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>
                                                            Num of Guests
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].n_o_g}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>
                                                            Occasion
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].occasion}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>{' '}
                                    </div>
                                </div>

                                {data[0].foodItems?.length > 0 ? (
                                    <div className='table-responsive order-list card-table mt-5'>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <strong>NAME</strong>
                                                    </th>

                                                    <th>
                                                        <strong>
                                                            CUSTOMISATION
                                                        </strong>
                                                    </th>
                                                    <th>
                                                        <strong>
                                                            QUANTITY
                                                        </strong>
                                                    </th>
                                                    <th>
                                                        <strong>PRICE</strong>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data[0].foodItems.length >
                                                0 ? (
                                                    data[0].foodItems.map(
                                                        food => (
                                                            <tr
                                                                key={
                                                                    food.foodName
                                                                }
                                                            >
                                                                <td>
                                                                    <div className='d-flex align-items-center'>
                                                                        <span className='w-space-no'>
                                                                            {
                                                                                food.foodName
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <div className='d-flex align-items-center'>
                                                                        <span className='w-space-no'>
                                                                            {
                                                                                food.foodType
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='d-flex align-items-center'>
                                                                        <span className='w-space-no'>
                                                                            {
                                                                                food.quantity
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <div className='d-flex align-items-center'>
                                                                        <span className='w-space-no'>
                                                                            {
                                                                                food.price
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <tr className='text-center'></tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                ) : (
                                    <div style={{height: '100px'}}>
                                        <h5 className='text-center mt-5'>
                                            NO ORDER FOUND
                                        </h5>
                                    </div>
                                )}

                                <div className='row'>
                                    <div className='col-lg-4 col-sm-5'> </div>
                                    <div className='col-lg-4 col-sm-5 ml-auto'>
                                        <table className='table table-clear'>
                                            <tbody>
                                                <tr>
                                                    <td className='left'>
                                                        <strong>
                                                            Food Charge
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].food_charge}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className='left'>
                                                        <strong>
                                                            Reservation Charge
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].res_charge}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left'>
                                                        <strong>VAT</strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].vat}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left'>
                                                        <strong>TOTAL</strong>
                                                    </td>
                                                    <td className='right'>
                                                        <strong>
                                                            {
                                                                data[0]
                                                                    .total_amount
                                                            }
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div id='preloader-inner'>
                        <div className='sk-three-bounce-inner'>
                            <div className='sk-child sk-bounce1'></div>
                            <div className='sk-child sk-bounce2'></div>
                            <div className='sk-child sk-bounce3'></div>
                            <div id='preloader-inner-bg'></div>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default ViewDetailpage;
