import React, {useState, useCallback} from 'react';
import {getAllCuisineList} from '../../../services/FoodItemsService';
import CuisineList from '../../components/FoodMenu/CuisineList';

const Cuisines = () => {
    const [cuisineList, setCuisineList] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const getCuisineList = useCallback(() => {
        getAllCuisineList().then(res => {
            res.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
            setIsLoading(false);
            setCuisineList(res);
        });
    }, []);
    return (
        <CuisineList
            cuisineList={cuisineList}
            getCuisineList={getCuisineList}
            isLoading={isLoading}
        />
    );
};

export default Cuisines;
