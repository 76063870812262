import {useState, useEffect, useCallback, useRef} from 'react';
import {Modal} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {ErrorMessage} from '@hookform/error-message';
import {useForm, Controller} from 'react-hook-form';
import FormInput from '../FoodMenu/FormInput';
import FormSelectInput from '../FoodMenu/FormSelectInput';
import {
    getAllTable,
    getAllCategoryList,
    updateTableDetails,
    addNewTable,
    deleteTableById,
} from '../../../services/SettingsService';
import {objectToFormData} from '../../../utils/objectToFormData';
// images
import uploadImageIcon from '../../../images/upload_image.png';
import swal from 'sweetalert';
const notify = (id, type, message, time) => {
    toast.update(id, {
        render: message,
        type: type,
        position: 'top-center',
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        isLoading: false,
    });
};

const AddTable = props => {
    const {
        addNewModal,
        setAddNewModal,
        setTableDetails,
        tableDetails,
        tableId,
    } = props;

    const [tableList, setTableList] = useState();
    const [categoryList, setCategoryList] = useState();

    const uploadRef = useRef(null);
    const {
        handleSubmit,
        formState: {errors},
        register,
        setValue,
        control,
        watch,
    } = useForm({mode: 'all'});
    const {ref, ...rest} = register('img');

    const watchImage = watch('img', null);

    const getTableList = useCallback(() => {
        getAllTable().then(res => {
            res.forEach(each => {
                Object.defineProperty(
                    each,
                    'label',
                    Object.getOwnPropertyDescriptor(each, 'name')
                );
                Object.defineProperty(
                    each,
                    'value',
                    Object.getOwnPropertyDescriptor(each, 'id')
                );
            });
            let mappedTableList = res
                .map(each => {
                    each.value = +each.value;
                    return each;
                })
                .filter(each => each.status);
            setTableList(mappedTableList);
        });
    }, [setTableList]);

    const getCategoryList = useCallback(() => {
        getAllCategoryList().then(res => {
            res.forEach(each => {
                Object.defineProperty(
                    each,
                    'label',
                    Object.getOwnPropertyDescriptor(each, 'name')
                );
                Object.defineProperty(
                    each,
                    'value',
                    Object.getOwnPropertyDescriptor(each, 'id')
                );
            });
            let mappedCategoryList = res
                .map(each => {
                    each.value = +each.value;
                    return each;
                })
                .filter(each => each.status);
            setCategoryList(mappedCategoryList);
        });
    }, [setCategoryList]);

    useEffect(() => {
        getCategoryList();
    }, [getCategoryList]);

    useEffect(() => {
        if (tableId && tableDetails) {
            const fields = [
                'table_no',
                'nop',
                'min_nop',
                'desc',
                'desc_arb',
                'category',
                'status',
            ];

            fields.forEach(field => setValue(field, tableDetails[field]));
        }
    }, [setValue, tableId, tableDetails]);
    const onClickDeleteTable = () => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this table!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then(async willDelete => {
            if (willDelete) {
                let res = await deleteTableById(tableId);
                if (res.status === 'success') {
                    swal(`The cuisine ${tableDetails.name} has been deleted!`, {
                        icon: 'success',
                    });
                    setAddNewModal(false);
                    getTableList();
                } else {
                    swal(res.data.message);
                }
            }
        });
    };

    console.log(tableDetails)

    const onSubmit = async data => {
        if (tableId && tableDetails) {
            const fields = [
                'table_no',
                'nop',
                'min_nop',
                'img',
                'desc',
                'desc_arb',
                'category',
                'status',
            ];
            fields.forEach(field => {
                if (field === 'img' && data[field].length < 1) {
                    delete data[field];
                } else if (field === 'img') {
                    data.img = data.img[0];
                }
                if (data[field] === undefined) {
                    delete data[field];
                }
            });
            let postData = objectToFormData(data, '');
            const toaster = toast.loading('Updating...');
            let res = await updateTableDetails(tableId, postData);
            if (res.status === 'success') {
                notify(toaster, 'success', 'Table details updated!', 1000);
                setAddNewModal(false);
            } else {
                notify(toaster, 'error', res.data.message, 1000);
            }
        } else {
            const toaster = toast.loading('Creating...');
            data.img = data.img[0];

            let postData = objectToFormData(data);
            let res = await addNewTable(postData);
            if (res.status === 'success') {
                notify(toaster, 'success', 'Created new Table!', 2000);
                setAddNewModal(false);
            } else {
                notify(toaster, 'error', res.data.message, 2000);
            }
        }
    };

    if (tableId && !tableDetails) {
        return (
            <div id='preloader-inner'>
                <div className='sk-three-bounce-inner'>
                    <div className='sk-child sk-bounce1'></div>
                    <div className='sk-child sk-bounce2'></div>
                    <div className='sk-child sk-bounce3'></div>
                    <div id='preloader-inner-bg'></div>
                </div>
            </div>
        );
    } else {
        return (
            <Modal
                show={addNewModal}
                onHide={setAddNewModal}
                className='modal fade'
                id='addNewModal'
            >
                <>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                {tableDetails && tableId
                                    ? 'Update Table'
                                    : 'Add Table'}
                            </h5>
                            <button
                                type='button'
                                className='close'
                                data-dismiss='modal'
                                onClick={() => setAddNewModal(false)}
                            >
                                <span>×</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='image-placeholder'>
                                    <div
                                        className='avatar-edit'
                                        onClick={() =>
                                            uploadRef?.current?.click()
                                        }
                                    >
                                        <input
                                            type='file'
                                            {...rest}
                                            name='img'
                                            ref={e => {
                                                ref(e);
                                                uploadRef.current = e;
                                            }}
                                            className='custom-file-input'
                                            id='val-img'
                                            hidden
                                        />
                                        <label
                                            htmlFor='imageUpload'
                                            name=''
                                        ></label>
                                    </div>
                                    <div className='avatar-preview'>
                                        <div id='imagePreview'>
                                            {tableDetails ||
                                            uploadRef?.current?.files.length ? (
                                                <img
                                                    id='saveImageFile'
                                                    src={
                                                        watchImage &&
                                                        watchImage.length &&
                                                        uploadRef.current.files
                                                            .length
                                                            ? URL.createObjectURL(
                                                                  uploadRef
                                                                      ?.current
                                                                      .files[0]
                                                              )
                                                            : process.env
                                                                  .REACT_APP_API_RESOURCE_URL +
                                                              tableDetails?.img
                                                    }
                                                    alt='upload table pic here'
                                                />
                                            ) : (
                                                <img
                                                    className='mr-3 img-fluid rounded'
                                                    src={uploadImageIcon}
                                                    alt='upload table pic here'
                                                />
                                            )}
                                        </div>
                                        <ErrorMessage
                                            errors={errors}
                                            name='img'
                                            render={({message}) => (
                                                <div className='text-danger fs-12'>
                                                    {message}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>

                                <FormInput
                                    label={'Table_number'}
                                    id={'val-table_no'}
                                    name={'table_no'}
                                    required={true}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('table_no', {
                                            required:
                                                'Table number is required!',
                                        }),
                                    }}
                                    errors={errors}
                                />

                                <FormInput
                                    label={'Description'}
                                    id={'val-desc'}
                                    name={'desc'}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('desc'),
                                    }}
                                    errors={errors}
                                />

                                <FormInput
                                    label={'Arabic Description'}
                                    id={'val-desc'}
                                    name={'desc_arb'}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('desc_arb'),
                                    }}
                                    errors={errors}
                                />
                                <div className='d-flex row'>
                                    <div className='col-6'>
                                        <FormInput
                                            label={'Number of persons'}
                                            id={'val-nop'}
                                            name={'nop'}
                                            required={true}
                                            ErrorMessage={ErrorMessage}
                                            register={{
                                                ...register('nop', {
                                                    required:
                                                        'Number of persons is required!',
                                                }),
                                            }}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <FormInput
                                            label={'Minimum persons'}
                                            id={'val-min_nop'}
                                            name={'min_nop'}
                                            required={true}
                                            ErrorMessage={ErrorMessage}
                                            register={{
                                                ...register('min_nop', {
                                                    required:
                                                        'Minimum Number of Persons required!',
                                                }),
                                            }}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <FormSelectInput
                                    label={'Category'}
                                    id={'val-category'}
                                    name={'category'}
                                    ErrorMessage={ErrorMessage}
                                    Controller={Controller}
                                    control={control}
                                    errors={errors}
                                    required={true}
                                    options={
                                        categoryList
                                            ? categoryList
                                            : [
                                                  {
                                                      value: null,
                                                      label: 'Loading...',
                                                  },
                                              ]
                                    }
                                />

                                <div className='custom-control custom-checkbox mb-3'>
                                    <input
                                        type='checkbox'
                                        defaultChecked
                                        className='custom-control-input'
                                        {...register('status')}
                                        id='val-status'
                                    />
                                    <label
                                        className='custom-control-label'
                                        htmlFor='val-status'
                                    >
                                        Active
                                    </label>
                                </div>

                                <div className='modal-footer'>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                    >
                                        {tableId && tableDetails
                                            ? 'Update'
                                            : 'Add'}
                                    </button>

                                    {tableId && tableDetails ? (
                                        <button
                                            className='btn btn-danger'
                                            type='button'
                                            onClick={onClickDeleteTable}
                                        >
                                            Delete
                                        </button>
                                    ) : (
                                        ' '
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            </Modal>
        );
    }
};

export default AddTable;
