import React, {useState, useCallback} from 'react';
import {getAllUpcomingTakeaways} from '../../../services/OrderService';
import TakeawayList from '../../components/Orders/TakeawayList';

const Takeaway = () => {
    const [takeawayList, setTakeawayList] = useState([]);
    const sort = 3;
    const [pagination, setPagination] = useState();
    const [isloading, setIsloading] = useState(true);

    const getUpcomingTakeaways = useCallback(() => {
        getAllUpcomingTakeaways().then(res => {
            setIsloading(false);
            res.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
            setPagination(
                Array(Math.ceil(res.length / sort))
                    .fill()
                    .map((_, i) => i + 1)
            );
            setTakeawayList(res);
        });
    }, []);
    return (
        <TakeawayList
            takeawayList={takeawayList}
            setTakeawayList={setTakeawayList}
            getUpcomingTakeaways={getUpcomingTakeaways}
            pagination={pagination}
            sort={sort}
            isloading={isloading}
        />
    );
};

export default Takeaway;
