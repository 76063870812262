import React, {useState, useEffect, useRef} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {connect} from 'react-redux';
import {ErrorMessage} from '@hookform/error-message';
import FormInput from '../Registration/FormInput';
import FormSelectInput from './FormSelectInput';
import GMap from '../Registration/Map';
import {
    getCountryList,
    getStateList,
    getCityList,
} from '../../../services/LocationService';
import {
    getRestaurantSettings,
    updateRestaurantSettings,
    deleteRestaurantImg1,
    deleteRestaurantImg2,
    deleteRestaurantImg3,
    deleteRestaurantImg4,
} from '../../../services/SettingsService';

import {loadingToggleAction} from '../../../store/actions/AuthActions';
import {objectToFormData} from '../../../utils/objectToFormData';
import {toast} from 'react-toastify';
const RestaurantSettings = () => {
    const {
        handleSubmit,
        formState: {errors},
        register,
        control,
        setValue,
        watch,
        resetField,
        setError,
    } = useForm({mode: 'all'});

    const [countryList, setCountryList] = useState('');
    const [stateList, setStateList] = useState('');
    const [cityList, setCityList] = useState('');
    const [stateLoading, setStateLoading] = useState(false);
    const [cityLoading, setCityLoading] = useState(false);
    const [resSettings, setResSettings] = useState();
    const [imageList, setImageList] = useState([]);

    const [img1, setImg1] = useState(null);
    const [img2, setImg2] = useState(null);
    const [img3, setImg3] = useState(null);
    const [img4, setImg4] = useState(null);

    const inputRef = useRef(null);
    const watchCountrySelection = watch('country', null);
    const watchStateSelection = watch('state', null);
    const watchCitySelection = watch('city', null);
    const notify = (id, type, message, time) => {
        toast.update(id, {
            render: message,
            type: type,
            position: 'top-center',
            autoClose: time,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            isLoading: false,
        });
    };

    useEffect(() => {
        const unloadCallback = event => {
            if (event.cancelable) event.preventDefault();
            event.returnValue = '';
            return '';
        };
        window.addEventListener('beforeunload', unloadCallback);
        return () => window.removeEventListener('beforeunload', unloadCallback);
    }, []);

    useEffect(() => {
        loadingToggleAction(true);
        (async () => {
            let fullCountryList = await getCountryList();
            console.log(fullCountryList);

            setCountryList(fullCountryList);
            loadingToggleAction(false);
        })();
    }, [loadingToggleAction]);

    useEffect(() => {
        if (watchCountrySelection) {
            (async () => {
                setStateLoading(true);
                resetField('state');
                let fullStateList = await getStateList(watchCountrySelection);
                setStateLoading(false);
                setStateList(fullStateList);
                setValue('state', resSettings['state_id']);
            })();
        }
    }, [watchCountrySelection]);

    useEffect(() => {
        if (watchStateSelection && watchCountrySelection) {
            (async () => {
                setCityLoading(true);
                resetField('city');
                let fullCityList = await getCityList( 
                    watchCountrySelection,
                    watchStateSelection
                );
                setCityLoading(false);
                setCityList(fullCityList);
                setValue('city', resSettings['city_id']);
            })();
        }
    }, [watchCountrySelection, watchStateSelection]);
    const deleteImg1 = async () => {
        const res = await deleteRestaurantImg1();
        setImg1(null);
        setImageList(prevImageList =>
            prevImageList.filter(imageUrl => imageUrl !== resSettings['img1'])
        );
    };
    const deleteImg2 = async () => {
        const res = await deleteRestaurantImg2();
        setImg2(null);
        setImageList(prevImageList =>
            prevImageList.filter(imageUrl => imageUrl !== resSettings['img2'])
        );
    };
    const deleteImg3 = async () => {
        const res = await deleteRestaurantImg3();
        setImg3(null);
        setImageList(prevImageList =>
            prevImageList.filter(imageUrl => imageUrl !== resSettings['img3'])
        );
    };
    const deleteImg4 = async () => {
        const res = await deleteRestaurantImg4();
        setImg4(null);
        setImageList(prevImageList =>
            prevImageList.filter(imageUrl => imageUrl !== resSettings['img4'])
        );
    };

    useEffect(() => {
        getRestaurantSettings().then(res => setResSettings(res));

        register('lati', {required: 'Map location is required!'});
        register('longi', {required: true});
    }, [register]);

    useEffect(async () => {
        if (resSettings) {
            const fields = [
                'restaurant_name',
                'restaurant_name_arb',
                'bank_name',
                'acc_no',
                'lati',
                'longi',
                'country',
                'country_id',
                'email',
                'mobile',
                'rating',
                'place',
                'zip_code',
                'is_catering',
                'is_delivery',
                'is_dining',
                'is_takeaway',
            ];

            fields.forEach(field => setValue(field, resSettings[field]));

            setImageList([
                resSettings['img1'],
                resSettings['img2'],
                resSettings['img3'],
                resSettings['img4'],
            ]);
            setImg1(resSettings['img1']);
            setImg2(resSettings['img2']);
            setImg3(resSettings['img3']);
            setImg4(resSettings['img4']);
            setValue('city', resSettings['city_id']);
        }
    }, [setValue, resSettings]);

    const watchImage1 = watch('img1', null);

    const watchImage2 = watch('img2', null);
    const watchImage3 = watch('img3', null);
    const watchImage4 = watch('img4', null);

    const onSubmit = async data => {
        let sendData = [];
        if (data.img1?.length) sendData['img1'] = data.img1[0];
        if (data.img2?.length) sendData['img2'] = data.img2[0];
        if (data.img3?.length) sendData['img3'] = data.img3[0];
        if (data.img4?.length) sendData['img4'] = data.img4[0];

        if (
            !data.img1[0] &&
            !data.img2[0] &&
            !data.img3[0] &&
            !data.img4[0] &&
            !img1 &&
            !img2 &&
            !img3 &&
            !img4
        ) {
            setError('imageList', {
                type: 'manual',
                message: 'Please select at least one image',
            });
            return;
        }
        const toaster = toast.loading('Updating...');

        let postData = objectToFormData(data);
        let res = await updateRestaurantSettings(postData);
        if (res.status === 'success') {
            notify(toaster, 'success', 'Updated Restaurant Settings!', 2000);
        } else {
            notify(toaster, 'error', res.data.message, 2000);
        }
    };

    return (
        <>
            {resSettings ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='container mt-1'>
                        <h4 className='mb-4 text-center'>Restaurant Details</h4>
                        <div className='col-lg-10'>
                            <FormInput
                                label={'Restaurant Name'}
                                id={'val-rest-name'}
                                name={'restaurant_name'}
                                required={true}
                                ErrorMessage={ErrorMessage}
                                register={{
                                    ...register('restaurant_name', {
                                        required:
                                            'Restaurant name is required!',
                                    }),
                                }}
                                errors={errors}
                            />
                            <FormInput
                                label={'Restaurant Arabic Name'}
                                id={'val-rest-name-arb'}
                                name={'restaurant_name_arb'}
                                required={true}
                                ErrorMessage={ErrorMessage}
                                register={{
                                    ...register('restaurant_name_arb', {
                                        required:
                                            'Restaurant Arabic name is required!',
                                    }),
                                }}
                                errors={errors}
                            />
                            <FormInput
                                label={'Bank Name'}
                                id={'val-bank'}
                                name={'bank_name'}
                                required={true}
                                ErrorMessage={ErrorMessage}
                                register={{
                                    ...register('bank_name', {
                                        required: 'Bank name is required!',
                                    }),
                                }}
                                errors={errors}
                            />
                            <FormInput
                                label={'Bank Account Number'}
                                id={'val-bank-acct-no'}
                                name={'acc_no'}
                                required={true}
                                ErrorMessage={ErrorMessage}
                                register={{
                                    ...register('acc_no', {
                                        required:
                                            'Bank account number is required!',
                                        pattern: {
                                            value: /^(0|[0-9]\d*)(\.\d+)?$/,
                                            message:
                                                'Invalid account number provided!',
                                        },
                                    }),
                                }}
                                errors={errors}
                            />
                            <FormInput
                                label={'Contact Number'}
                                id={'val-rest-mobile'}
                                name={'mobile'}
                                required={true}
                                ErrorMessage={ErrorMessage}
                                register={{
                                    ...register('mobile', {
                                        required: 'Contact number is required!',
                                        pattern: {
                                            value: /^\+?\d{1,15}$/,
                                            message:
                                                'Please enter a valid phone number.',
                                        },
                                    }),
                                }}
                                errors={errors}
                            />

                            <FormInput
                                label={'Restaurant Email'}
                                type='text'
                                id={'val-rest-email'}
                                name={'email'}
                                ErrorMessage={ErrorMessage}
                                className='form-control'
                                register={{
                                    ...register('email', {
                                        required:
                                            'Restaurant Email is requied!',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Invalid email provided!',
                                        },
                                    }),
                                }}
                                errors={errors}
                            />
                        </div>
                        {/* <div className='col-10'>
                            <GMap
                                setValue={setValue}
                                ErrorMessage={ErrorMessage}
                                errors={errors}
                                names={['lati', 'longi']}
                            />
                        </div> */}
                        <div className='col-10'>
                            <FormSelectInput
                                label={'Country'}
                                id={'val-country'}
                                name={'country'}
                                required={true}
                                ErrorMessage={ErrorMessage}
                                Controller={Controller}
                                control={control}
                                errors={errors}
                                rules={{required: 'Country is required!'}}
                                options={
                                    countryList
                                        ? countryList
                                        : [
                                              {
                                                  value: null,
                                                  label: 'Loading...',
                                              },
                                          ]
                                }
                            />
                            {stateLoading ? (
                                <div style={{height: '100px'}}>
                                    <div className='sk-three-bounce'>
                                        <div className='sk-child sk-bounce1'></div>
                                        <div className='sk-child sk-bounce2'></div>
                                        <div className='sk-child sk-bounce3'></div>
                                    </div>
                                </div>
                            ) : (
                                <FormSelectInput
                                    label={'State'}
                                    id={'val-state'}
                                    name={'state'}
                                    required={true}
                                    ErrorMessage={ErrorMessage}
                                    Controller={Controller}
                                    control={control}
                                    errors={errors}
                                    rules={{required: 'State is required!'}}
                                    options={
                                        stateList && watchCountrySelection
                                            ? stateList
                                            : [
                                                  {
                                                      value: null,
                                                      label: 'Select Country...',
                                                  },
                                              ]
                                    }
                                />
                            )}
                            {cityLoading ? (
                                <div style={{height: '100px'}}>
                                    <div className='sk-three-bounce'>
                                        <div className='sk-child sk-bounce1'></div>
                                        <div className='sk-child sk-bounce2'></div>
                                        <div className='sk-child sk-bounce3'></div>
                                    </div>
                                </div>
                            ) : (
                                <FormSelectInput
                                    label={'City'}
                                    id={'val-city'}
                                    name={'city'}
                                    required={true}
                                    ErrorMessage={ErrorMessage}
                                    Controller={Controller}
                                    control={control}
                                    errors={errors}
                                    rules={{required: 'City is required!'}}
                                    options={
                                        cityList &&
                                        watchStateSelection &&
                                        watchCountrySelection
                                            ? cityList
                                            : [
                                                  {
                                                      value: null,
                                                      label: 'Select State...',
                                                  },
                                              ]
                                    }
                                />
                            )}
                            <FormInput
                                label={'Place'}
                                id={'val-place'}
                                name={'place'}
                                required={true}
                                ErrorMessage={ErrorMessage}
                                register={{
                                    ...register('place', {
                                        required: 'Place is required!',
                                    }),
                                }}
                                errors={errors}
                            />
                            <FormInput
                                label={'Postal Code'}
                                id={'val-zip-code'}
                                name={'zip_code'}
                                required={true}
                                ErrorMessage={ErrorMessage}
                                register={{
                                    ...register('zip_code', {
                                        required: 'Postal Code is required!',
                                        pattern: {
                                            value: /^([0-9]\d*)$/,
                                            message:
                                                'Invalid postal code provided!',
                                        },
                                    }),
                                }}
                                errors={errors}
                            />
                        </div>
                        <h4 className='mb-4 text-center mt-3'>
                            Restaurant Service Provided
                        </h4>
                        <div className='col-12 '>
                            <div className='custom-control custom-checkbox mb-3 checkbox-info'>
                                <input
                                    type='checkbox'
                                    defaultChecked
                                    className='custom-control-input'
                                    {...register('is_catering')}
                                    id='val-is-cater'
                                />
                                <label
                                    className='custom-control-label'
                                    htmlFor='val-is-cater'
                                >
                                    Provides Catering Service
                                </label>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='custom-control custom-checkbox mb-3 checkbox-info'>
                                <input
                                    type='checkbox'
                                    defaultChecked
                                    className='custom-control-input'
                                    {...register('is_dining')}
                                    id='val-is-dining'
                                />
                                <label
                                    className='custom-control-label'
                                    htmlFor='val-is-dining'
                                >
                                    Provides Dine-in Option
                                </label>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='custom-control custom-checkbox mb-3 checkbox-info'>
                                <input
                                    type='checkbox'
                                    defaultChecked
                                    className='custom-control-input'
                                    {...register('is_takeaway')}
                                    id='val-is-takeaway'
                                />
                                <label
                                    className='custom-control-label'
                                    htmlFor='val-is-takeaway'
                                >
                                    Provides Take Away Service
                                </label>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='custom-control custom-checkbox mb-3 checkbox-info'>
                                <input
                                    type='checkbox'
                                    defaultChecked
                                    className='custom-control-input'
                                    {...register('is_delivery')}
                                    id='val-is-delivery'
                                />
                                <label
                                    className='custom-control-label'
                                    htmlFor='val-is-delivery'
                                >
                                    Provides Delivery Service
                                </label>
                            </div>
                        </div>
                        <h4 className='mb-4 text-center mt-3 justify-content-center'>
                            Restaurant Images
                        </h4>
                        <div className='col-10'>
                            <div
                                className='  justify-content-center'
                                style={{
                                    // display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    // height: '100vh',
                                }}
                            >
                                <div className='input-group  justify-content-center'>
                                    <div className='custom-file mb-1'>
                                        <input
                                            type='file'
                                            className='custom-file-input'
                                            {...register('img1')}
                                            // {...register('img1', {
                                            //     required:
                                            //         'Atleast one image is required!',
                                            // })}
                                            id='val-img1'
                                        />

                                        <label className='custom-file-label'>
                                            {imageList &&
                                            img1 &&
                                            watchImage1?.length == 0
                                                ? img1.slice(0, 30) + '...]'
                                                : watchImage1 &&
                                                  watchImage1.length > 0
                                                ? '[Uploaded:' +
                                                  watchImage1[0]?.name.slice(
                                                      0,
                                                      20
                                                  ) +
                                                  '...]'
                                                : 'Image 1'}
                                        </label>
                                    </div>
                                    {watchImage1 && watchImage1[0] ? (
                                        <div
                                            className='ml-2 mt-1 bootstrap-badge'
                                            id='cross-1'
                                            type='button'
                                            onClick={() => {
                                                resetField('img1');
                                            }}
                                        >
                                            <span className='badge badge-primary light'>
                                                <i className='fa fa-close'></i>
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    {imageList &&
                                    img1 &&
                                    watchImage1 &&
                                    !watchImage1[0] ? (
                                        <div
                                            className='ml-2 mt-1 bootstrap-badge'
                                            type='button'
                                            onClick={() => deleteImg1()}
                                        >
                                            <span className='badge badge-primary light'>
                                                <i className='fa fa-close'></i>
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {/* <ErrorMessage
                                    errors={errors}
                                    name='img1'
                                    render={({message}) => (
                                        <div className='text-danger fs-12'>
                                            {message}
                                        </div>
                                    )}
                                /> */}
                                <div className='input-group mt-4 mb-4 float-left'>
                                    <div className='custom-file'>
                                        <input
                                            type='file'
                                            className='custom-file-input'
                                            {...register('img2')}
                                            id='val-img2'
                                        />
                                        <label className='custom-file-label'>
                                            {imageList &&
                                            img2 &&
                                            watchImage2?.length == 0
                                                ? img2.slice(0, 30) + '...]'
                                                : watchImage2 &&
                                                  watchImage2.length > 0
                                                ? '[Uploaded:' +
                                                  watchImage2[0].name.slice(
                                                      0,
                                                      20
                                                  ) +
                                                  '...]'
                                                : 'Image 2'}
                                        </label>
                                    </div>
                                    {watchImage2 && watchImage2[0] ? (
                                        <div
                                            className='ml-2 mt-1 bootstrap-badge'
                                            id='cross-1'
                                            type='button'
                                            onClick={() => {
                                                resetField('img2');
                                            }}
                                        >
                                            <span className='badge badge-primary light'>
                                                <i className='fa fa-close'></i>
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {imageList &&
                                    img2 &&
                                    watchImage2 &&
                                    !watchImage2[0] ? (
                                        <div
                                            className='ml-2 mt-1 bootstrap-badge'
                                            type='button'
                                            onClick={() => deleteImg2()}
                                        >
                                            <span className='badge badge-primary light'>
                                                <i className='fa fa-close'></i>
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className='input-group mb-4'>
                                    <div className='custom-file'>
                                        <input
                                            type='file'
                                            className='custom-file-input'
                                            {...register('img3')}
                                            id='val-img3'
                                        />
                                        <label className='custom-file-label'>
                                            {imageList &&
                                            img3 &&
                                            watchImage3?.length == 0
                                                ? img3.slice(0, 30) + '...]'
                                                : watchImage3 &&
                                                  watchImage3?.length > 0
                                                ? '[Uploaded:' +
                                                  watchImage3[0].name.slice(
                                                      0,
                                                      20
                                                  ) +
                                                  '...]'
                                                : 'Image 3'}
                                        </label>
                                    </div>
                                    {watchImage3 && watchImage3[0] ? (
                                        <div
                                            className='ml-2 mt-1 bootstrap-badge'
                                            id='cross-1'
                                            type='button'
                                            onClick={() => {
                                                resetField('img3');
                                            }}
                                        >
                                            <span className='badge badge-primary light'>
                                                <i className='fa fa-close'></i>
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {imageList &&
                                    img3 &&
                                    watchImage3 &&
                                    !watchImage3[0] ? (
                                        <div
                                            className='ml-2 mt-1 bootstrap-badge'
                                            type='button'
                                            onClick={() => deleteImg3()}
                                        >
                                            <span className='badge badge-primary light'>
                                                <i className='fa fa-close'></i>
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className='input-group mb-4'>
                                    <div className='custom-file'>
                                        <input
                                            type='file'
                                            className='custom-file-input'
                                            {...register('img4')}
                                            id='val-img4'
                                        />
                                        <label className='custom-file-label'>
                                            {imageList &&
                                            img4 &&
                                            watchImage4?.length == 0
                                                ? img4.slice(0, 30) + '...]'
                                                : watchImage4 &&
                                                  watchImage4.length > 0
                                                ? '[Uploaded:' +
                                                  watchImage4[0].name.slice(
                                                      0,
                                                      20
                                                  ) +
                                                  '...]'
                                                : 'Image 4'}
                                        </label>
                                    </div>
                                    {watchImage4 && watchImage4[0] ? (
                                        <div
                                            className='ml-2 mt-1 bootstrap-badge'
                                            id='cross-1'
                                            type='button'
                                            onClick={() => {
                                                resetField('img4');
                                            }}
                                        >
                                            <span className='badge badge-primary light'>
                                                <i className='fa fa-close'></i>
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {imageList &&
                                    img4 &&
                                    watchImage4 &&
                                    !watchImage4[0] ? (
                                        <div
                                            className='ml-2 mt-1 bootstrap-badge'
                                            type='button'
                                            onClick={() => deleteImg4()}
                                        >
                                            <span className='badge badge-primary light'>
                                                <i className='fa fa-close'></i>
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {errors.imageList && (
                                    <p style={{color: 'red'}}>
                                        {errors.imageList.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <button className='btn btn-primary btn-block btn-sm mb-2'>
                            Update Restaurant Settings
                        </button>
                    </div>
                </form>
            ) : (
                <div id='preloader-inner'>
                    <div className='sk-three-bounce-inner'>
                        <div className='sk-child sk-bounce1'></div>
                        <div className='sk-child sk-bounce2'></div>
                        <div className='sk-child sk-bounce3'></div>
                        <div id='preloader-inner-bg'></div>
                    </div>
                </div>
            )}
        </>
    );
};

export default RestaurantSettings;
