import React, {useState, useCallback} from 'react';
import {getAllTakeaways} from '../../../services/OrderService';
import TakeawayHistoryList from '../../components/OrderHistory/TakeawayHistoryList';

const TakeawayHistory = () => {
    const [takeawayList, setTakeawayList] = useState();

    const [page, setPage] = useState(1);
    const [totalPages, setTotalpages] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const getTakeaways = useCallback((page, fromDate, toDate) => {
        getAllTakeaways(page, fromDate, toDate).then(res => {
            setTotalpages(res.total_pages, 'total pages');

            setTakeawayList(res.data);
        });
    }, []);
    return (
        <TakeawayHistoryList
            takeawayList={takeawayList}
            getTakeaways={getTakeaways}
            // pagination={pagination}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            // sort={sort}
            page={page}
            totalPages={totalPages}
        />
    );
};

export default TakeawayHistory;
