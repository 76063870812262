import React, {useState, useCallback} from 'react';
import {getAllUpcomingReservations} from '../../../services/OrderService';
import ReservationList from '../../components/Orders/ReservationList';

const Reservations = () => {
    const [reservationList, setReservationList] = useState([]);

    const [isloading, setIsloading] = useState(true);

    const getUpcomingReservations = useCallback(() => {
        getAllUpcomingReservations().then(res => {
            setIsloading(false);

            setReservationList(res);
        });
    }, []);
    return (
        <ReservationList
            reservationList={reservationList}
            setReservationList={setReservationList}
            getUpcomingReservations={getUpcomingReservations}
            isloading={isloading}
        />
    );
};

export default Reservations;
