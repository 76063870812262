import React, {useEffect, useMemo} from 'react';
import {Card, Table} from 'react-bootstrap';
import {FilteringTable} from '../../components/OrderHistory/FilterTable';
import {Link} from 'react-router-dom';
import {ColumnFilter} from '../../components/OrderHistory/ColumnFilter';
const CustomerList = props => {
    const {customerList, page, totalPages, getCustomers} = props;

    useEffect(() => {
        getCustomers(page);
    }, [getCustomers]);

    const handlePaginationClick = async pageIndex => {
        getCustomers(pageIndex + 1);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'USER NAME',
                Footer: 'USER NAME',
                accessor: 'username',
                Filter: ColumnFilter,
                //disableFilters: true,
            },
            {
                Header: 'NAME',
                Footer: 'NAME',
                accessor: 'full_name',
                Filter: ColumnFilter,
                //disableFilters: true,
            },
            {
                Header: 'EMAIL',
                Footer: 'EMAIL',
                accessor: 'email',

                Filter: ColumnFilter,
            },
            {
                Header: 'MOBILE',
                Footer: 'MOBILE',
                accessor: 'mobile',
                Filter: ColumnFilter,
            },

            {
                Header: 'View Detail',
                // Footer: '',
                accessor: 'view',
                Cell: value => {
                    let {username} = value.row.original;
                    let linkUrl = `/customer-detail/${username}`;
                    return (
                        <Link to={linkUrl}>
                            <button
                                className='btn btn-primary btn-sm 
                            '
                            >
                                View
                            </button>
                        </Link>
                        //{' '}
                    );
                },
                Filter: ColumnFilter,
                disableFilters: true,
            },
        ],
        []
    );

    return (
        <>
            {customerList && customerList.length > 0 ? (
                <>
                    <Card>
                        <FilteringTable
                            columns={columns}
                            data={customerList}
                            total_pages={totalPages}
                            paginationClick={handlePaginationClick}
                        />
                    </Card>
                </>
            ) : (
                <Table>
                    <thead>
                        <tr>
                            <th>
                                <strong>NAME</strong>
                            </th>
                            <th>
                                <strong>EMAIL</strong>
                            </th>
                            <th>
                                <strong>MOBILE</strong>
                            </th>

                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div id='preloader-inner'>
                                    <div className='sk-three-bounce-inner'>
                                        <div className='sk-child sk-bounce1'></div>
                                        <div className='sk-child sk-bounce2'></div>
                                        <div className='sk-child sk-bounce3'></div>
                                        <div id='preloader-inner-bg'></div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            )}
        </>
    );
};

export default CustomerList;
