const FormInput = props => {
    const {label, required, ErrorMessage, register, name, id, errors} = props;
    return (
        <div className='form-group row'>
            <label className='col-lg-4 col-form-label' htmlFor={id}>
                {label}
                {required && <span className='text-danger'>*</span>}
            </label>
            <div className='col-lg-8'>
                <input
                    type='text'
                    id={id}
                    className='form-control'
                    {...register}
                />
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({message}) => (
                        <div className='text-danger fs-12'>{message}</div>
                    )}
                />
            </div>
        </div>
    );
};

export default FormInput;
