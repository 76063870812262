import React, {useEffect, useState} from 'react';
// import usePushNotification from '../../../services/NotificationService';
// import {initializeApp} from 'firebase/app';
import {Link} from 'react-router-dom';
import Customtoast from './Customtoast';
import Loader from '../../components/Orders/Loader';
// import firebaseConfig from '../../../firebase';
/// Scroll
import PerfectScrollbar from 'react-perfect-scrollbar';
// Redux
import {connect, useDispatch} from 'react-redux';
// firebase
import {onMessageListener} from '../../../firebase';
import {Button, Row, Col, Toast} from 'react-bootstrap';
import {toast} from 'react-toastify';

/// Image
import profile from '../../../images/profile/profile.jpg';
import avatar from '../../../images/avatar/1.jpg';
import {Dropdown} from 'react-bootstrap';
import Logout from './Logout';
import {getLoggedInUserData} from '../../../services/UserService';
import {getAllNotifications} from '../../../services/NotificationService';
import NotificationButton from '../../components/Notification/NotificationButton';

const Header = props => {
    const {userProfile} = props;
    const dispatch = useDispatch();
    useEffect(() => {
        // console.log(userProfile);
    }, [userProfile]);
    // useEffect(() => {
    //     console.log('userProfile.dispatch');
    //     getLoggedInUserData(dispatch);
    // }, [dispatch, notification]);

    /// firebase
    const [show, setShow] = useState(false);
    const [notificationList, setNotificationList] = useState();
    const [unseenNot, setUnseennot] = useState([]);
    const [notification, setNotification] = useState({title: '', body: ''});
    const [isloading, setIsloading] = useState(true);
    useEffect(() => {
        console.log('userProfile.dispatch');
        getLoggedInUserData(dispatch);
    }, [dispatch, notification, unseenNot]);

    onMessageListener()
        .then(async payload => {
            // setShow(true);
            console.log(payload, 'notification');
            setNotification({
                title: payload.notification.title,
                body: payload.notification.body,
            });
            const {title, body} = payload.notification;
            const {type, bookid} = payload.data;

            toast(
                <Customtoast
                    title={title}
                    body={body}
                    type={type}
                    bookid={bookid}
                    autoClose={false}
                />
            );
            // console.log(payload);
            // const res = await getAllNotifications();
            // setNotificationList(res.data);
        })
        .catch(err => console.log('failed: ', err));

    const handleApi = async () => {
        const res = await getAllNotifications();
        setIsloading(false);
        console.log(res, 'notificationzzzzzzzzz');
        setNotificationList(res.data);
        const filteredData = res.data.filter(item => item.is_seen === false);
        console.log(filteredData, 'list of filtered notification');
        setUnseennot(filteredData);
        console.log(unseenNot, 'UNSEEN');
    };

    //////////////////////////////////////////

    var path = window.location.pathname.split('/');

    var name = path[path.length - 1].split('-');
    var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
    return (
        <div className='header'>
            <div className='header-content'>
                <nav className='navbar navbar-expand'>
                    <div className='collapse navbar-collapse justify-content-between'>
                        <div className='header-left'>
                            <div
                                className='dashboard_bar'
                                style={{textTransform: 'capitalize'}}
                            >
                                {filterName[0] === ''
                                    ? 'Dashboard'
                                    : path[1] === 'delivery-detail'
                                    ? 'Delivery Order Details'
                                    : path[1] === 'reservation-detail'
                                    ? 'Reservation Order Details'
                                    : path[1] === 'catering-detail'
                                    ? 'Catering Order Details'
                                    : path[1] === 'take-away-detail'
                                    ? 'TakeAway Order Details'
                                    : path[1] === 'customer-detail'
                                    ? ' Order Details'
                                    : filterName.join(' ')}
                                {/* {filterName[0] === ''
                                    ? 'Dashboard'
                                    : filterName.join(' ')} */}
                                {filterName[0] === '' ? (
                                    <span>Welcome to Skippy Admin!</span>
                                ) : filterName[0] === 'reservations' ? (
                                    <span>Here is your reservation list</span>
                                ) : filterName[0] === 'users' ? (
                                    <span>Here is your admin users list</span>
                                ) : filterName[0] === 'settings' ? (
                                    <span>
                                        Here are your restaurant settings
                                    </span>
                                ) : filterName[0] === 'food' &&
                                  filterName[1] === 'items' ? (
                                    <span>
                                        Here are your list of food items
                                    </span>
                                ) : filterName[0] === 'customers' ? (
                                    <span>Here is your customer list data</span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <ul className='navbar-nav header-right'>
                            <Dropdown
                                className='nav-item dropdown notification_dropdown'
                                as='li'
                                onClick={handleApi}
                            >
                                <Dropdown.Toggle
                                    as='a'
                                    variant=''
                                    className='nav-link  ai-icon i-false'
                                    href='#'
                                    role='button'
                                    data-toggle='dropdown'
                                >
                                    <svg
                                        width={28}
                                        height={28}
                                        viewBox='0 0 28 28'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path
                                            d='M21.75 14.8385V12.0463C21.7471 9.88552 20.9385 7.80353 19.4821 6.20735C18.0258 4.61116 16.0264 3.61555 13.875 3.41516V1.625C13.875 1.39294 13.7828 1.17038 13.6187 1.00628C13.4546 0.842187 13.2321 0.75 13 0.75C12.7679 0.75 12.5454 0.842187 12.3813 1.00628C12.2172 1.17038 12.125 1.39294 12.125 1.625V3.41534C9.97361 3.61572 7.97429 4.61131 6.51794 6.20746C5.06159 7.80361 4.25291 9.88555 4.25 12.0463V14.8383C3.26257 15.0412 2.37529 15.5784 1.73774 16.3593C1.10019 17.1401 0.751339 18.1169 0.75 19.125C0.750764 19.821 1.02757 20.4882 1.51969 20.9803C2.01181 21.4724 2.67904 21.7492 3.375 21.75H8.71346C8.91521 22.738 9.45205 23.6259 10.2331 24.2636C11.0142 24.9013 11.9916 25.2497 13 25.2497C14.0084 25.2497 14.9858 24.9013 15.7669 24.2636C16.548 23.6259 17.0848 22.738 17.2865 21.75H22.625C23.321 21.7492 23.9882 21.4724 24.4803 20.9803C24.9724 20.4882 25.2492 19.821 25.25 19.125C25.2486 18.117 24.8998 17.1402 24.2622 16.3594C23.6247 15.5786 22.7374 15.0414 21.75 14.8385ZM6 12.0463C6.00232 10.2113 6.73226 8.45223 8.02974 7.15474C9.32723 5.85726 11.0863 5.12732 12.9212 5.125H13.0788C14.9137 5.12732 16.6728 5.85726 17.9703 7.15474C19.2677 8.45223 19.9977 10.2113 20 12.0463V14.75H6V12.0463ZM13 23.5C12.4589 23.4983 11.9316 23.3292 11.4905 23.0159C11.0493 22.7026 10.716 22.2604 10.5363 21.75H15.4637C15.284 22.2604 14.9507 22.7026 14.5095 23.0159C14.0684 23.3292 13.5411 23.4983 13 23.5ZM22.625 20H3.375C3.14298 19.9999 2.9205 19.9076 2.75644 19.7436C2.59237 19.5795 2.50014 19.357 2.5 19.125C2.50076 18.429 2.77757 17.7618 3.26969 17.2697C3.76181 16.7776 4.42904 16.5008 5.125 16.5H20.875C21.571 16.5008 22.2382 16.7776 22.7303 17.2697C23.2224 17.7618 23.4992 18.429 23.5 19.125C23.4999 19.357 23.4076 19.5795 23.2436 19.7436C23.0795 19.9076 22.857 19.9999 22.625 20Z'
                                            fill='#4C8147'
                                        />
                                    </svg>
                                    <span className='badge light text-white bg-primary rounded-circle'>
                                        {userProfile.notif_cnt}
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu align='right' className='mt-2'>
                                    {isloading && <Loader />}
                                    {!isloading && unseenNot.length > 0 ? (
                                        <PerfectScrollbar className='widget-media dz-scroll p-3 height380 ps'>
                                            <ul className='timeline'>
                                                {unseenNot.length > 0
                                                    ? unseenNot.map(
                                                          (each, i) => (
                                                              <li>
                                                                  <div
                                                                      key={i}
                                                                      className='timeline-panel'
                                                                  >
                                                                      <div className='media-body'>
                                                                          <h6 className='mb-1'>
                                                                              {
                                                                                  each.title
                                                                              }
                                                                          </h6>
                                                                          <span className='mb-1'>
                                                                              <small>
                                                                                  {' '}
                                                                                  {
                                                                                      each.message
                                                                                  }
                                                                              </small>
                                                                              <NotificationButton
                                                                                  type={
                                                                                      each
                                                                                          .endpoint
                                                                                          .type
                                                                                  }
                                                                                  bookid={
                                                                                      each
                                                                                          .endpoint
                                                                                          .bookid
                                                                                  }
                                                                              />
                                                                          </span>
                                                                          <small className='d-block'>
                                                                              {new Date(
                                                                                  each.timestamp
                                                                              ).toLocaleString()}
                                                                          </small>
                                                                      </div>
                                                                  </div>
                                                              </li>
                                                          )
                                                      )
                                                    : ''}
                                            </ul>
                                            <div
                                                className='ps__rail-x'
                                                style={{left: 0, bottom: 0}}
                                            >
                                                <div
                                                    className='ps__thumb-x'
                                                    tabIndex={0}
                                                    style={{left: 0, width: 0}}
                                                />
                                            </div>
                                            <div
                                                className='ps__rail-y'
                                                style={{top: 0, right: 0}}
                                            >
                                                <div
                                                    className='ps__thumb-y'
                                                    tabIndex={0}
                                                    style={{top: 0, height: 0}}
                                                />
                                            </div>
                                        </PerfectScrollbar>
                                    ) : (
                                        <h6 className='text-center'>
                                            No Unseen notification
                                        </h6>
                                    )}
                                    <Link
                                        className='all-notification'
                                        to='/notifications'
                                    >
                                        See all notifications{' '}
                                        <i className='ti-arrow-right' />
                                    </Link>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown
                                className='nav-item dropdown header-profile'
                                as='li'
                            >
                                <Dropdown.Toggle
                                    as='a'
                                    to='#'
                                    variant=''
                                    className='nav-link  i-false p-0c-pointer pointr'
                                >
                                    {userProfile.img ? (
                                        <img
                                            src={
                                                process.env
                                                    .REACT_APP_API_RESOURCE_URL +
                                                userProfile.img
                                            }
                                            width={20}
                                            alt='profile'
                                        />
                                    ) : (
                                        <img
                                            src={profile}
                                            width={20}
                                            alt='profile'
                                        />
                                    )}

                                    <div className='header-info'>
                                        <span className='text-black'>
                                            <strong>
                                                {userProfile.full_name}
                                            </strong>
                                        </span>
                                        <p className='fs-12 mb-0'>
                                            {userProfile.designation}
                                        </p>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align='right' className='mt-2'>
                                    <Link
                                        to='/app-profile'
                                        className='dropdown-item ai-icon'
                                    >
                                        <svg
                                            id='icon-user1'
                                            xmlns='http://www.w3.org/2000/svg'
                                            className='text-primary'
                                            width={18}
                                            height={18}
                                            viewBox='0 0 24 24'
                                            fill='none'
                                            stroke='currentColor'
                                            strokeWidth={2}
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        >
                                            <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2' />
                                            <circle cx={12} cy={7} r={4} />
                                        </svg>
                                        <span className='ml-2'>Profile </span>
                                    </Link>

                                    <Logout />
                                </Dropdown.Menu>
                            </Dropdown>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        userProfile: state.user,
    };
};

export default connect(mapStateToProps)(Header);
