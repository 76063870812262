import React, {useEffect, useState, useMemo} from 'react';
import {Card, Table} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {getCustomerDetail} from '../../../services/CustomerService';
import {getCustomerOrder} from '../../../services/CustomerService';
import ViewButton from './ViewButton';
import {FilteringTable} from '../../components/OrderHistory/FilterTable';

import {ColumnFilter} from '../../components/OrderHistory/ColumnFilter';
const CustomerDetail = () => {
    let {username} = useParams();
    const [detail, setDetail] = useState('');
    const [order, setOrder] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalpages] = useState();

    useEffect(async () => {
        const res = await getCustomerDetail(username);

        setDetail(res);
    }, [username]);
    useEffect(async () => {
        const res = await getCustomerOrder(username, page);

        setOrder(res.data);
        setTotalpages(res.total_pages);
    }, []);

    const handlePaginationClick = async pageIndex => {
        const res = await getCustomerOrder(username, pageIndex + 1);

        setOrder(res.data);
    };
    const columns = useMemo(
        () => [
            {
                Header: 'BOOK ID',
                Footer: 'BOOK ID',
                accessor: 'bookid',
                Filter: ColumnFilter,
                //disableFilters: true,
            },
            {
                Header: 'DATE',
                Footer: 'DATE',
                accessor: 'date',

                Filter: ColumnFilter,
            },
            {
                Header: 'ORDER TYPE',
                Footer: 'ORDER TYPE',
                accessor: 'ord_type',
                Filter: ColumnFilter,
            },
            {
                Header: 'STATUS',
                Footer: 'STATUS',
                accessor: 'status',
                Filter: ColumnFilter,
            },

            {
                Header: 'View Detail',
                // Footer: '',
                accessor: 'view',
                Cell: value => {
                    let {bookid, ord_type} = value.row.original;

                    return <ViewButton ord_type={ord_type} ord_id={bookid} />;
                },
                Filter: ColumnFilter,
                disableFilters: true,
            },
        ],
        []
    );

    // if (detail) {
    return (
        <>
            <div className='row'>
                {detail ? (
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='profile-photo'>
                                    <img
                                        src={
                                            process.env
                                                .REACT_APP_API_RESOURCE_URL +
                                            detail.img
                                        }
                                        className='img-fluid rounded-circle'
                                        alt='profile'
                                        style={{
                                            height: '50px',
                                            width: '50px',
                                        }}
                                    />
                                    <strong className='px-4 py-4'>
                                        {' '}
                                        {detail.full_name}{' '}
                                    </strong>
                                </div>
                                <strong> {detail.email}</strong>
                                <span className='float-right'>
                                    <strong>{detail.mobile}</strong>
                                </span>{' '}
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-lg-5 col-sm-5'>
                                        <table className=' table-clear'>
                                            <tbody>
                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>Place</strong>
                                                    </td>
                                                    <td className='right'>
                                                        {detail.place}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>City</strong>
                                                    </td>
                                                    <td className='right'>
                                                        {detail.city}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left px-5 py-2 '>
                                                        <strong>
                                                            State{detail.state}
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {detail.state}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>{' '}
                                    </div>
                                    <div className='col-lg-4 col-sm-5 ml-auto '>
                                        <table className=' table-clear'>
                                            <tbody>
                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>
                                                            Num of Orders
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {order.length + 1}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>{' '}
                                    </div>
                                </div>

                                <div className='table-responsive mt-5'>
                                    {order ? (
                                        <Card>
                                            <FilteringTable
                                                columns={columns}
                                                data={order}
                                                total_pages={totalPages}
                                                paginationClick={
                                                    handlePaginationClick
                                                }
                                            />
                                        </Card>
                                    ) : (
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <strong>
                                                            RES. NO.
                                                        </strong>
                                                    </th>
                                                    <th>
                                                        <strong>DATE</strong>
                                                    </th>
                                                    <th>
                                                        <strong>TIME</strong>
                                                    </th>
                                                    <th>
                                                        <strong>
                                                            CUSTOMER
                                                        </strong>
                                                    </th>

                                                    <th>
                                                        <strong>STATUS</strong>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div id='preloader-inner'>
                                                            <div className='sk-three-bounce-inner'>
                                                                <div className='sk-child sk-bounce1'></div>
                                                                <div className='sk-child sk-bounce2'></div>
                                                                <div className='sk-child sk-bounce3'></div>
                                                                <div id='preloader-inner-bg'></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div id='preloader-inner'>
                        <div className='sk-three-bounce-inner'>
                            <div className='sk-child sk-bounce1'></div>
                            <div className='sk-child sk-bounce2'></div>
                            <div className='sk-child sk-bounce3'></div>
                            <div id='preloader-inner-bg'></div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default CustomerDetail;
