import React from 'react';
import {Router,Link} from 'react-router-dom';

const Customtoast = ({type, bookid, title, body}) => {
    console.log(title, body, type, bookid, 'custom toaster');
    let linkUrl;
    if (type === 'Reservation') {
        linkUrl = `/reservation-detail/${bookid}`;
    } else if (type === 'Skipque') {
        linkUrl = `/delivery-detail/${bookid}`;
        // } else if (type === 'Takeaway') {
        //     linkUrl = `/take-away-detail/{bookid}`;
    } else if (type === 'Catering') {
        linkUrl = `/catering-detail/${bookid}`;
    } else {
        linkUrl = `/notifications`;
    }
    return (
        <a href={linkUrl} >
        <div>
            
            `Notification received:{title}`
            <div className='float-right'>
            
                
                    <small className='text-primary'>show</small>
                
            </div>
        
        </div>
        </a>
    );
};
export default Customtoast;
