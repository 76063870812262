import axiosPrivateInstance from './AxiosPrivateInstance';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {apiCallError} from '../store/actions/ErrorActions';
// import store from '../store/store';

export const getAllTable = async () => {
    try {
        const res = await axiosPrivateInstance.get('/restaurant-table-list/');
        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const addNewTable = async data => {
    try {
        const res = await axiosPrivateInstance.post(
            `restaurant-table-list/`,
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getTableDetailsById = async id => {
    try {
        const res = await axiosPrivateInstance.get(
            `/restaurant-table-detail/?pk=${id}`
        );
        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const deleteTableById = async id => {
    try {
        const res = await axiosPrivateInstance.delete(
            `/restaurant-table-detail/?pk=${id}`
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const updateTableDetails = async (id, data) => {
    try {
        const res = await axiosPrivateInstance.patch(
            `restaurant-table-detail/?pk=${id}`,
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const getAllCategoryList = async () => {
    try {
        const res = await axiosPrivateInstance.get(
            'restaurant-table-category-list/'
        );
        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const addNewCategory = async data => {
    try {
        const res = await axiosPrivateInstance.post(
            `restaurant-table-category-list/`,
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getCategoryDetailsById = async id => {
    try {
        console.log(id, 'category');
        const res = await axiosPrivateInstance.get(
            `restaurant-tablecategory-detail/?id=${id}`
        );
        console.log(res.data.data[0], 'YYYYY');

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const updateCategoryDetails = async (id, data) => {
    console.log(id, data);
    try {
        const res = await axiosPrivateInstance.patch(
            `restaurant-tablecategory-detail/?id=${id}`,
            data
        );

        console.log(res.data);
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const deleteTablecategoryById = async id => {
    try {
        const res = await axiosPrivateInstance.delete(
            `restaurant-tablecategory-detail/?id=${id}`
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const getAllOccasionList = async () => {
    try {
        const res = await axiosPrivateInstance.get(
            'restaurant-occassion-list/'
        );

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const getAllCuisineList = async () => {
    try {
        const res = await axiosPrivateInstance.get('restaurant-cuisine/');

        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const updateReserSettings = async data => {
    try {
        const res = await axiosPrivateInstance.patch(
            'restaurant-reservation-config/',
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getReservationDetails = async () => {
    try {
        const res = await axiosPrivateInstance.get(
            'restaurant-reservation-config/'
        );

        return res.data.data[0];
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const getDeliverySettings = async () => {
    try {
        const res = await axiosPrivateInstance.get(
            'restaurant-delivery-config/'
        );
        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const updateDeliverySettings = async data => {
    try {
        const res = await axiosPrivateInstance.patch(
            'restaurant-delivery-config/',
            data
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getTakeawaySettings = async () => {
    try {
        const res = await axiosPrivateInstance.get(
            'restaurant-takeaway-config/'
        );

        return res.data.data[0];
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const updateTakeawaySettings = async data => {
    try {
        const res = await axiosPrivateInstance.patch(
            'restaurant-takeaway-config/',
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getCateringSettings = async () => {
    try {
        const res = await axiosPrivateInstance.get(
            'restaurant-catering-config/'
        );

        return res.data.data[0];
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const updateCateringSettings = async data => {
    try {
        const res = await axiosPrivateInstance.patch(
            'restaurant-catering-config/',
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getRestaurantSettings = async () => {
    try {
        const res = await axiosPrivateInstance.get(
            'restaurant-settings-config/'
        );

        return res.data.data[0];
    } catch (error) {
        console.log('ERROR', error);
        handleApiError(error);
    }
};
export const updateRestaurantSettings = async data => {
    try {
        const res = await axiosPrivateInstance.patch(
            'restaurant-settings-config/',
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const deleteRestaurantImg1 = async () => {
    try {
        const res = await axiosPrivateInstance.delete(
            `restaurant-settings-config/?img1_status=false`
        );
        console.log(res.data);
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const deleteRestaurantImg2 = async () => {
    try {
        const res = await axiosPrivateInstance.delete(
            `restaurant-settings-config/?img2_status=false`
        );
        console.log(res.data);
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const deleteRestaurantImg3 = async () => {
    try {
        const res = await axiosPrivateInstance.delete(
            `restaurant-settings-config/?img3_status=false`
        );
        console.log(res.data);
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const deleteRestaurantImg4 = async () => {
    try {
        const res = await axiosPrivateInstance.delete(
            `restaurant-settings-config/?img4_status=false`
        );
        console.log(res.data);
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const updateReservationSlot = async (id, status) => {
    try {
        const res = await axiosPrivateInstance.patch(
            `edit-reservation-slote/?sloteid=${id}&&status=${status}`
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const updateDeliverySlot = async (id, data) => {
    try {
        const res = await axiosPrivateInstance.patch(
            `edit-reservation-slote/?sloteid=${id}`,
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const updateTakeawaySlot = async (id, data) => {
    try {
        const res = await axiosPrivateInstance.patch(
            `edit-delivery-pickup-slote/?sloteid=${id}`,
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const updateProfileDetails = async data => {
    try {
        const res = await axiosPrivateInstance.patch(
            `restaurant-user-profile/`,
            data
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const testAPI = () => async dispatch => {
    try {
        const res = await axiosPrivateInstance.get(`restaurant-user-profile/`);

        return res.data;
    } catch (error) {
        console.log(error);
        toast.error(`An error occurred during the API call: ${error.message}`);
        dispatch(apiCallError('Failed to fetch data'));
    }
};
export const handleApiError = error => {
    toast.error(`Oops! Something went wrong: ${error.message}`);
};
