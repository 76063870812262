import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {Provider} from 'react-redux';
import {store} from './store/store';
import reportWebVitals from './reportWebVitals';
import SimpleReactLightbox from 'simple-react-lightbox';
import ThemeContext from './context/ThemeContext';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const history = createBrowserHistory();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <SimpleReactLightbox>
                <ToastContainer
                    position='top-center'
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* <BrowserRouter> */}
                <Router history={history}>
                    <ThemeContext>
                        <App />
                    </ThemeContext>
                </Router>
                {/* </BrowserRouter> */}
            </SimpleReactLightbox>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
reportWebVitals();
