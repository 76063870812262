import React, {Fragment, useEffect, useState, useMount} from 'react';
import {Link} from 'react-router-dom';

const AdminUsers = props => {
    const {
        userList,
        jobData,
        jobPagination,
        handleAddNew,
        handleEdit,
        getUserList,
        setJobData,
        sort,
        isLoading,
    } = props;

    const [activePag, setActivePag] = useState(0);

    useEffect(() => {
        getUserList();
        
    }, [getUserList]);

    const onClick = i => {
        setActivePag(i);
        setJobData(userList.data.slice(i * sort, (i + 1) * sort));
    };

    return (
        <div className='col-12'>
            <div className='card'>
                <div className='card-header d-flex justify-content-between'>
                    <h4 className='card-title'>Restaurant Admin Users</h4>
                    <div>
                        <Link
                            className='btn btn-primary font-w600 mb-2 mr-auto'
                            to='#'
                            onClick={() => handleAddNew()}
                        >
                            + New User
                        </Link>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='w-100 table-responsive'>
                        {isLoading && (
                            <div id='preloader-inner'>
                                <div className='sk-three-bounce-inner'>
                                    <div className='sk-child sk-bounce1'></div>
                                    <div className='sk-child sk-bounce2'></div>
                                    <div className='sk-child sk-bounce3'></div>
                                    <div id='preloader-inner-bg'></div>
                                </div>
                            </div>
                        )}
                        {!isLoading && jobData && jobData.length > 0 ? (
                            <div
                                id='example_wrapper'
                                className='dataTables_wrapper'
                            >
                                <table
                                    id='example'
                                    className='display w-100 dataTable'
                                >
                                    <thead>
                                        <tr role='row'>
                                            {userList.columns.map((d, i) => (
                                                <th key={i}>{d}</th>
                                            ))}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {jobData
                                            ? jobData.map((d, i) => (
                                                  <tr key={i}>
                                                      {d.map((da, i) => (
                                                          <Fragment key={i}>
                                                              <td>
                                                                  {i === 3 ? (
                                                                      da ===
                                                                      'subadmin' ? (
                                                                          'Admin'
                                                                      ) : da ===
                                                                        'rest_staff' ? (
                                                                          'Restaurant Staff'
                                                                      ) : (
                                                                          'Waiter'
                                                                      )
                                                                  ) : i ===
                                                                    0 ? (
                                                                      <img
                                                                          className='rounded-circle'
                                                                          width='35'
                                                                          src={
                                                                              da
                                                                          }
                                                                          alt=''
                                                                      />
                                                                  ) : (
                                                                      <Fragment>
                                                                          {i !==
                                                                              5 &&
                                                                              da}
                                                                          {i ===
                                                                              5 && (
                                                                              <div className='m-2'>
                                                                                  <Link
                                                                                      to='#'
                                                                                      onClick={() =>
                                                                                          handleEdit(
                                                                                              da
                                                                                          )
                                                                                      }
                                                                                      className='btn btn-secondary shadow btn-xs sharp mr-1'
                                                                                  >
                                                                                      <i className='fa fa-pencil'></i>
                                                                                  </Link>
                                                                              </div>
                                                                          )}
                                                                      </Fragment>
                                                                  )}
                                                              </td>
                                                          </Fragment>
                                                      ))}
                                                  </tr>
                                              ))
                                            : null}
                                    </tbody>

                                    <tfoot>
                                        <tr role='row'>
                                            {userList.columns.map((d, i) => (
                                                <th key={i}>{d}</th>
                                            ))}
                                        </tr>
                                    </tfoot>
                                </table>

                                <div className='d-sm-flex text-center justify-content-between align-items-center mt-3'>
                                    <div className='dataTables_info'>
                                        Showing {activePag * sort + 1} to
                                        {userList.data.length <
                                        (activePag + 1) * sort
                                            ? userList.data.length
                                            : (activePag + 1) * sort}
                                        of {userList.data.length} entries
                                    </div>
                                    <div className='dataTables_paginate paging_simple_numbers'>
                                        <div
                                            className='dataTables_paginate paging_simple_numbers'
                                            id='example5_paginate'
                                        >
                                            <Link
                                                className='paginate_button previous disabled'
                                                to='/users'
                                                onClick={() =>
                                                    activePag > 0 &&
                                                    onClick(activePag - 1)
                                                }
                                            >
                                                Previous
                                            </Link>
                                            {jobPagination
                                                ? jobPagination.map(
                                                      (number, i) => (
                                                          <Link
                                                              to='/users'
                                                              key={i}
                                                              className={`paginate_button  ${
                                                                  activePag ===
                                                                  i
                                                                      ? 'current'
                                                                      : ''
                                                              } `}
                                                              onClick={() =>
                                                                  onClick(i)
                                                              }
                                                          >
                                                              {number}
                                                          </Link>
                                                      )
                                                  )
                                                : null}

                                            <Link
                                                className='paginate_button next'
                                                to='/users'
                                                onClick={() =>
                                                    activePag + 1 <
                                                        jobPagination.length &&
                                                    onClick(activePag + 1)
                                                }
                                            >
                                                Next
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div style={{height: '100px'}}>
                                <h5 className='text-center mt-5'>
                                    NO USERS FOUND
                                </h5>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminUsers;
