import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {Table} from 'react-bootstrap';
import AddTable from './AddTable';
import {getTableDetailsById} from '../../../services/SettingsService';

const TableList = props => {
    const {tableList, getTableList} = props;
    const [addNewModal, setAddNewModal] = useState(false);
    const [tableId, setTableId] = useState();
    const [tableDetails, setTableDetails] = useState();

    useEffect(() => {
        getTableList();
    }, [getTableList]);

    useEffect(() => {
        if (!addNewModal) {
            setTableDetails();
            setTableId();
            getTableList();
        }
    }, [addNewModal, getTableList]);

    const editData = id => {
        setAddNewModal(true);
        setTableId(id);
        getTableDetailsById(id).then(res => {
            setTableDetails(res);
        });
    };

    const addData = () => {
        setAddNewModal(true);
    };

    return (
        <>
            <div className='col-xl-12'>
                <div className='card'>
                    <div className='card-header d-flex justify-content-end'>
                        <div>
                            <Link
                                className='btn btn-primary font-w600 mb-2 mr-auto'
                                to='#'
                                onClick={addData}
                            >
                                + New Table
                            </Link>
                        </div>
                    </div>
                    <div className='card-body p-0'>
                        <div className='table-responsive order-list card-table'>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Table No</th>
                                        <th>No. of Persons</th>
                                        <th>Description</th>
                                        <th>Category</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableList && tableList.length ? (
                                        tableList.map(table => (
                                            <tr key={table.id}>
                                                <td>
                                                    <img
                                                        src={
                                                            process.env
                                                                .REACT_APP_API_RESOURCE_URL +
                                                            table.img
                                                        }
                                                        className='rounded-lg mr-2'
                                                        width='24'
                                                        alt=''
                                                    />
                                                </td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='w-space-no'>
                                                            {table.table_no}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='w-space-no'>
                                                            {table.nop}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='w-space-no'>
                                                            {table.desc}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='w-space-no'>
                                                            {table.category}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <i
                                                            className={`fa fa-circle ${
                                                                table.status
                                                                    ? 'text-success'
                                                                    : 'text-danger'
                                                            } mr-1`}
                                                        ></i>{' '}
                                                        {table.status
                                                            ? 'Active'
                                                            : 'Inactive'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex'>
                                                        <div
                                                            onClick={() =>
                                                                editData(
                                                                    table.id
                                                                )
                                                            }
                                                            className='btn btn-primary shadow btn-xs sharp mr-1'
                                                        >
                                                            <i className='fa fa-pencil'></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan='6'
                                                className='text-center'
                                            >
                                                No table items available
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                {addNewModal ? (
                    <AddTable
                        addNewModal={addNewModal}
                        setAddNewModal={setAddNewModal}
                        tableDetails={tableDetails}
                        setTableDetails={setTableDetails}
                        tableId={tableId}
                    />
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

export default TableList;
