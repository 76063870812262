import React from 'react';
import {Link} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';

const CustomDropdown = ({type, bookid}) => {
    let linkUrl;
    if (type === 'Reservation') {
        linkUrl = `/reservation-detail/${bookid}`;
    } else if (type === 'Skipque') {
        linkUrl = `/delivery-detail/${bookid}`;
    } else if (type === 'Catering') {
        linkUrl = `/catering-detail/${bookid}`;
    } else {
        linkUrl = `/notifications`;
    }
    return (
        <div>
            <Dropdown.Item as={Link} to={linkUrl} className='dropdown-item'>
                <i className='las la-check-circle text-success mr-3 scale5' />
                View Detail
            </Dropdown.Item>
        </div>
    );
};

export default CustomDropdown;
