import {API_CALL_ERROR} from '../actions/ErrorActions';
const initialState = {
    data: null,
    error: null,
};

export const ErrorReducer = (state = initialState, action) => {
    switch (action.type) {
        case API_CALL_ERROR:
            return {...state, error: action.payload};
        default:
            return state;
    }
};
