import axios from 'axios';

const axiosPublicInstance = axios.create({
    baseURL: 'https://kaser.texol.work/api/v1/admin/restaurant/',
    headers: {
        get: {
            'x-public-token': '76439265-59e1-4c44-9f74-311f05135c13',
        },
        post: {
            'x-public-token': '76439265-59e1-4c44-9f74-311f05135c13',
        }
    },
});

export default axiosPublicInstance;
