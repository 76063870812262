import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
    GET_OTP_ACTION,
    GET_OTP_FAIL_ACTION,
    SUBMIT_OTP_ACTION,
    SUBMIT_OTP_FAIL_ACTION,
    REMOVE_SUCCESS_MESSAGE,
    GET_FCM_TOKEN,
} from '../actions/AuthActions';

const initialState = {
    auth: {
        isAuthenticated: '',
        access: '',
        refresh: '',
        mobile: '',
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function AuthReducer(state = initialState, action) {
    if (action.type === GET_OTP_ACTION) {
        return {
            ...state,
            auth: {...state.auth, mobile: action.payload.mobile},
            showLoading: false,
        };
    }
    if (action.type === GET_OTP_FAIL_ACTION) {
        return {
            ...state,
            showLoading: false,
        };
    }
    if (action.type === SUBMIT_OTP_ACTION) {
        return {
            ...state,
            showLoading: false,
        };
    }
    if (action.type === SUBMIT_OTP_FAIL_ACTION) {
        return {
            ...state,
            showLoading: false,
        };
    }
    if (action.type === REMOVE_SUCCESS_MESSAGE) {
        return {
            ...state,
            successMessage: '',
        };
    }

    if (action.type === SIGNUP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: {...state.auth, ...action.payload},
            errorMessage: '',
            successMessage: 'Signup Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === LOGIN_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: {
                ...state.auth,
                ...action.payload,
                isAuthenticated: true,
            },
            errorMessage: '',
            successMessage: 'Login Successfully Completed',
            showLoading: false,
        };
    }

    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            auth: {
                access: '',
                refresh: '',
                mobile: '',
                isAuthenticated: '',
            },
        };
    }

    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }

    return state;
}
