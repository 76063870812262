import React, {useState, useCallback} from 'react';

import {getAllCustomers} from '../../../services/CustomerService';
import CustomerList from './CustomerList';

const Customer = () => {
    const [customerList, setCustomerList] = useState();

    // const sort = 3;
    const [page, setPage] = useState(1);
    const [totalPages, setTotalpages] = useState();
    const getCustomers = useCallback(page => {
        getAllCustomers(page).then(res => {
            setTotalpages(res.total_pages, 'total pages');

            setCustomerList(res.data);
        });
    }, []);
    return (
        <CustomerList
            customerList={customerList}
            setCustomerList={setCustomerList}
            getCustomers={getCustomers}
            page={page}
            totalPages={totalPages}
        />
    );
};

export default Customer;
