import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import {Table} from 'react-bootstrap';
import AddChef from './AddChef';

import {getChefDetailsById} from '../../../services/FoodItemsService';

const ChefList = props => {
    const {chefList, getChefList, isLoading} = props;
    const [addNewModal, setAddNewModal] = useState(false);
    const [chefId, setChefId] = useState();
    const [chefDetails, setChefDetails] = useState();

    useEffect(() => {
        getChefList();
    }, [getChefList]);

    useEffect(() => {
        if (!addNewModal) {
            setChefDetails();
            setChefId();
            getChefList();
        }
    }, [addNewModal, getChefList]);

    const editData = id => {
        setAddNewModal(true);
        setChefId(id);
        getChefDetailsById(id).then(res => {
            setChefDetails(res);
        });
    };

    const addData = () => {
        setAddNewModal(true);
    };

    return (
        <>
            <div className='col-xl-12'>
                <div className='card'>
                    <div className='card-header d-flex justify-content-end'>
                        <div>
                            <Link
                                className='btn btn-primary font-w600 mb-2 mr-auto'
                                to='#'
                                onClick={() => addData()}
                            >
                                + New Chef
                            </Link>
                        </div>
                    </div>
                    <div className='card-body p-0'>
                        <div className='table-responsive order-list card-table'>
                            {isLoading && (
                                <div id='preloader-inner'>
                                    <div className='sk-three-bounce-inner'>
                                        <div className='sk-child sk-bounce1'></div>
                                        <div className='sk-child sk-bounce2'></div>
                                        <div className='sk-child sk-bounce3'></div>
                                        <div id='preloader-inner-bg'></div>
                                    </div>
                                </div>
                            )}
                            {!isLoading && chefList && chefList.length > 0 ? (
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong></strong>
                                            </th>
                                            <th>
                                                <strong>NAME</strong>
                                            </th>
                                            <th>
                                                <strong>EMAIL</strong>
                                            </th>
                                            <th>
                                                <strong>
                                                    No. of Food Items
                                                </strong>
                                            </th>
                                            <th>
                                                <strong>Status</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {chefList?.length ? (
                                            chefList.map(chef => (
                                                <tr key={chef.id}>
                                                    <td>
                                                        <img
                                                            src={
                                                                process.env
                                                                    .REACT_APP_API_RESOURCE_URL +
                                                                chef.img
                                                            }
                                                            className='rounded-lg mr-2'
                                                            width='24'
                                                            alt=''
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <span className='w-space-no'>
                                                                {chef.name}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <span className='w-space-no'>
                                                                {chef.email}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <span className='w-space-no'>
                                                                {
                                                                    chef.no_of_fooditems
                                                                }
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <i
                                                                className={`fa fa-circle ${
                                                                    chef.status
                                                                        ? 'text-success'
                                                                        : 'text-danger'
                                                                } mr-1`}
                                                            ></i>{' '}
                                                            {chef.status
                                                                ? 'Active'
                                                                : 'Inactive'}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <div
                                                                onClick={() =>
                                                                    editData(
                                                                        chef.id
                                                                    )
                                                                }
                                                                className='btn btn-primary shadow btn-xs sharp mr-1'
                                                            >
                                                                <i className='fa fa-pencil'></i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td>
                                                    <div id='preloader-inner'>
                                                        <div className='sk-three-bounce-inner'>
                                                            <div className='sk-child sk-bounce1'></div>
                                                            <div className='sk-child sk-bounce2'></div>
                                                            <div className='sk-child sk-bounce3'></div>
                                                            <div id='preloader-inner-bg'></div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            ) : (
                                <div style={{height: '100px'}}>
                                    <h5 className='text-center mt-5'>
                                        NO CHEFS FOUND
                                    </h5>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* add new food item */}
                {addNewModal ? (
                    <AddChef
                        addNewModal={addNewModal}
                        setAddNewModal={setAddNewModal}
                        chefDetails={chefDetails}
                        setChefDetails={setChefDetails}
                        chefId={chefId}
                        getChefList={getChefList}
                    />
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

export default ChefList;
