import React, {useState, useCallback} from 'react';
import {getAllReservations} from '../../../services/OrderService';
import ReservationHistoryList from '../../components/OrderHistory/ReservationHistoryList';

const ReservationHistory = () => {
    const [reservationList, setReservationList] = useState();

    const [page, setPage] = useState(1);
    const [totalPages, setTotalpages] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const getReservations = useCallback((page, fDate, tDate) => {
        getAllReservations(page, fDate, tDate).then(res => {
            setTotalpages(res.total_pages, 'total pages');

            setReservationList(res.data);
        });
    }, []);

    return (
        <ReservationHistoryList
            reservationList={reservationList}
            getReservations={getReservations}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            page={page}
            totalPages={totalPages}
        />
    );
};

export default ReservationHistory;
