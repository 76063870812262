import React from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';

const DateFilter = props => {
    const {selectedDates, handleDateChange} = props;

    return (
        <div className='col-6 m-3'>
            <p className='mb-2 text-black font-w500'>DATE FILTER</p>

            <Flatpickr
                className='form-control'
                placeholder='Select a date'
                options={{
                    mode: 'range',
                    dateFormat: 'Y-m-d',
                    defaultDate: selectedDates,
                    onChange: handleDateChange,
                }}
            />
        </div>
    );
};

export default DateFilter;
