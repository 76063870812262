import {
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
    getOtp,
    submitOtp,
    saveTokenInCookies,
} from '../../services/AuthService';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
// import {getfirebaseToken} from '../../firebase';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const GET_OTP_ACTION = '[signup action] get otp';
export const GET_OTP_FAIL_ACTION = '[signup action] get otp fail';
export const SUBMIT_OTP_ACTION = '[signup action] submit otp';
export const SUBMIT_OTP_FAIL_ACTION = '[signup action] submit otp fail';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const REMOVE_SUCCESS_MESSAGE = '[message action] remove success mssg';
// export const GET_FCM_TOKEN = 'Gnereting fcm token';
export const getOtpAction = mobile => async dispatch => {
    try {
        const res = await getOtp(mobile);
        if (res.data.status === 'success') {
            dispatch({type: GET_OTP_ACTION, payload: mobile});
        } else {
            dispatch({type: GET_OTP_FAIL_ACTION});
        }
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const submitOtpAction = eotp => async dispatch => {
    try {
        const res = await submitOtp(eotp);
        if (res.data.status === 'success') {
            dispatch({type: SUBMIT_OTP_ACTION, payload: eotp});
            setTimeout(() => {
                dispatch({type: REMOVE_SUCCESS_MESSAGE, payload: ''});
            }, 2000);
        } else {
            dispatch({type: SUBMIT_OTP_FAIL_ACTION, payload: res.data.message});
        }
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const signupAction = data => async dispatch => {
    try {
        const res = await signUp(data);
        if (res.data.status === 'success') {
            dispatch({type: SIGNUP_CONFIRMED_ACTION, payload: data});
            setTimeout(() => {
                dispatch({type: REMOVE_SUCCESS_MESSAGE, payload: ''});
            }, 2000);
        } else {
            dispatch({type: SIGNUP_FAILED_ACTION, payload: res.data.message});
        }
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export function logout(history) {
    localStorage.removeItem('refreshToken');
    Cookies.remove('access');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export const loginAction =
    (email, password, history, fcmToken) => async dispatch => {
        
        try {
            
            const res = await login(email, password, fcmToken);
            if (res.data.status === 'success') {
                
                const {access: accessToken, refresh: refreshToken} =
                    res.data.data.message;
                saveTokenInCookies(accessToken);
                saveTokenInLocalStorage(refreshToken);
                let refreshTokenExpireDate = new Date(
                    jwt_decode(refreshToken).exp * 1000
                );
                const timer =
                    refreshTokenExpireDate.getTime() - new Date().getTime();
                runLogoutTimer(dispatch, timer, history);
                dispatch(loginConfirmedAction(res.data.data.message));
                history.push('/dashboard');
            } else {
                console.log(res.data.data.message)
                const errorMessage = res.data.data.message;
                dispatch(loginFailedAction(errorMessage));
            }
        } catch (error) {
            console.log(error);
            dispatch(loginFailedAction(error.message));
        }
    };

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

// export const getFcmTokenAction = setTokenFound => async dispatch => {
//     try {
//         const fcmToken = await getfirebaseToken(setTokenFound);
//         return fcmToken;
//     } catch (err) {
//         console.log(err);
//     }
// };
