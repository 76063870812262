import {useState, useEffect, useCallback, useRef} from 'react';
import {Modal} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {ErrorMessage} from '@hookform/error-message';
import {useForm, Controller} from 'react-hook-form';
import FormInput from '../FoodMenu/FormInput';
import {
    updateCategoryDetails,
    addNewCategory,
    deleteTablecategoryById,
    getAllCategoryList,
} from '../../../services/SettingsService';
import {objectToFormData} from '../../../utils/objectToFormData';
// images
import uploadImageIcon from '../../../images/upload_image.png';
import swal from 'sweetalert';
const notify = (id, type, message, time) => {
    toast.update(id, {
        render: message,
        type: type,
        position: 'top-center',
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        isLoading: false,
    });
};

const AddTableCategory = props => {
    const {addNewModal, setAddNewModal, categoryDetails, categoryId} = props;

    const uploadRef = useRef(null);
    const {
        handleSubmit,
        formState: {errors},
        register,
        setValue,
        watch,
    } = useForm({mode: 'all'});
    const {ref, ...rest} = register('img');

    const watchImage = watch('img', null);

    useEffect(() => {
        if (categoryDetails) {
            const fields = ['name','name_arb', 'status'];
            fields.forEach(field => setValue(field, categoryDetails[field]));
        }
    }, [setValue, categoryDetails]);

    console.log(categoryDetails)
    const onClickDeleteTablecategory = () => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this category!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then(async willDelete => {
            if (willDelete) {
                let res = await deleteTablecategoryById(categoryId);
                if (res.status === 'success') {
                    swal(
                        `The cuisine ${categoryDetails.name} has been deleted!`,
                        {
                            icon: 'success',
                        }
                    );
                    setAddNewModal(false);
                    getAllCategoryList();
                } else {
                    swal(res.data.message);
                }
            }
        });
    };

    const onSubmit = async data => {
        console.log(data, 'data');
        if (categoryDetails) {
            // const fields = ['name', 'status'];
            // fields.forEach(field => {
            //     if (field === 'img' && data[field].length < 1) {
            //         delete data[field];
            //     } else if (field === 'img') {
            //         data.img = data.img[0];
            //     }
            //     if (data[field] === undefined) {
            //         delete data[field];
            //     }
            // });
            if (data.img.length < 1) {
                delete data.img;
            } else {
                data.img = data.img[0];
            }
            console.log(data, 'data');
            let postData = objectToFormData(data, '');
            const toaster = toast.loading('Updating...');
            let res = await updateCategoryDetails(categoryId, postData);
            if (res.status === 'success') {
                notify(toaster, 'success', 'Category details updated!', 2000);
                setAddNewModal(false);
            } else {
                notify(toaster, 'error', res.data.message, 2000);
            }
        } else {
            const toaster = toast.loading('Creating...');
            data.img = data.img[0];
            let postData = objectToFormData(data);
            let res = await addNewCategory(postData);
            if (res.status === 'success') {
                notify(toaster, 'success', 'New category details added!', 2000);
                setAddNewModal(false);
            } else {
                notify(toaster, 'error', res.data.message, 2000);
            }
        }
    };

    if (categoryId && !categoryDetails) {
        return (
            <div id='preloader-inner'>
                <div className='sk-three-bounce-inner'>
                    <div className='sk-child sk-bounce1'></div>
                    <div className='sk-child sk-bounce2'></div>
                    <div className='sk-child sk-bounce3'></div>
                    <div id='preloader-inner-bg'></div>
                </div>
            </div>
        );
    } else {
        return (
            <Modal
                show={addNewModal}
                onHide={setAddNewModal}
                className='modal fade'
                id='addNewModal'
            >
                <>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                {categoryDetails && categoryId
                                    ? 'Update Category'
                                    : 'Add Category'}
                            </h5>
                            <button
                                type='button'
                                className='close'
                                data-dismiss='modal'
                                onClick={() => setAddNewModal(false)}
                            >
                                <span>×</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='food-image media mb-3'>
                                    {categoryDetails ||
                                    uploadRef?.current?.files.length ? (
                                        <>
                                            <button
                                                type='button'
                                                onClick={() =>
                                                    uploadRef.current.click()
                                                }
                                                className='btn btn-primary food-image-edit'
                                            >
                                                <i className='flaticon-381-edit'></i>
                                            </button>
                                            <img
                                                className='mr-3 img-fluid rounded'
                                                src={
                                                    watchImage &&
                                                    watchImage.length &&
                                                    uploadRef.current.files
                                                        .length
                                                        ? URL.createObjectURL(
                                                              uploadRef?.current
                                                                  .files[0]
                                                          )
                                                        : process.env
                                                              .REACT_APP_API_RESOURCE_URL +
                                                          categoryDetails?.img
                                                }
                                                alt='upload category pic here'
                                            />
                                        </>
                                    ) : (
                                        <img
                                            className='mr-3 img-fluid rounded'
                                            onClick={() =>
                                                uploadRef?.current?.click()
                                            }
                                            style={{
                                                width: '100px',
                                                cursor: 'pointer',
                                            }}
                                            src={uploadImageIcon}
                                            alt='upload category pic here'
                                        />
                                    )}
                                    <div className='custom-file mb-3'>
                                        <input
                                            type='file'
                                            {...rest}
                                            name='img'
                                            ref={e => {
                                                ref(e);
                                                uploadRef.current = e;
                                            }}
                                            className='custom-file-input'
                                            id='val-img'
                                            hidden
                                        />
                                    </div>
                                    <ErrorMessage
                                        errors={errors}
                                        name='img'
                                        render={({message}) => (
                                            <div className='text-danger fs-12'>
                                                {message}
                                            </div>
                                        )}
                                    />
                                </div>
                                <FormInput
                                    label={'Name'}
                                    id={'val-name'}
                                    name={'name'}
                                    required={true}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('name', {
                                            required:
                                                'Category name is required!',
                                        }),
                                    }}
                                    errors={errors}
                                />

<                               FormInput
                                    label={'Arabic Name'}
                                    id={'val-name'}
                                    name={'name_arb'}
                                    required={true}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('name_arb', {
                                            required:
                                                'Category name is required!',
                                        }),
                                    }}
                                    errors={errors}
                                />
                                <div className='custom-control custom-checkbox mb-3'>
                                    <input
                                        type='checkbox'
                                        defaultChecked
                                        className='custom-control-input'
                                        {...register('status')}
                                        id='val-status'
                                    />
                                    <label
                                        className='custom-control-label'
                                        htmlFor='val-status'
                                    >
                                        Active
                                    </label>
                                </div>
                                <div className='modal-footer'>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                    >
                                        {categoryDetails && categoryId
                                            ? 'Update'
                                            : 'Add'}
                                    </button>
                                    {/* <button
                                        type='button'
                                        onClick={() => setAddCard(false)}
                                        className='btn btn-danger'
                                    >
                                        {' '}
                                        <i className='flaticon-delete-1'></i>{' '}
                                        Discard
                                    </button> */}
                                    {categoryDetails && categoryId ? (
                                        <button
                                            className='btn btn-danger'
                                            type='button'
                                            onClick={onClickDeleteTablecategory}
                                        >
                                            Delete
                                        </button>
                                    ) : (
                                        ' '
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            </Modal>
        );
    }
};

export default AddTableCategory;
