import React, {useState, useCallback} from 'react';
import FoodList from '../../components/FoodMenu/FoodList';
import {getAllFoodItems} from '../../../services/FoodItemsService';

const FoodItems = () => {
    const [foodItemList, setFoodItemList] = useState();
    const getFoodItemList = useCallback(() => {
        getAllFoodItems().then(res => {
            res.data.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
            setFoodItemList(res.data);
        });
    }, []);

    return (
        <>
            <FoodList
                getFoodItemList={getFoodItemList}
                foodItemList={foodItemList}
            />
        </>
    );
};

export default FoodItems;
