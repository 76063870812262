import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';
import {toast} from 'react-toastify';

const firebaseConfig = {
    apiKey: 'AIzaSyDykFG49avorfmdld1Th07tnFqgGu6yN4c',
    authDomain: 'skippy-customer-app-c3491.firebaseapp.com',
    projectId: 'skippy-customer-app-c3491',
    storageBucket: 'skippy-customer-app-c3491.appspot.com',
    messagingSenderId: '498394071115',
    appId: '1:498394071115:web:9b634553c833a0e6864dce',
    measurementId: 'G-XGLTC8GRJ4',
};

initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const messaging = getMessaging(firebaseApp);

export const getfirebaseToken = setTokenFound => {
    return getToken(messaging, {
        vapidKey:
            'BAjYeFrLxRWv3gYYwaJ0WLu3Zaeuo9BBt6PED_asZ44qAw84XhSi3kSxLneMz3Z3nzA37q2pJ9EU_OpP21FZxG8',
    })
        .then(fcmToken => {
            if (fcmToken) {
                console.log('current token for client: ', fcmToken);
                setTokenFound(true);
                return fcmToken;
            } else {
                console.log(
                    'No registration token available. Request permission to generate one.'
                );
                toast(
                    'No registration token available. Request permission to generate one.'
                );
                setTokenFound(false);
                // shows on the UI that permission is required
            }
        })
        .catch(err => {
            console.log('An error occurred while retrieving token. ', err);
            toast.error(`An error occurred while retrieving token. ${err}`);
        });
};

export const onMessageListener = () =>
    new Promise(resolve => {
        onMessage(messaging, payload => {
            resolve(payload);

            // const {title, body} = payload.notification;

            // toast(`Notification received:${title}\n${body}`);
        });
    });
