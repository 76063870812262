import React, {useState, useCallback} from 'react';
import {getAllTable} from '../../../services/SettingsService';
import TableList from '../../components/Settings/TableList';

const TableSettings = () => {
    const [tableList, setTableList] = useState();

    const getTableList = useCallback(() => {
        getAllTable().then(res => {
            setTableList(res);
        });
    }, []);
    return <TableList tableList={tableList} getTableList={getTableList} />;
};

export default TableSettings;
