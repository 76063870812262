import axiosPrivateInstance from './AxiosPrivateInstance';
import {handleApiError} from './SettingsService';

export const getAllFoodItems = async () => {
    try {
        const res = await axiosPrivateInstance.get('/restaurant-foods-list/');
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getFoodItemDetailsById = async id => {
    try {
        const res = await axiosPrivateInstance.get(
            `/restaurant-food-detail/?pk=${id}`
        );
        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const deleteFoodItem = async id => {
    try {
        const res = await axiosPrivateInstance.delete(
            `/restaurant-food-detail/?pk=${id}`
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getAllCuisineList = async () => {
    try {
        const res = await axiosPrivateInstance.get('/restaurant-cuisine/');
        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getAllChefList = async () => {
    try {
        const res = await axiosPrivateInstance.get('/restaurant-chef/');
        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const updateFoodItemDetails = async (id, data) => {
    try {
        const res = await axiosPrivateInstance.patch(
            `restaurant-food-detail/?pk=${id}`,
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const addNewFoodItem = async data => {
    try {
        const res = await axiosPrivateInstance.post(
            `restaurant-foods-list/`,
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getCuisineDetailsById = async id => {
    try {
        const res = await axiosPrivateInstance.get(
            `restaurant-cuisine-detail/?pk=${id}`
        );
        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const updateCuisineDetails = async (id, data) => {
    try {
        const res = await axiosPrivateInstance.patch(
            `restaurant-cuisine-detail/?pk=${id}`,
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const deleteCuisineById = async id => {
    try {
        const res = await axiosPrivateInstance.delete(
            `restaurant-cuisine-detail/?pk=${id}`
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const deleteChefById = async id => {
    try {
        const res = await axiosPrivateInstance.delete(
            `restaurant-chef-detail/?pk=${id}`
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const addNewCuisine = async data => {
    try {
        const res = await axiosPrivateInstance.post(
            `restaurant-cuisine/`,
            data
        );

        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const getChefDetailsById = async id => {
    try {
        const res = await axiosPrivateInstance.get(
            `restaurant-chef-detail/?pk=${id}`
        );
        return res.data.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const updateChefDetails = async (id, data) => {
    try {
        const res = await axiosPrivateInstance.patch(
            `restaurant-chef-detail/?pk=${id}`,
            data
        );
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};

export const addNewChef = async data => {
    try {
        const res = await axiosPrivateInstance.post(`restaurant-chef/`, data);
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
