import React, {Fragment, useState, useEffect} from 'react';
import {Button, Dropdown, Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {SRLWrapper} from 'simple-react-lightbox';
//** Import Image */
// import profile01 from '../../../../images/profile/1.jpg';
// import profile02 from '../../../../images/profile/2.jpg';
// import profile03 from '../../../../images/profile/3.jpg';
// import profile04 from '../../../../images/profile/4.jpg';
// import profile05 from '../../../../images/profile/5.jpg';
// import profile06 from '../../../../images/profile/6.jpg';
// import profile07 from '../../../../images/profile/7.jpg';
// import profile08 from '../../../../images/profile/8.jpg';
// import profile09 from '../../../../images/profile/9.jpg';
import profile from '../../../images/profile/profile.jpg';
import PageTitle from '../../layouts/PageTitle';
import EditProfile from './EditProfile';

// Redux
import {connect} from 'react-redux';

const ProfileCard = props => {
    const {userProfile} = props;
    console.log(userProfile, 'USER');

    const [EditModal, setEditModal] = useState(false);

    const [profileDetails, setProfileDetails] = useState();

    const editProfile = data => {
        // console.log(data, 'profileDetails');
        setEditModal(true);
        setProfileDetails(data);

        // console.log(id, 'id');
        // setAddNewModal(true);
        // setCategoryId(id);
        // getCategoryDetailsById(id).then(res => {
        //     console.log(res, 'cat');
        //     setCategoryDetails(res);
        // });
    };
    // useEffect(() => {
    //     console.log(profileDetails, 'profile');
    // }, [setProfileDetails]);

    return (
        <Fragment>
            <PageTitle activeMenu='Profile' motherMenu='App' />

            <div className='row'>
                <div className='col-lg-12'>
                    <div className='profile card card-body px-3 pt-3 pb-0'>
                        <div className='profile-head'>
                            <div className='photo-content'>
                                <div className='cover-photo'></div>
                            </div>
                            <div className='profile-info d-flex'>
                                <div className='profile-photo'>
                                    {userProfile.img ? (
                                        <img
                                            src={
                                                process.env
                                                    .REACT_APP_API_RESOURCE_URL +
                                                userProfile.img
                                            }
                                            className='img-fluid rounded-circle'
                                            alt='profile'
                                        />
                                    ) : (
                                        <img
                                            src={profile}
                                            className='img-fluid rounded-circle'
                                            alt='profile'
                                        />
                                    )}
                                    {/* <img
                                        src={
                                            process.env
                                                .REACT_APP_API_RESOURCE_URL +
                                            userProfile.img
                                        }
                                        className='img-fluid rounded-circle'
                                        alt='profile'
                                    /> */}
                                </div>
                                <div className='profile-details'>
                                    <div className='profile-name px-3 pt-2'>
                                        <h4 className='text-primary mb-0'>
                                            {userProfile.full_name}
                                        </h4>
                                        <p>{userProfile.designation}</p>
                                    </div>
                                    <div className='profile-email px-2 pt-2'>
                                        <h4 className='text-muted mb-0'>
                                            {userProfile.email}
                                        </h4>
                                        <p>Email</p>
                                    </div>
                                    <div
                                        className='ml-auto'
                                        //  px-5  float-end'
                                        // style={{

                                        //     justifyContent: 'flex-end',
                                        // }}
                                    >
                                        <div
                                            onClick={() =>
                                                editProfile(userProfile)
                                            }
                                            className='btn btn-primary shadow btn-xs sharp mr-1'
                                        >
                                            <i className='fa fa-pencil'></i>
                                        </div>
                                    </div>
                                    {/* <Dropdown className='dropdown ml-auto'>
                                        <Dropdown.Toggle
                                            variant='primary'
                                            className='btn btn-primary light sharp i-false'
                                            data-toggle='dropdown'
                                            aria-expanded='true'
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                //    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                width='18px'
                                                height='18px'
                                                viewBox='0 0 24 24'
                                                version='1.1'
                                            >
                                                <g
                                                    stroke='none'
                                                    strokeWidth='1'
                                                    fill='none'
                                                    fillRule='evenodd'
                                                >
                                                    <rect
                                                        x='0'
                                                        y='0'
                                                        width='24'
                                                        height='24'
                                                    ></rect>
                                                    <circle
                                                        fill='#000000'
                                                        cx='5'
                                                        cy='12'
                                                        r='2'
                                                    ></circle>
                                                    <circle
                                                        fill='#000000'
                                                        cx='12'
                                                        cy='12'
                                                        r='2'
                                                    ></circle>
                                                    <circle
                                                        fill='#000000'
                                                        cx='19'
                                                        cy='12'
                                                        r='2'
                                                    ></circle>
                                                </g>
                                            </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='dropdown-menu dropdown-menu-right'>
                                            <Dropdown.Item className='dropdown-item'>
                                                <i className='fa fa-user-circle text-primary mr-2' />
                                                Edit profile
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* add new food item */}
                {EditModal ? (
                    <EditProfile
                        EditModal={EditModal}
                        setEditModal={setEditModal}
                        profileDetails={profileDetails}
                        setProfileDetails={setProfileDetails}
                    />
                ) : (
                    ''
                )}
            </div>
        </Fragment>
    );
};
const mapStateToProps = state => {
    return {
        userProfile: state.user,
    };
};

export default connect(mapStateToProps)(ProfileCard);
