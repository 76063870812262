import React, {useEffect, useState, useMemo} from 'react';
import {Card, Table, Badge, Dropdown} from 'react-bootstrap';
import {rejectSkipque} from '../../../services/OrderService';
import {confirmSkipque} from '../../../services/OrderService';
import {Link} from 'react-router-dom';
import Status from './Status';
import Loader from './Loader';
const svg1 = (
    <svg width='20px' height='20px' viewBox='0 0 24 24' version='1.1'>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <rect x='0' y='0' width='24' height='24'></rect>
            <circle fill='#000000' cx='5' cy='12' r='2'></circle>
            <circle fill='#000000' cx='12' cy='12' r='2'></circle>
            <circle fill='#000000' cx='19' cy='12' r='2'></circle>
        </g>
    </svg>
);

const TakeawayList = props => {
    const {takeawayList, getUpcomingTakeaways, setTakeawayList, isloading} =
        props;
    const [filteredList, setFilteredList] = useState(takeawayList ?? []);
    useEffect(() => {
        getUpcomingTakeaways();
    }, [getUpcomingTakeaways]);
    useEffect(() => {
        setFilteredList(takeawayList);
    }, [takeawayList]);

    const handleConfirm = async id => {
        try {
            await confirmSkipque(id);
            const res = await getUpcomingTakeaways();
            setTakeawayList(res);
        } catch (error) {
            console.log(error);
        }
    };
    const handleReject = async id => {
        try {
            await rejectSkipque(id);
            const res = await getUpcomingTakeaways();
            setTakeawayList(res);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Status
                List={takeawayList}
                filteredList={filteredList}
                setFilteredList={setFilteredList}
            />
            <Card>
                <Card.Header>
                    <Card.Title>Live Take away Orders</Card.Title>
                </Card.Header>
                {isloading && <Loader />}
                {!isloading && filteredList && filteredList.length > 0 ? (
                    <Card.Body>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>DELIVERY NO.</strong>
                                    </th>
                                    <th>
                                        <strong>DATE</strong>
                                    </th>
                                    <th>
                                        <strong>SLOT</strong>
                                    </th>
                                    <th>
                                        <strong>CUSTOMER</strong>
                                    </th>
                                    <th>
                                        <strong>AMOUNT</strong>
                                    </th>
                                    <th>
                                        <strong>STATUS</strong>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isloading &&
                                filteredList &&
                                filteredList.length > 0 ? (
                                    filteredList.map(each => (
                                        <tr key={each.booking_id}>
                                            <td>
                                                <strong>
                                                    {each.booking_id}
                                                </strong>
                                            </td>
                                            <td>{each.date} </td>
                                            <td>{each.slote}</td>
                                            <td>{each.initiator}</td>
                                            <td>SAR {each.total}</td>
                                            <td>
                                                <Badge
                                                    variant={
                                                        each.status ===
                                                        'Booking Confirmed'
                                                            ? 'success light'
                                                            : 'warning'
                                                    }
                                                >
                                                    {each.status}
                                                </Badge>
                                            </td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant={
                                                            each.status ===
                                                            'Booking Confirmed'
                                                                ? 'success'
                                                                : 'warning'
                                                        }
                                                        className='light sharp i-false'
                                                    >
                                                        {svg1}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='dropdown-menu dropdown-menu-right'>
                                                        {each.status !== "Booking Confirmed" && each.status !== "Food Ready" && (
                                                             <Dropdown.Item
                                                            className='dropdown-item'
                                                            onClick={() =>
                                                                handleConfirm(
                                                                    each.booking_id
                                                                )
                                                            }
                                                        >
                                                            <i className='las la-check-circle text-success mr-3 scale5' />
                                                            Confirm
                                                        </Dropdown.Item>
                                                        )}
                                                        {
                                                           ( each.status === "Pending" || each.status === "Booking Completed") && (
                                                                <Dropdown.Item
                                                            onClick={() =>
                                                                handleReject(
                                                                    each.booking_id
                                                                )
                                                            }
                                                            data-dismiss='alert'
                                                            aria-label='Close'
                                                            className='dropdown-item'
                                                        >
                                                            <i className='las la-times-circle text-danger mr-3 scale5' />
                                                            Reject
                                                        </Dropdown.Item> 
                                                            )
                                                       }
                                                       
                                                        <Dropdown.Item
                                                            className='dropdown-item'
                                                            as={Link}
                                                            to={`/take-away-detail/${each.booking_id}`}
                                                        >
                                                            View Details
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr></tr>
                                )}
                            </tbody>
                        </Table>
                    </Card.Body>
                ) : (
                    <div style={{height: '100px'}}>
                        <h5 className='text-center mt-5'>NO ORDER FOUND</h5>
                    </div>
                )}
            </Card>
        </>
    );
};

export default TakeawayList;
