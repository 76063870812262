import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {Table} from 'react-bootstrap';
import AddTableCategory from './AddTableCategory';

import {getCategoryDetailsById} from '../../../services/SettingsService';

const TableCategoryList = props => {
    const {categoryList, getCategoryList} = props;
    const [addNewModal, setAddNewModal] = useState(false);
    const [categoryId, setCategoryId] = useState();
    const [categoryDetails, setCategoryDetails] = useState();

    useEffect(() => {
        getCategoryList();
    }, [getCategoryList]);

    useEffect(() => {
        if (!addNewModal) {
            setCategoryDetails();
            setCategoryId();
            getCategoryList();
        }
    }, [addNewModal, getCategoryList]);

    const editData = id => {
        setAddNewModal(true);
        setCategoryId(id);
        getCategoryDetailsById(id).then(res => {
            setCategoryDetails(res[0]);
        });
    };

    const addData = id => {
        setAddNewModal(true);
    };

    return (
        <>
            <div className='col-xl-12'>
                <div className='card'>
                    <div className='card-header d-flex justify-content-end'>
                        <div>
                            <Link
                                className='btn btn-primary font-w600 mb-2 mr-auto'
                                to='#'
                                onClick={() => addData()}
                            >
                                + New Category
                            </Link>
                        </div>
                    </div>
                    <div className='card-body p-0'>
                        <div className='table-responsive order-list card-table'>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <strong></strong>
                                        </th>
                                        <th>
                                            <strong>NAME</strong>
                                        </th>
                                        <th>
                                            <strong>No. of Tables</strong>
                                        </th>
                                        <th>
                                            <strong>Status</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categoryList?.length ? (
                                        categoryList.map(category => (
                                            <tr key={category.id}>
                                                <td>
                                                    <img
                                                        src={
                                                            process.env
                                                                .REACT_APP_API_RESOURCE_URL +
                                                            category.img
                                                        }
                                                        className='rounded-lg mr-2'
                                                        width='24'
                                                        alt=''
                                                    />
                                                </td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='w-space-no'>
                                                            {category.name}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='w-space-no'>
                                                            {
                                                                category.no_of_tables
                                                            }
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        <i
                                                            className={`fa fa-circle ${
                                                                category.status
                                                                    ? 'text-success'
                                                                    : 'text-danger'
                                                            } mr-1`}
                                                        ></i>{' '}
                                                        {category.status
                                                            ? 'Active'
                                                            : 'Inactive'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex'>
                                                        <div
                                                            onClick={() =>
                                                                editData(
                                                                    category.id
                                                                )
                                                            }
                                                            className='btn btn-primary shadow btn-xs sharp mr-1'
                                                        >
                                                            <i className='fa fa-pencil'></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        // <tr>
                                        //     <td>
                                        //         <div id='preloader-inner'>
                                        //             <div className='sk-three-bounce-inner'>
                                        //                 <div className='sk-child sk-bounce1'></div>
                                        //                 <div className='sk-child sk-bounce2'></div>
                                        //                 <div className='sk-child sk-bounce3'></div>
                                        //                 <div id='preloader-inner-bg'></div>
                                        //             </div>
                                        //         </div>
                                        //     </td>
                                            // </tr>
                                             <tr>
                                            <td colSpan='6' className='text-center'>
                                                No table items available
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                {/* add new food item */}
                {addNewModal ? (
                    <AddTableCategory
                        addNewModal={addNewModal}
                        setAddNewModal={setAddNewModal}
                        categoryDetails={categoryDetails}
                        setCategoryDetails={setCategoryDetails}
                        categoryId={categoryId}
                    />
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

export default TableCategoryList;
