import React, {Fragment, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getSkipthequeDetail} from '../../../services/OrderService';
import Loader from '../Orders/Loader';
import {rejectSkipque} from '../../../services/OrderService';
import { confirmSkipque ,completeOrder,foodReady} from '../../../services/OrderService';
import ErrorModal from '../../pages/Error';
// images
import {Table, Badge, Dropdown} from 'react-bootstrap';
const svg1 = (
    <svg width='20px' height='20px' viewBox='0 0 24 24' version='1.1'>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <rect x='0' y='0' width='24' height='24'></rect>
            <circle fill='#000000' cx='5' cy='12' r='2'></circle>
            <circle fill='#000000' cx='12' cy='12' r='2'></circle>
            <circle fill='#000000' cx='19' cy='12' r='2'></circle>
        </g>
    </svg>
);

const ViewTakeawayDetail = () => {
    let {id} = useParams();
    const [data, setData] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorModal,setShowErrorModal]=useState(false)

   
   

      const fetchData = async () => {
        try {
            const res = await getSkipthequeDetail(id);
            console.log(res)
            setData(res.data);

        }
        catch (error) {
            const errorMessage = error.response?.message;
            if (errorMessage) {
                setErrorMessage(errorMessage)
            }
            else {
                setErrorMessage("An Error Occured while processing")
            }

        }
    }

    const handleCloseErrorModal = () => {
        setShowErrorModal(false)
    }
   
    const handleConfirm = async (id) => {
    try {
        const response = await confirmSkipque(id);
        console.log(response);

     
        if (response.data && response.status === 'fail') {
            const errorMessage = response.data.message;

         
            console.error(errorMessage); 

        
            setErrorMessage(errorMessage);
            setShowErrorModal(true);
        } else {
            
            await fetchData();
        }
    } catch (error) {
        console.error("An error occurred while processing the request:", error);

        
        const errorMessage = error.response?.data?.message;

       
        if (errorMessage) {
            setErrorMessage(errorMessage);
        } else {
            setErrorMessage("An error occurred while processing the request.");
        }
    }
};

    const handleReject = async id => {
        try {
            await rejectSkipque(id);
        } catch (error) {
            console.log(error);
        }
    };

    const handleOrderCompleted = async (id) => {
    try {
        const response = await completeOrder(id);
        console.log(response)

       
        if (response.data && response.status === 'fail') {
            const errorMessage = response.data.message;

         
            console.error(errorMessage); 
            setErrorMessage(errorMessage);
            setShowErrorModal(true)
        } else {
            
            await fetchData();
        }
    } catch (error) {
        console.error("An error occurred while processing the request:", error);

        
        const errorMessage = error.response?.data?.message;

       
        if (errorMessage) {
            setErrorMessage(errorMessage);
        } else {
            setErrorMessage("An error occurred while processing the request.");
        }
    }
    };
    
      const handleFoodReady = async (id) => {
    try {
        const response = await foodReady(id);
        
        
        if (response.data && response.data.status === 'fail') {
            const errorMessage = response.data.data.message;
            
          
            console.error(errorMessage); 
            return (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            );
        }

        await fetchData();
    } catch (error) {
        console.error("An error occurred while processing the request:", error);
    }
};

    useEffect(() => {
      fetchData()
  },[id])

    // {
    //     if (data.length > 0) {
    return (
        <Fragment>
            <div className='row'>
                  <ErrorModal show={showErrorModal} handleClose={handleCloseErrorModal} errorMessage={errorMessage} />
                {data?.length > 0 ? (
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-header'>
                                {' '}
                                <strong>{data[0].booking_id}</strong>
                                <strong> {data[0].date}</strong>
                                <div className='d-flex'>
                                    <Badge
                                        variant={
                                            data[0].status ===
                                                'Booking Confirmed' ||
                                            data[0].status ===
                                                'Booking Completed' ||
                                                data[0].status === 'Order Completed' ||
                                                data[0].status === "Food Ready"
                                                ? 'success'
                                                : 'warning'
                                        }
                                    >
                                        {data[0].status}
                                    </Badge>
                                    <div className='ml-3'>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant={
                                                    data[0].status ===
                                                    'Booking Confirmed'
                                                        ? 'success'
                                                        : 'warning'
                                                }
                                                className='light sharp i-false'
                                            >
                                                {svg1}
                                            </Dropdown.Toggle>

                                           {data[0].status !==
                                                'Order Completed'  &&   (
                                                <Dropdown.Menu className='dropdown-menu dropdown-menu-right'>
                                                    {data[0].status !==
                                                        'Booking Confirmed' && data[0].status !== "Food Ready" && (
                                                        <Dropdown.Item
                                                            className='dropdown-item'
                                                            onClick={() =>
                                                                handleConfirm(
                                                                    data[0]
                                                                        .booking_id
                                                                )
                                                            }
                                                        >
                                                            <i className='las la-check-circle text-success mr-3 scale5' />
                                                            Confirm
                                                        </Dropdown.Item>
                                                    )}

                                                    {/* <Dropdown.Item
                                                    onClick={() =>
                                                        handleReject(
                                                            data[0].booking_id
                                                        )
                                                    }
                                                    data-dismiss='alert'
                                                    aria-label='Close'
                                                    className='dropdown-item'
                                                >
                                                    <i className='las la-times-circle text-danger mr-3 scale5' />
                                                    Reject
                                                </Dropdown.Item> */}
                                                    {data[0].status ===
                                                        'Booking Confirmed' && (
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                handleFoodReady(
                                                                    data[0]
                                                                        .booking_id
                                                                )
                                                            }
                                                            className='dropdown-item'
                                                        >
                                                            <i className='las la-check-double text-info mr-3 scale5' />
                                                            Food Ready
                                                        </Dropdown.Item>
                                                    )}
                                                    {data[0].status ===
                                                        'Food Ready' && (
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                handleOrderCompleted(
                                                                    data[0]
                                                                        .booking_id
                                                                )
                                                            }
                                                            className='dropdown-item'
                                                        >
                                                            <i className='las la-check-double text-info mr-3 scale5' />
                                                            Order Completed
                                                        </Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            )}
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body'>
                                {/* <div className='row mb-5  ml-4 justify-content-between mr-5'>
                                    <div className='mt-4 col-xl-4 col-lg-8 col-md-6 col-sm-6'>
                                        <strong>
                                            Customer:{data[0].initiator}
                                        </strong>

                                        <div>
                                            Phone:
                                            {data[0].foodlist[0].added_by}
                                        </div>
                                    </div>
                                    <div className=' mt-4 float_right mr-5'>
                                        <div>Order Time:{data[0].time}</div>
                                        <div>Slote:{data[0].slote}</div>
                                        <div>
                                            Balance:{data[0].balance_to_pay}
                                        </div>
                                        <div>
                                            Payment Status:{data[0].payment}
                                        </div>
                                    </div>
                                </div> */}
                                <div className='row'>
                                    <div className='col-lg-5 col-sm-5'>
                                        <table className=' table-clear'>
                                            <tbody>
                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>
                                                            Customer
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].initiator}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong> Phone</strong>
                                                    </td>
                                                    <td className='right'>
                                                        {
                                                            data[0].foodlist[0]
                                                                .added_by
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>
                                                            {' '}
                                                            Order Time
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].time}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>{' '}
                                    </div>
                                    <div className='col-lg-5 col-sm-5 ml-auto'>
                                        <table className=' table-clear'>
                                            <tbody>
                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>Slote</strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].slote}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>
                                                            Payment Status
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].payment}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left  px-5 py-2 '>
                                                        <strong>Balance</strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].balance_to_pay}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>{' '}
                                    </div>
                                </div>

                                {data[0].foodlist?.length > 0 ? (
                                    <div className='table-responsive order-list card-table mt-5'>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <strong> ID</strong>
                                                    </th>
                                                    <th>
                                                        <strong></strong>
                                                    </th>
                                                    <th>
                                                        <strong>NAME</strong>
                                                    </th>
                                                    <th>
                                                        <strong>
                                                            QUANTITY
                                                        </strong>
                                                    </th>
                                                    <th>
                                                        <strong>
                                                            CUSTOMISATION
                                                        </strong>
                                                    </th>
                                                    <th>
                                                        <strong>AMOUNT</strong>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data[0].foodlist?.length >
                                                0 ? (
                                                    data[0].foodlist.map(
                                                        food => (
                                                            <tr key={food.id}>
                                                                <td>
                                                                    <div className='d-flex align-items-center'>
                                                                        <span className='w-space-no'>
                                                                            {
                                                                                food.id
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <img
                                                                        src={
                                                                            process
                                                                                .env
                                                                                .REACT_APP_API_RESOURCE_URL +
                                                                            food.img
                                                                        }
                                                                        className='rounded-lg mr-2'
                                                                        width='24'
                                                                        alt=''
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div className='d-flex align-items-center'>
                                                                        <span className='w-space-no'>
                                                                            {
                                                                                food.item_name
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='d-flex align-items-center'>
                                                                        <span className='w-space-no'>
                                                                            {
                                                                                food.qty
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='d-flex align-items-center'>
                                                                        <span className='w-space-no'>
                                                                            {
                                                                                food.customisation_val
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='d-flex align-items-center'>
                                                                        <span className='w-space-no'>
                                                                            {
                                                                                food.total
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <tr>
                                                        {/* <td>
                                                        <h6>No Ordered Food</h6>
                                                    </td> */}
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                ) : (
                                    <div style={{height: '100px'}}>
                                        <h5 className='text-center mt-5'>
                                            NO ORDER FOUND
                                        </h5>
                                    </div>
                                )}

                                <div className='row'>
                                    <div className='col-lg-4 col-sm-5'> </div>
                                    <div className='col-lg-4 col-sm-5 ml-auto'>
                                        <table className='table table-clear'>
                                            <tbody>
                                                <tr>
                                                    <td className='left'>
                                                        <strong>
                                                            Food Charge
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].food_charge}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left'>
                                                        <strong>
                                                            booking_charge
                                                        </strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].booking_charge}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left'>
                                                        <strong>VAT</strong>
                                                    </td>
                                                    <td className='right'>
                                                        {data[0].vat}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='left'>
                                                        <strong>TOTAL</strong>
                                                    </td>
                                                    <td className='right'>
                                                        <strong>
                                                            {data[0].total}
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div id='preloader-inner'>
                        <div className='sk-three-bounce-inner'>
                            <div className='sk-child sk-bounce1'></div>
                            <div className='sk-child sk-bounce2'></div>
                            <div className='sk-child sk-bounce3'></div>
                            <div id='preloader-inner-bg'></div>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default ViewTakeawayDetail;
