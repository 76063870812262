import Select from 'react-select';

const customSelectBoxStyles = {
    control: provided => ({
        ...provided,
        color: '#B1B1B1',
        height: '56px',
        background: '#fff',
        border: '1px solid #f0f1f5',
        borderRadius: '1.15rem',
    }),
};

const FormSelectInput = props => {
    const {
        label,
        Controller,
        control,
        required,
        ErrorMessage,
        name,
        id,
        errors,
        options,
        rules,
    } = props;
    return (
        <div className='form-group row'>
            <label className='col-lg-4 col-form-label' htmlFor='val-country'>
                {label}
                {required && <span className='text-danger'>*</span>}
            </label>
            <div className='col-lg-8'>
                <Controller
                    control={control}
                    id={id}
                    name={name}
                    rules={rules}
                    render={({field: {onChange, onBlur, value, ref}}) => (
                        <Select
                            onChange={val => onChange(val.value)}
                            onBlur={onBlur}
                            styles={customSelectBoxStyles}
                            options={options}
                            value={options.find(c => c.value === value)}
                        />
                    )}
                />
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({message}) => (
                        <div className='text-danger fs-12'>{message}</div>
                    )}
                />
            </div>
        </div>
    );
};

export default FormSelectInput;
