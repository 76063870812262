import React, {useState} from 'react';
import TimePickerPicker from 'react-time-picker';

const TimePicker = props => {
    const {
        label,
        Controller,
        control,
        required,
        ErrorMessage,
        name,
        id,
        errors,

        rules,
    } = props;

    const [date, setDate] = useState(new Date());

    return (
        <div className='form-group mb-3'>
            <label className='text-black font-w500' htmlFor={id}>
                {label}
                {required && <span className='text-danger'>*</span>}
            </label>
            <div className='col-lg-12'>
                <Controller
                    control={control}
                    id={id}
                    name={name}
                    rules={rules}
                    // defaultValue={new Date()}
                    render={({
                        field: {onChange, onBlur, value, defaultValue},
                    }) => (
                        <TimePickerPicker
                            id={id}
                            // defaultValue={new Date()}
                            onBlur={onBlur}
                            rules={rules}
                            onChange={onChange}
                            menuPortalTarget={document.body}
                            value={value || '10:00'}
                        />
                    )}
                />
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({message}) => (
                        <div className='text-danger fs-12'>{message}</div>
                    )}
                />
            </div>
        </div>
    );
};
export default TimePicker;
