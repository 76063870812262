import React, {Suspense, lazy, useEffect, useState, useCallback} from 'react';

import {ToastContainer, toast} from 'react-toastify';
import UserList from '../../components/Users/UserList';
import {
    getUserDetailById,
    getAllAdminUsers,
} from '../../../services/UserService';

const AddUser = lazy(() =>
    import(/* webpackChunkName: "AddUser" */ '../../components/Users/AddUser')
);

const notify = (id, type, message, time) => {
    toast.update(id, {
        render: message,
        type: type,
        position: 'top-center',
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        isLoading: false,
    });
};

const Users = () => {
    const [userId, setUserId] = useState();
    const [userDetails, setUserDetails] = useState();
    const [addCard, setAddCard] = useState(false);
    const [userList, setUserList] = useState({
        data: [],
        columns: ['', 'Name', 'Mobile', 'Designation', 'Email', 'Action'],
    });
    const [jobPagination, setJobPagination] = useState();
    const [jobData, setJobData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const sort = 5;

    const getUserList = useCallback(() => {
        setIsLoading(true);
        getAllAdminUsers().then(data => {
            let newArray = [];
            data.map((each, index) => {
                let testArray = [];
                testArray[0] =
                    process.env.REACT_APP_API_RESOURCE_URL + each.image;
                testArray[1] = each.full_name;
                testArray[2] = each.mob;
                testArray[3] = each.designation;
                testArray[4] = each.email;
                testArray[5] = each.id;
                newArray[index] = testArray;
                return null;
            });
            let userListData = {
                columns: [
                    '',
                    'Name',
                    'Mobile',
                    'Designation',
                    'Email',
                    'Action',
                ],
                data: newArray,
            };
            setUserList(userListData);
            setJobData(userListData.data.slice(0 * sort, (0 + 1) * sort));
            setJobPagination(
                Array(Math.ceil(userListData.data.length / sort))
                    .fill()
                    .map((_, i) => i + 1)
            );
            setIsLoading(false);
        });
    }, []);

    // add new user
    const handleAddNew = () => {
        setAddCard(true);
    };

    // edit existing user
    const handleEdit = id => {
        setAddCard(true);
        setUserId(id);
        getUserDetailById(id).then(res => {
            setUserDetails(res);
        });
    };

    useEffect(() => {
        if (!addCard) {
            setUserId('');
            setUserDetails('');
        }
    }, [addCard]);

    return (
        <>
            <Suspense
                fallback={
                    <div id='preloader-inner'>
                        <div className='sk-three-bounce-inner'>
                            <div className='sk-child sk-bounce1'></div>
                            <div className='sk-child sk-bounce2'></div>
                            <div className='sk-child sk-bounce3'></div>
                            <div id='preloader-inner-bg'></div>
                        </div>
                    </div>
                }
            >
                {addCard ? (
                    <AddUser
                        addCard={addCard}
                        setAddCard={setAddCard}
                        userId={userId}
                        userDetails={userDetails}
                        getUserList={getUserList}
                        notify={notify}
                        toast={toast}
                    />
                ) : (
                    ''
                )}
            </Suspense>
            {/* <ToastContainer
                position='top-center'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            /> */}
            <UserList
                userList={userList}
                jobData={jobData}
                jobPagination={jobPagination}
                handleAddNew={handleAddNew}
                handleEdit={handleEdit}
                getUserList={getUserList}
                setJobData={setJobData}
                sort={sort}
                isLoading={isLoading}
            />
        </>
    );
};
export default Users;
