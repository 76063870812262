import React, {Fragment, useState, useEffect, lazy} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';
import {
    getFoodItemDetailsById,
    deleteFoodItem,
} from '../../../services/FoodItemsService';

import swal from 'sweetalert';
import {toast} from 'react-toastify';
const AddFoodItem = lazy(() =>
    import(/* webpackChunkName: "AddFoodItem" */ './AddFoodItem')
);
const notify = (id, type, message, time) => {
    toast.update(id, {
        render: message,
        type: type,
        position: 'top-center',
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        isLoading: false,
    });
};
const FoodList = props => {
    const {getFoodItemList, foodItemList} = props;
    const [addNewModal, setAddNewModal] = useState(false);
    const [foodId, setFoodId] = useState();
    const [foodDetails, setFoodDetails] = useState();

    useEffect(() => {
        getFoodItemList();
    }, [getFoodItemList]);

    useEffect(() => {
        if (!addNewModal) {
            setFoodDetails();
            setFoodId();
            getFoodItemList();
        }
    }, [addNewModal, getFoodItemList]);

    // edit food
    const handleEdit = id => {
        setAddNewModal(true);
        setFoodId(id);
        getFoodItemDetailsById(id).then(res => {
            setFoodDetails(res);
        });
    };

    // add new food
    const handleAddNew = () => {
        setAddNewModal(true);
    };

    // delete food item
    const handleDeleteClick = (id, name) => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then(async willDelete => {
            if (willDelete) {
                let res = await deleteFoodItem(id);
                if (res.status === 'success') {
                    swal(`The foodItem ${name} has been deleted!`, {
                        icon: 'success',
                    });

                    getFoodItemList();
                } else {
                    swal(res.data.message);
                }
            }
        });
    };

    return (
        <>
            <div className='mb-sm-5 mb-3 d-flex flex-wrap align-items-center text-head'>
                <Link
                    className='btn btn-primary font-w600 mb-2 mr-auto'
                    to='#'
                    onClick={() => handleAddNew()}
                >
                    + New Food Item
                </Link>
            </div>
            <div className='row'>
                {foodItemList ? (
                    foodItemList.map((each, index) => (
                        <div
                            className='col-xl-3 col-xxl-4 col-lg-6 col-md-6 col-sm-6'
                            key={index}
                        >
                            <div className='card project-boxed'>
                                <div className='new-arrival-product mb-4 mb-xxl-4 mb-md-0'>
                                    <div className='new-arrivals-img-contnent'>
                                        <img
                                            className='img-fluid'
                                            src={
                                                process.env
                                                    .REACT_APP_API_RESOURCE_URL +
                                                each.img
                                            }
                                            alt=''
                                            height='300'
                                        />
                                    </div>
                                </div>

                                <div className='card-header align-items-start'>
                                    <div>
                                        <p className='fs-14 mb-2 text-primary'>
                                            {each.status}
                                        </p>
                                        <div className=' comment-review star-rating'>
                                            <ul>
                                                {' '}
                                                {[
                                                    ...Array(
                                                        ((Math.floor(
                                                            (+each.rating + 5) /
                                                                10
                                                        ) /
                                                            2) *
                                                            10) %
                                                            10 ===
                                                            0
                                                            ? Math.floor(
                                                                  (+each.rating +
                                                                      5) /
                                                                      10
                                                              ) / 2
                                                            : Math.floor(
                                                                  (+each.rating +
                                                                      5) /
                                                                      10
                                                              ) /
                                                                  2 -
                                                                  0.5
                                                    ),
                                                ].map((_, i) => (
                                                    <li key={i}>
                                                        <i className='fa fa-star' />{' '}
                                                    </li>
                                                ))}
                                                {[
                                                    ...Array(
                                                        ((Math.floor(
                                                            (+each.rating + 5) /
                                                                10
                                                        ) /
                                                            2) *
                                                            10) %
                                                            10 ===
                                                            0
                                                            ? 0
                                                            : 1
                                                    ),
                                                ].map((_, i) => (
                                                    <li key={i}>
                                                        <i className='fa fa-star-half-empty' />{' '}
                                                    </li>
                                                ))}
                                            </ul>
                                            <span className='review-text'>
                                                ({each.n_o_r} reviews)
                                            </span>
                                        </div>
                                        <p
                                            className='price'
                                            style={{
                                                fontWeight: 600,
                                                color: '#F3B516',
                                                fontSize: '20px',
                                            }}
                                        >
                                            SAR {each.selling_price}
                                        </p>

                                        <h6 className='fs-18 font-w500 mb-3'>
                                            <Link
                                                to={'#'}
                                                className='text-black user-name'
                                            >
                                                {each.name}
                                            </Link>{' '}
                                            <span className='item'>
                                                {each.status ? (
                                                    <i className='fa fa-check-circle text-success' />
                                                ) : (
                                                    <i className='fa fa-times-circle text-error' />
                                                )}
                                            </span>
                                        </h6>

                                        <div className='text-dark fs-14 text-nowrap'>
                                            <i
                                                className='fa fa-calendar-o mr-3'
                                                aria-hidden='true'
                                            ></i>
                                            Created on{' '}
                                            {new Date(
                                                each.created_on
                                            ).toLocaleDateString('en-us', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                            })}
                                        </div>
                                    </div>
                                    <Dropdown className=''>
                                        <Dropdown.Toggle
                                            variant=''
                                            as='div'
                                            className='btn-link i-false'
                                        >
                                            <svg
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <path
                                                    d='M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z'
                                                    stroke='#342E59'
                                                    stroke-width='2'
                                                    stroke-linecap='round'
                                                    stroke-linejoin='round'
                                                />
                                                <path
                                                    d='M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z'
                                                    stroke='#342E59'
                                                    stroke-width='2'
                                                    stroke-linecap='round'
                                                    stroke-linejoin='round'
                                                />
                                                <path
                                                    d='M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z'
                                                    stroke='#342E59'
                                                    stroke-width='2'
                                                    stroke-linecap='round'
                                                    stroke-linejoin='round'
                                                />
                                            </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() =>
                                                    handleEdit(each.id)
                                                }
                                            >
                                                Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                className='text-danger'
                                                onClick={() =>
                                                    handleDeleteClick(
                                                        each.id,
                                                        each.name
                                                    )
                                                }
                                            >
                                                Delete
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className='card-body p-0 pb-3'>
                                    <ul className='list-group list-group-flush'>
                                        <li className='list-group-item'>
                                            <span className='mb-0 title'>
                                                Cuisine
                                            </span>{' '}
                                            :
                                            <span className='text-black ml-2'>
                                                {each.cuisine}
                                            </span>
                                        </li>
                                        <li className='list-group-item'>
                                            <span className='mb-0 title'>
                                                Chef
                                            </span>{' '}
                                            :
                                            <span className='text-black ml-2'>
                                                {each.chef}
                                            </span>
                                        </li>
                                        <li className='list-group-item'>
                                            <span className='mb-0 title'>
                                                Available for
                                            </span>{' '}
                                            :
                                            <span>
                                                {' '}
                                                {each.reservation && (
                                                    <span className='badge badge-outline-primary badge-rounded item mt-2'>
                                                        Reservation
                                                    </span>
                                                )}{' '}
                                                {each.delivery && (
                                                    <span className='badge badge-outline-secondary badge-rounded item mt-2'>
                                                        Delivery
                                                    </span>
                                                )}{' '}
                                                {each.takeaway && (
                                                    <span className='badge badge-outline-info badge-rounded item mt-2'>
                                                        Takeaway
                                                    </span>
                                                )}{' '}
                                                {each.catering && (
                                                    <span className='badge badge-outline-light badge-rounded item mt-2'>
                                                        Catering
                                                    </span>
                                                )}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div id='preloader-inner'>
                        <div className='sk-three-bounce-inner'>
                            <div className='sk-child sk-bounce1'></div>
                            <div className='sk-child sk-bounce2'></div>
                            <div className='sk-child sk-bounce3'></div>
                            <div id='preloader-inner-bg'></div>
                        </div>
                    </div>
                )}
                {addNewModal ? (
                    <AddFoodItem
                        addNewModal={addNewModal}
                        setAddNewModal={setAddNewModal}
                        foodDetails={foodDetails}
                        setFoodDetails={setFoodDetails}
                        foodId={foodId}
                    />
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

export default FoodList;
