import {useEffect, useRef} from 'react';
import {Modal} from 'react-bootstrap';
import {toast} from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import {ErrorMessage} from '@hookform/error-message';
import {useForm, Controller} from 'react-hook-form';
import FormInput from './FormInput';
import {
    updateChefDetails,
    addNewChef,
    deleteChefById,
} from '../../../services/FoodItemsService';
import {objectToFormData} from '../../../utils/objectToFormData';
// images
import swal from 'sweetalert';
import uploadImageIcon from '../../../images/upload_image.png';

const notify = (id, type, message, time) => {
    toast.update(id, {
        render: message,
        type: type,
        position: 'top-center',
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        isLoading: false,
    });
};

const AddChef = props => {
    const {addNewModal, setAddNewModal, chefDetails, chefId, getChefList} =
        props;
    const uploadRef = useRef(null);
    const {
        handleSubmit,
        formState: {errors},
        register,
        setValue,
        control,
        watch,
    } = useForm({mode: 'all'});
    const {ref, ...rest} = register('img');

    const watchImage = watch('img', null);

    useEffect(() => {
        if (chefId && chefDetails) {
            const fields = ['name','name_arb', 'mobile', 'description','description_arb', 'email', 'status'];
            fields.forEach(field => setValue(field, chefDetails[field]));
        }
    }, [setValue, chefId, chefDetails]);
    console.log(chefDetails)
    const onClickDeleteChef = () => {
        swal({
            title: 'Are you sure?',
            text: 'Once deleted, you will not be able to recover this chef!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then(async willDelete => {
            if (willDelete) {
                let res = await deleteChefById(chefId);
                if (res.status === 'success') {
                    swal(`The chef ${chefDetails.name} has been deleted!`, {
                        icon: 'success',
                    });
                    setAddNewModal(false);
                    getChefList();
                } else {
                    swal(res.data.message);
                }
            }
        });
    };

    const onSubmit = async data => {
        if (chefId && chefDetails) {
            if (data.img.length < 1) {
                delete data.img;
            } else {
                data.img = data.img[0];
            }
            let postData = objectToFormData(data, '');
            const toaster = toast.loading('Updating...');
            let res = await updateChefDetails(chefId, postData);
            if (res.status === 'success') {
                notify(toaster, 'success', 'Updated chef details!', 2000);
                setAddNewModal(false);
            } else {
                notify(toaster, 'error', res.data.message, 2000);
            }
        } else {
            const toaster = toast.loading('Creating...');
            data.img = data.img[0];
            let postData = objectToFormData(data);
            let res = await addNewChef(postData);
            if (res.status === 'success') {
                notify(toaster, 'success', 'Added new chef details!', 2000);
                setAddNewModal(false);
            } else {
                notify(toaster, 'error', res.data.message, 2000);
            }
        }
    };

    if (chefId && !chefDetails) {
        return (
            <div id='preloader-inner'>
                <div className='sk-three-bounce-inner'>
                    <div className='sk-child sk-bounce1'></div>
                    <div className='sk-child sk-bounce2'></div>
                    <div className='sk-child sk-bounce3'></div>
                    <div id='preloader-inner-bg'></div>
                </div>
            </div>
        );
    } else {
        return (
            <Modal
                show={addNewModal}
                onHide={setAddNewModal}
                className='modal fade'
                id='addNewModal'
            >
                <>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                {chefDetails && chefId
                                    ? 'Update Chef'
                                    : 'Add Chef'}
                            </h5>
                            <button
                                type='button'
                                className='close'
                                data-dismiss='modal'
                                onClick={() => setAddNewModal(false)}
                            >
                                <span>×</span>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='food-image media mb-3'>
                                    {chefDetails ||
                                    uploadRef?.current?.files.length ? (
                                        <>
                                            <button
                                                type='button'
                                                onClick={() =>
                                                    uploadRef.current.click()
                                                }
                                                className='btn btn-primary food-image-edit'
                                                style={{
                                                    zIndex: '1',
                                                }}
                                            >
                                                <i className='flaticon-381-edit'></i>
                                            </button>
                                            <img
                                                className='mr-3 img-fluid rounded'
                                                src={
                                                    watchImage &&
                                                    watchImage.length &&
                                                    uploadRef.current.files
                                                        .length
                                                        ? URL.createObjectURL(
                                                              uploadRef?.current
                                                                  .files[0]
                                                          )
                                                        : process.env
                                                              .REACT_APP_API_RESOURCE_URL +
                                                          chefDetails?.img
                                                }
                                                alt='upload chef pic here'
                                            />
                                        </>
                                    ) : (
                                        <img
                                            className='mr-3 img-fluid rounded'
                                            onClick={() =>
                                                uploadRef?.current?.click()
                                            }
                                            style={{
                                                width: '100px',
                                                cursor: 'pointer',
                                            }}
                                            src={uploadImageIcon}
                                            alt='upload chef pic here'
                                        />
                                    )}
                                    <div className='custom-file mb-3'>
                                        <input
                                            type='file'
                                            {...rest}
                                            name='img'
                                            ref={e => {
                                                ref(e);
                                                uploadRef.current = e;
                                            }}
                                            className='custom-file-input'
                                            id='val-img'
                                            hidden
                                        />
                                    </div>
                                    <ErrorMessage
                                        errors={errors}
                                        name='img'
                                        render={({message}) => (
                                            <div className='text-danger fs-12'>
                                                {message}
                                            </div>
                                        )}
                                    />
                                </div>
                                <FormInput
                                    label={'Name'}
                                    id={'val-name'}
                                    name={'name'}
                                    required={true}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('name', {
                                            required: 'Chef name is required!',
                                        }),
                                    }}
                                    errors={errors}
                                />

                                <FormInput
                                    label={'Name'}
                                    id={'val-name'}
                                    name={'name_arb'}
                                    required={true}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('name_arb', {
                                            required: 'Chef name is required!',
                                        }),
                                    }}
                                    errors={errors}
                                />
                                <div className='form-group mb-3'>
                                    <label
                                        className='text-black font-w500'
                                        htmlFor={'val-mob'}
                                    >
                                        Mobile Number
                                    </label>
                                    <div className='col-lg-12'>
                                        <Controller
                                            control={control}
                                            name='mobile'
                                            render={({
                                                field: {ref, ...field},
                                            }) => (
                                                <PhoneInput
                                                    {...field}
                                                    inputExtraProps={{
                                                        ref,
                                                        autoFocus: true,
                                                    }}
                                                    country={'sa'}
                                                    specialLabel=''
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <FormInput
                                    label={'Email'}
                                    id={'val-email'}
                                    name={'email'}
                                    required={true}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('email', {
                                            required: 'Email is requied!',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message:
                                                    'Invalid email provided!',
                                            },
                                        }),
                                    }}
                                    errors={errors}
                                />
                                <FormInput
                                    label={'Description'}
                                    id={'val-description'}
                                    name={'description'}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('description'),
                                    }}
                                    errors={errors}
                                />

                                <FormInput
                                    label={'Arabic Description'}
                                    id={'val-description'}
                                    name={'description_arb'}
                                    ErrorMessage={ErrorMessage}
                                    register={{
                                        ...register('description_arb'),
                                    }}
                                    errors={errors}
                                />
                                <div className='modal-footer'>
                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                    >
                                        {chefId && chefDetails
                                            ? 'Update'
                                            : 'Add'}
                                    </button>

                                    {chefId && chefDetails ? (
                                        <button
                                            className='btn btn-danger'
                                            type='button'
                                            onClick={onClickDeleteChef}
                                        >
                                            Delete
                                        </button>
                                    ) : (
                                        ' '
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            </Modal>
        );
    }
};

export default AddChef;
