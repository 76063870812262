import axiosPrivateInstance from './AxiosPrivateInstance';
import {handleApiError} from './SettingsService';

import 'firebase/compat/messaging';

// export default usePushNotification;
export const getAllNotifications = async () => {
    try {
        console.log('notification api called');
        const res = await axiosPrivateInstance.get(`restaurant-notification/`);
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
export const DeleteNotification = async id => {
    try {
        console.log(id, 'notification delete api called');
        const res = await axiosPrivateInstance.delete(
            `restaurant-notification/?notifid=${id}`
        );
        console.log(res.data);
        return res.data;
    } catch (error) {
        console.log(error);
        handleApiError(error);
    }
};
