import React, {useState} from 'react';
import {ToastContainer} from 'react-toastify';
/// React router dom
import {Link} from 'react-router-dom';

/// images
import logo from '../../../images/logo.png';
import logoText from '../../../images/logo-pop-ar.png';

const NavHader = () => {
    const [toggle, setToggle] = useState(false);
    return (
        <div className='nav-header'>
            <Link to='/' className='brand-logo'>
                <img className='logo-abbr' src={logo} alt='' />
                <img className='logo-compact' src={logo} alt='' />
                <img className='brand-title' src={logoText} alt='' />
            </Link>

            <div className='nav-control' onClick={() => setToggle(!toggle)}>
                <div className={`hamburger ${toggle ? 'is-active' : ''}`}>
                    <span className='line'></span>
                    <span className='line'></span>
                    <span className='line'></span>
                </div>
            </div>
        </div>
    );
};

export default NavHader;
