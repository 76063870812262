import axios from 'axios';
import {checkTokenValidity} from '../utils/checkTokenValidity';
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';

const axiosPrivateInstance = axios.create({
    baseURL: 'https://kaser.texol.work/api/v1/admin/restaurant/',
    headers: {'Content-Type': 'multipart/form-data'},
});

//Request Interceptor
axiosPrivateInstance.interceptors.request.use(config => {
    checkTokenValidity();
    config.headers['Authorization'] = 'Bearer ' + Cookies.get('access');
    config.headers['Content-Type'] = 'multipart/form-data';
    config.withCredentials = true;
    return config;
});

// Response Interceptor
axiosPrivateInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        toast.error(error.message);
        const request = error.config; //this is actual request that was sent, and error is received in response to that request
        if (error.response?.status === 401 && !request._retry) {
            request._retry = true;
            axios.defaults.headers.common['Authorization'] =
                'Bearer ' + Cookies.get('access');
            axios.defaults.headers.common['Content-Type'] =
                'multipart/form-data';
            return axios(request);
        }
        return Promise.reject(error);
    }
);

export default axiosPrivateInstance;
