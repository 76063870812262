import React, {useEffect, useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';
import {
    DeleteNotification,
    getAllNotifications,
} from '../../../services/NotificationService';
import {toast} from 'react-toastify';
import CustomDropdown from './CustomDropdown';
import Loader from '../Orders/Loader';

const Index = () => {
    const [data, setData] = useState('');
    const [jobPagination, setJobPagination] = useState();
    const [jobData, setJobData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [activePag, setActivePag] = useState(0);
    const sort = 10;
    const [test, setTest] = useState(0);

    const notify = (id, type, message, time) => {
        toast.update(id, {
            render: message,
            type: type,
            position: 'top-center',
            autoClose: time,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            isLoading: false,
            pauseOnFocusLoss: false,
        });
    };

    const Notifications = async () => {
        setIsLoading(true);
        const res = await getAllNotifications();

        setData(res.data);
        setIsLoading(false);
    };
    useEffect(() => {
        Notifications();
    }, []);

    useEffect(() => {
        const test = data.slice(0 * sort, (0 + 1) * sort);

        setJobData(test);
        setJobPagination(
            Array(Math.ceil(data.length / sort))
                .fill()
                .map((_, i) => i + 1)
        );
    }, [data]);

    const onClick = i => {
        setActivePag(i);
        setJobData(data.slice(i * sort, (i + 1) * sort));
        setTest(i);
    };

    const handleDelete = async id => {
        const res = await DeleteNotification(id);
        const toaster = toast.loading('Deleting...');
        if (res.status === 'success') {
            notify(toaster, 'success', 'Deleted Notification!', 2000);
        } else {
            notify(toaster, 'error', res.data.message, 2000);
        }
        Notifications();
    };

    return (
        <>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='table-responsive rounded card-table'>
                        <div
                            id='generalCustomer'
                            className='dataTables_wrapper no-footer'
                        >
                            {isLoading && <Loader />}
                            {!isLoading && jobData?.length > 0 ? (
                                <>
                                    <table
                                        className='table border-no order-table mb-4 table-responsive-lg dataTablesCard dataTable no-footer'
                                        id='example5'
                                        role='grid'
                                        aria-describedby='example5_info'
                                    >
                                        <thead>
                                            <tr role='row'>
                                                <th
                                                    className='sorting_asc'
                                                    tabIndex={0}
                                                    aria-controls='example5'
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-sort='ascending'
                                                    aria-label='Customer ID: activate to sort column descending'
                                                    style={{width: 100}}
                                                >
                                                    ID
                                                </th>
                                                <th
                                                    className='sorting'
                                                    tabIndex={0}
                                                    aria-controls='example5'
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label='Join Date: activate to sort column ascending'
                                                    style={{width: 166}}
                                                >
                                                    Title
                                                </th>
                                                <th
                                                    className='sorting'
                                                    tabIndex={0}
                                                    aria-controls='example5'
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label='Customer Name: activate to sort column ascending'
                                                    style={{width: 126}}
                                                >
                                                    Message
                                                </th>
                                                <th
                                                    className='sorting'
                                                    tabIndex={0}
                                                    aria-controls='example5'
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label='Location: activate to sort column ascending'
                                                    style={{width: 184}}
                                                >
                                                    Date & Time
                                                </th>

                                                <th
                                                    className='sorting'
                                                    tabIndex={0}
                                                    aria-controls='example5'
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label=': activate to sort column ascending'
                                                    style={{width: 26}}
                                                />
                                                <th
                                                    className='sorting'
                                                    tabIndex={0}
                                                    aria-controls='example5'
                                                    rowSpan={1}
                                                    colSpan={1}
                                                    aria-label=': activate to sort column ascending'
                                                    style={{width: 26}}
                                                />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!isLoading &&
                                            jobData?.length > 0 ? (
                                                jobData.map(each => (
                                                    <tr
                                                        key={each.id}
                                                        className='alert alert-dismissible border-0 odd'
                                                        role='row'
                                                    >
                                                        <td className='sorting_1'>
                                                            {each.id}
                                                        </td>
                                                        <td>{each.title}</td>
                                                        <td>{each.message}</td>
                                                        <td>
                                                            {' '}
                                                            {new Date(
                                                                each.timestamp
                                                            ).toLocaleString()}
                                                        </td>

                                                        <td>
                                                            <Dropdown className='dropdown'>
                                                                <Dropdown.Toggle
                                                                    variant=''
                                                                    className='i-false'
                                                                    to='#'
                                                                    data-toggle='dropdown'
                                                                    aria-expanded='false'
                                                                >
                                                                    <svg
                                                                        width={
                                                                            24
                                                                        }
                                                                        height={
                                                                            24
                                                                        }
                                                                        viewBox='0 0 24 24'
                                                                        fill='none'
                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                    >
                                                                        <path
                                                                            d='M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z'
                                                                            stroke='#3E4954'
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                        />
                                                                        <path
                                                                            d='M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z'
                                                                            stroke='#3E4954'
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                        />
                                                                        <path
                                                                            d='M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z'
                                                                            stroke='#3E4954'
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                        />
                                                                    </svg>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className='dropdown-menu dropdown-menu-right'>
                                                                    <CustomDropdown
                                                                        type={
                                                                            each
                                                                                .endpoint
                                                                                .type
                                                                        }
                                                                        bookid={
                                                                            each
                                                                                .endpoint
                                                                                .bookid
                                                                        }
                                                                    />

                                                                    <Dropdown.Item
                                                                        onClick={() =>
                                                                            handleDelete(
                                                                                each.id
                                                                            )
                                                                        }
                                                                        className='dropdown-item'
                                                                    >
                                                                        <i className='las la-times-circle text-danger mr-3 scale5' />
                                                                        Delete
                                                                        Notification
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr></tr>
                                            )}
                                        </tbody>
                                    </table>

                                    <div className='d-sm-flex text-center justify-content-between align-items-center mt-3'>
                                        <div
                                            className='dataTables_info'
                                            id='example5_info'
                                            role='status'
                                            aria-live='polite'
                                        >
                                            Showing {activePag * sort + 1} to
                                            {data.length <
                                            (activePag + 1) * sort
                                                ? data.length
                                                : (activePag + 1) * sort}
                                            of {data.length} entries
                                        </div>
                                        <div
                                            className='dataTables_paginate paging_simple_numbers'
                                            id='example5_paginate'
                                        >
                                            <Link
                                                className='paginate_button previous disabled'
                                                to='/notifications'
                                                onClick={() =>
                                                    activePag > 0 &&
                                                    onClick(activePag - 1)
                                                }
                                            >
                                                Previous
                                            </Link>
                                            <span>
                                                {jobPagination
                                                    ? jobPagination.map(
                                                          (number, i) => (
                                                              <Link
                                                                  to='/notifications'
                                                                  key={i}
                                                                  className={`paginate_button  ${
                                                                      activePag ===
                                                                      i
                                                                          ? 'current'
                                                                          : ''
                                                                  } `}
                                                                  onClick={() =>
                                                                      onClick(i)
                                                                  }
                                                              >
                                                                  {number}
                                                              </Link>
                                                          )
                                                      )
                                                    : null}
                                            </span>
                                            <Link
                                                className='paginate_button next'
                                                to='/notifications'
                                                onClick={() =>
                                                    activePag + 1 <
                                                        jobPagination.length &&
                                                    onClick(activePag + 1)
                                                }
                                            >
                                                Next
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div style={{height: '100px'}}>
                                    <h5 className='text-center mt-5'>
                                        NO NOTIFICATION FOUND
                                    </h5>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
