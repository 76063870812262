import GoogleMapReact from 'google-map-react';
import React, {useState, useRef, useCallback, useEffect} from 'react';
import OutsideAlerter from './clickOutsideHook.js';

export default function GMap(props) {
    const {setValue, ErrorMessage, errors, names} = props;
    const searchInput = useRef(null);
    const searchBox = useRef(null);
    const markerLocation = useRef(null);
    const mapInstance = useRef(null);
    const [searchValue, setSearchValue] = useState();
    const [openMapView, setOpenMapView] = useState(false);
    const [mapApi, setMapApi] = useState();
    const [locLat, setLocLat] = useState();
    const [locLong, setLocLong] = useState();

    const handleApiLoaded = (map, maps) => {
        mapInstance.current = map;
        setMapApi(maps);
        if (markerLocation.current == null) {
            markerLocation.current = new maps.Marker({
                position: {
                    lat: 26.400119378886043,
                    lng: 50.140292835890754,
                },
                draggable: true,
                map,
            });
        } else {
            markerLocation.current.setMap(mapInstance.current);
            mapInstance.current.setCenter(markerLocation.current.getPosition());
        }
    };

    const onMapClick = ev => {
        if (ev) {
            setLocLat(ev.lat);
            setLocLong(ev.lng);
            setValue('lati', ev.lat);
            setValue('longi', ev.lng);
        }
    };

    const handleOnPlacesChanged = useCallback(() => {
        const place = searchBox.current.getPlace();
        if (place?.name) setSearchValue(place.name);
        if (mapApi && place.geometry) {
            if (markerLocation.current) markerLocation.current.setMap(null);
            var marker = new mapApi.Marker({
                position: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                },
                draggable: true,
            });
            setLocLat(place.geometry.location.lat());
            setLocLong(place.geometry.location.lng());
            setValue('lati', place.geometry.location.lat());
            setValue('longi', place.geometry.location.lng());
            markerLocation.current = marker;
            marker.setMap(mapInstance.current);
            mapInstance.current.setCenter(marker.getPosition());
        }
    }, [mapApi, searchBox, mapInstance, markerLocation]);

    useEffect(() => {
        const options = {
            fields: ['geometry', 'name'],
        };
        if (!searchBox.current && mapApi) {
            searchBox.current = new mapApi.places.Autocomplete(
                searchInput.current,
                options
            );
            searchBox.current.addListener(
                'place_changed',
                handleOnPlacesChanged
            );
        }

        return () => {
            if (mapApi) {
                searchBox.current = null;
                mapApi.event.clearInstanceListeners(searchInput);
            }
        };
    }, [mapApi]);

    const handleSearchChange = e => {
        setSearchValue(e.target.value);
        if (!e.target.value) {
            setValue('lati', null);
            setValue('longi', null);
        }
    };

    const defaultProps = {
        center: {
            lat: 26.400119378886043,
            lng: 50.140292835890754,
        },
        zoom: 11,
    };

    return (
        <>
            <div className='form-group row'>
                <label
                    className='col-lg-4 col-form-label'
                    htmlFor='val-location'
                >
                    Location
                    <span className='text-danger'>*</span>
                </label>
                <div className='col-lg-8'>
                    <input
                        type='text'
                        id='val-location'
                        className='form-control'
                        ref={searchInput}
                        value={searchValue ?? ''}
                        onClick={() => setOpenMapView(true)}
                        onChange={e => handleSearchChange(e)}
                        placeholder={
                            openMapView
                                ? 'Enter a Location'
                                : 'Click to Open Map'
                        }
                    />
                    <ErrorMessage
                        errors={errors}
                        name={names[0]}
                        render={({message}) => (
                            <div className='text-danger fs-12'>{message}</div>
                        )}
                    />
                </div>
            </div>

            <OutsideAlerter
                refInside={searchInput}
                closeAction={setOpenMapView}
            >
                {openMapView ? (
                    <div className='gmap_container'>
                        <div className='gmap'>
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: 'AIzaSyABNyrPeURiLM8e1L81_V4qBX3oPH349Lc',
                                    libraries: ['places'],
                                }}
                                onClick={onMapClick}
                                yesIWantToUseGoogleMapApiInternals
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                                onGoogleApiLoaded={({map, maps}) =>
                                    handleApiLoaded(map, maps)
                                }
                            ></GoogleMapReact>
                        </div>
                    </div>
                ) : null}
            </OutsideAlerter>
        </>
    );
}
